import React from "react";
import Select from "react-select";
import { BsUpload } from "react-icons/bs";
import { Button } from "../../../../components/elementsDS/buttons/Buttons";
import Modal from "react-modal";
import {
  Input,
  InputFile,
  LabelFile,
} from "../../../../components/elementsDS/Forms";
import { Hr, Text } from "../../../../components/elementsDS/Content";
import LoadButton from "../../../../components/elementsDS/Loaders/LoadButton";
import { ImageCategory } from "../../../../components/card/cardCategory";

interface Iprops {
  modalIsOpen: boolean;
  closeModal: any;
  customStyles: any;
  loading: boolean;
  onChangeCategoryName: any;
  categoryName: any;
  onChangeImage: any;
  onChangeImageBanner: any;
  saveCategory: any;
  imageCategory: any;
  imageCategoryBanner: any;
}
const ModalCategories = ({
  modalIsOpen,
  closeModal,
  customStyles,
  loading,
  onChangeCategoryName,
  categoryName,
  onChangeImage,
  onChangeImageBanner,
  saveCategory,
  imageCategory,
  imageCategoryBanner,
}: Iprops) => {
  return (
    <Modal
      ariaHideApp={false}
      isOpen={modalIsOpen}
      // className="Modal"
      // overlayClassName="Overlay"
      // onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="row">
        <Text type="H6" content="Agregar nueva categoría" color="#5C6562" />
        <Hr />
        <div className="col-lg-6 justify-content-center">
          <LabelFile htmlFor="newImage" disabled={loading} className="mb-3">
            {!loading ? (
              <>
                <BsUpload className="me-2" /> Subir imágen de categoria
              </>
            ) : (
              <LoadButton message="Cargando imágen..." />
            )}
            <InputFile
              type="file"
              name="newImage"
              id="newImage"
              onChange={onChangeImage}
            />
          </LabelFile>
          <br />
          <div className="d-flex justify-content-center">
            <img src={imageCategory} height={100} width={100} alt="" />
          </div>
        </div>
        <div className="col-lg-6">
          <LabelFile htmlFor="ImageBanner" disabled={loading} className="mb-3">
            {!loading ? (
              <>
                <BsUpload className="me-2" /> Subir imágen de banner
              </>
            ) : (
              <LoadButton message="Cargando imágen..." />
            )}
            <InputFile
              type="file"
              name="ImageBanner"
              id="ImageBanner"
              onChange={onChangeImageBanner}
            />
          </LabelFile>
          <br />
          <div className="d-flex justify-content-center">
            <img src={imageCategoryBanner} height={100} width={100} alt="" />
          </div>
        </div>
        <div className="mt-3 mb-3">
          <Input
            value={categoryName}
            type="text"
            placeholder="Nombre de la categoria"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-lg"
            onChange={onChangeCategoryName}
          />
        </div>
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <Button
          type="button"
          primary
          className="me-2"
          onClick={saveCategory}
          disabled={loading}
        >
          {!loading ? "Guardar" : "Procesando..."}
        </Button>
        <Button type="button" light onClick={closeModal} disabled={loading}>
          Cerrar
        </Button>
      </div>
    </Modal>
  );
};

export default ModalCategories;
