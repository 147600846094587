import styled, { css } from "styled-components";
import { colors } from "../utilsStyle/PaletteColor";
import { PropsButtonStyle } from "../buttons/style";

const BaseButton = styled.button`
  display: inline-block;
  border-radius: 0;
  outline-color: transparent;
  outline-style: none;
  user-select: none;
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
  vertical-align: middle;
  padding: 7px 12px;
  font-weight: 500;
  margin: 0;
  font-family: Avenir, sans-serif;
  background-position: center;
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);

  // modifiers or variants
  ${({ disabled }: PropsButtonStyle) =>
    disabled &&
    css`
      background: ${colors.blue};
      pointer-events: none;
      border: 0;
      opacity: 0.55;
      box-shadow: none;
    `}
  ${(props) =>
    props.block &&
    css`
      display: block;
      width: 100%;
    `}
    ${(props) =>
    props.small &&
    css`
      padding: 5px 10px;
      font-size: 9px;
    `}
    ${(props) =>
    props.large &&
    css`
      padding: 10px 20px;
      font-size: 16px;
    `}
`;

const Button = styled(BaseButton)`
  // default
      background-color: ${colors.white};
      color: ${colors.blue};
      border: 1px solid ${colors.blue};

      &:hover {
      background: ${colors.blue};
        radial-gradient(circle, transparent 1%, 
          ${colors.blue} 1%) center/15000%;
      box-shadow: 0 5px 5px rgba(0, 0, 0, 0.12);
      color: ${colors.white};
      }

      &:active {
      background-color: ${colors.white};
      background-size: 100%;
      transition: 0s;
      }

      // primary
      ${({ primary }: PropsButtonStyle) =>
        primary &&
        css`
          background-color: ${colors.blue};
          color: white;
          border: 1px solid ${colors.blue};
          &:hover {
          background: ${colors.blueDeep};
            radial-gradient(circle, transparent 1%, ${colors.blueDeep} 1%) center/15000%;
          box-shadow: 0 5px 5px rgba(0, 0, 0, 0.12);
        }
          &:active {
          background-color: ${colors.blue};
          background-size: 100%;
          transition: 0s;
        }
      `}
      ${({ secondary }: PropsButtonStyle) =>
        secondary &&
        css`
          background-color: ${colors.blueLight};
          color: ${colors.blue};
          border: 1px solid ${colors.blueLight};
          &:hover {
          background: ${colors.blue};
            radial-gradient(circle, transparent 1%, ${colors.blue} 1%) center/15000%;
          box-shadow: 0 5px 5px rgba(0, 0, 0, 0.12);
        }
          &:active {
          background-color: ${colors.blue};
          background-size: 100%;
          transition: 0s;
        }
      `} 
      ${({ danger }: PropsButtonStyle) =>
        danger &&
        css`
          background-color: ${colors.white};
          color: ${colors.red};
          border: 1px solid ${colors.red};
          &:hover {
          background: ${colors.redDeep};
            radial-gradient(circle, transparent 1%, ${colors.redDeep} 1%) center/15000%;
          box-shadow: 0 5px 5px rgba(0, 0, 0, 0.12);
        }
          &:active {
          background-color: ${colors.red};
          background-size: 100%;
          transition: 0s;
        }
      `}
      ${({ info }: PropsButtonStyle) =>
        info &&
        css`
          background-color: ${colors.white};
          color: ${colors.blueBright};
          border: 1px solid ${colors.blueBright};
          &:hover {
          background: ${colors.blueBright};
            radial-gradient(circle, transparent 1%, ${colors.blueBright} 1%) center/15000%;
          box-shadow: 0 5px 5px rgba(0, 0, 0, 0.12);
        }
          &:active {
          background-color: ${colors.blueBright};
          background-size: 100%;
          transition: 0s;
        }
      `}
      ${({ light }: PropsButtonStyle) =>
        light &&
        css`
        background-color: ${colors.white};
        color: ${colors.gray};
        border: 1px solid ${colors.gray};
        &:hover {
        background: ${colors.dark};
          radial-gradient(circle, transparent 1%, ${colors.dark} 1%) center/15000%;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.12);
      }
        &:active {
        background-color: ${colors.gray};
        background-size: 100%;
        transition: 0s;
      }
    `} 
      ${({ floating }: PropsButtonStyle) =>
        floating &&
        css`
          position: relative;
          top: -20px;
          z-index: 1;
        }
      `}
`;

const ButtonSearchIcon = styled.button`
  border-top: 1px solid ${colors.blue};
  border-right: 1px solid ${colors.blue};
  border-bottom: 1px solid ${colors.blue};
  border-left: 0px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 0;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
  font-family: inherit;
  color: ${colors.blue};
  background-color: white;
  &:hover {
    background: ${colors.blue};
    color: white;
  }
  ${({ globalSearchButtom }: PropsButtonStyle) =>
    globalSearchButtom &&
    css`
      border-top: 0px;
      border-right: 0px;
      border-bottom: 1px solid white;
      border-left: 0px;
      color: white;
      background-color: inherit;
  }
`}
`;

export { Button, ButtonSearchIcon };
