/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import LayoutMain from "../../layouts/layoutMain";
import { Image, Section, Text } from "../../components/elementsDS/Content";
import { CardCategory } from "../../components/card/cardCategory";
import { pathname } from "../../commons/const/pathname";
import { fetchMethod } from "../../utils/fetch/fecth";
import {
  Icategory,
  IcategoryAnswer,
  IimageCategory,
  IsubCategory,
  ISubcategoryAnswer,
} from "../../commons/interfaces/category.interface";
import { Animate } from "../../components/elementsDS/animations/animations";
import LoadPage from "../../components/elementsDS/Loaders/LoadPage";

const CategoryPage: React.FC = () => {
  const location = useLocation();
  const { categoria } = useParams();
  const [imageBanner, setImageBanner] = useState("");
  const [categories, setCategories] = useState<Icategory[]>([]);
  const [subCategories, setSubCategories] = useState<IsubCategory[]>([]);
  const getSubCategoryImage = async () => {
    const imageBanner: IimageCategory = await fetchMethod(
      `${process.env.REACT_APP_BACKEND_API}/categories/imageCategoryBanner/${categoria}`,
      {
        method: "GET",
        headers: {
          Authorization: "1234",
        },
      }
    );
    setImageBanner(imageBanner.data.imageBannerUrl);
  };
  const getCategories = async () => {
    if (location.pathname === pathname[0]) {
      const data: IcategoryAnswer = await fetchMethod(
        `${process.env.REACT_APP_BACKEND_API}/categories?limit=${10}&skip=${0}`,
        {}
      );

      setCategories(data.data);
    } else if (location.pathname.split("/")[1] === pathname[1]) {
      const subCategories: ISubcategoryAnswer = await fetchMethod(
        `${process.env.REACT_APP_BACKEND_API}/categories/subcategories/${categoria}`,
        {
          method: "GET",
          headers: {
            Authorization: "1234",
          },
        }
      );
      setSubCategories(subCategories.data.subCategories);
    }
  };
  useEffect(() => {
    getCategories();
    getSubCategoryImage();
    return () => {
      setCategories([]);
      setSubCategories([]);
    };
  }, [location.pathname]);

  return (
    <LayoutMain>
      <Animate.FadeIn>
        {location.pathname !== pathname[0] && (
          <Image
            src={imageBanner}
            alt="banner categorias coinsa"
            className="w-100"
            loading="lazy"
          />
        )}
      </Animate.FadeIn>
      <Section className="container text-center">
        {location.pathname === pathname[0] && (
          <Animate.FadeUp>
            <Text
              type="H5"
              content="Categorías de Producto"
              bold
              color="#5C6562"
            />
          </Animate.FadeUp>
        )}
        <div className="row justify-content-center mt-5 text-center">
          {subCategories.length === 0 && categories.length === 0 ? (
            <LoadPage />
          ) : (
            categories.map((content) => (
              <CardCategory
                key={content._id}
                name={content.nombre}
                image={content.imageUrl}
                link={`/categoria/${content.nombre}`}
              />
            ))
          )}
          {subCategories.length === 0 && categories.length === 0 ? (
            <LoadPage />
          ) : (
            subCategories.map((content) => (
              <CardCategory
                key={content._id}
                name={content.nombre}
                image={content.imageUrl}
                link={`/productos/${categoria}/${content.nombre}/all`}
              />
            ))
          )}
        </div>
      </Section>
    </LayoutMain>
  );
};

export default CategoryPage;
