/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable */
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import LayoutMain from "../../layouts/layoutMain";
import { Text, BoxText, BlockText, Image } from "../../components/elementsDS/Content";
import { Animate } from "../../components/elementsDS/animations/animations";
import { useUser } from "../../context/userContext";

const Section = styled.div`
  background: #83b0a0;
  color: white;
  position: relative;
  top: -20px;
  z-index: -1;
  padding: 80px;
`;

const AboutUs: React.FC = () => {
  const contextData = useUser();
  const [content, setcontent] = useState("");

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_API}/articles/notArticles/QUIENESSOMOS`
    )
      .then((data) => data.json())
      .then((data) => {
        setcontent(data.data[0].body);
      })
      .catch((e) => toast.error(e.message));
  }, []);
  return (
    <LayoutMain>
      {content ? (
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
      ) : (
        <div className="text-center">
          <br />
          <br />
          <Animate.FadeUp>
            <Text type="H5" content="Quienes somos" bold color="#5C6562" />
            <br />
            <Image
              src="/img/about.png"
              alt="about coinsa"
              className="m-auto d-block"
              loading="lazy"
            />
          </Animate.FadeUp>
          <Section>
            <Animate.ScaleIn>
              <div className="container">
                <p className="fw-light fs-5">
                  <b>Cosmética Internacional S.A.</b> es una empresa con más de
                  47 años de experiencia en la fabricación de medicamentos,
                  bioseguridad, cosméticos, cuidado personal y cuidado del
                  hogar. Cumplimos con los requisitos de la norma ISO 9001:2015
                  con respaldo del Sistema de Gestión de Calidad (SGC). Avalados
                  por la Agencia de Regulación Sanitaria (ARSA), contamos con el
                  Certificado de Buenas Prácticas de Manufactura Farmacéutica y
                  Buenas Prácticas de Manufactura Cosmética. Nuestras
                  instalaciones físicas cuentan con más de 5,000 m2, 19 líneas
                  de producción, tecnología de punta, un equipo altamente
                  especializado y un gran portafolio de más de 450 productos con
                  marcas líderes en el mercado. Estamos presente en 6 países,
                  acompañando en todo momento las tendencias de consumo, tanto
                  regionales como mundiales. Contamos con una ubicación
                  estratégica en Tegucigalpa, Honduras; lo que nos permite
                  cubrir todo el continente con eficientes costos de
                  distribución. Otorgando así, una ventaja competitiva para
                  nuestros socios comerciales.
                </p>
              </div>
            </Animate.ScaleIn>
          </Section>
          <div className="container-fluider mb-5 mt-5">
            {" "}
            <Animate.FadeUp>
              <BoxText>
                <BlockText>
                  <Text type="H4" bold content="Visión" color="#002C1C" />
                </BlockText>
                <BlockText right>
                  <p className="fw-light fs-5">
                    Convertirnos en el líder regional en el diseño y fabricación
                    de productos cosméticos de alta calidad y productos de
                    limpieza. Posicionarnos como un fabricante farmacéutico
                    importante en la región, en los formatos incluídos en
                    nuestras Buenas Prácticas de Manufactura.
                  </p>
                </BlockText>
              </BoxText>
            </Animate.FadeUp>
            <Animate.FadeUp>
              <BoxText>
                <BlockText right alignTextRight>
                  <p className="fw-light fs-5">
                    Ofrecer a nuestros clientes productos de alta calidad a
                    precios competitivos, aplicando tecnología e ingredientes de
                    última generación.
                  </p>
                </BlockText>
                <BlockText>
                  <Text type="H4" bold content="Misión" color="#002C1C" />
                </BlockText>
              </BoxText>
            </Animate.FadeUp>
          </div>
        </div>
      )}
    </LayoutMain>
  );
};

export default AboutUs;
