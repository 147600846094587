import React, { useState } from "react";
import Select from "react-select";
import { BsUpload } from "react-icons/bs";
import { Button } from "../../../../components/elementsDS/buttons/Buttons";
import Modal from "react-modal";
import {
  Input,
  InputFile,
  LabelFile,
} from "../../../../components/elementsDS/Forms";
import { Hr, Text } from "../../../../components/elementsDS/Content";
import LoadButton from "../../../../components/elementsDS/Loaders/LoadButton";
import { ImageCategory } from "../../../../components/card/cardCategory";
import { Icategory } from "../../../../commons/interfaces/category.interface";
import { toast } from "react-toastify";
import { fetchMethod } from "../../../../utils/fetch/fecth";
import { useUser } from "../../../../context/userContext";

interface Iprops {
  modalIsOpen: boolean;
  closeModal: any;
  customStyles: any;
  categoryName: any;
  category: Icategory;
}

const ModalEditSubCategory = ({
  modalIsOpen,
  closeModal,
  customStyles,
  categoryName,
  category,
}: Iprops) => {
  const contextData = useUser();
  const [loading, setloading] = useState(false);
  const [input, setinput] = useState("");
  const [index, setindex] = useState(0);
  const [id, setid] = useState("");
  const [idCategory, setidCategory] = useState("");
  const [oldImage, setoldImage] = useState("");
  const [newImage, setnewImage] = useState<any>();
  const [newImageUri, setnewImageUri] = useState<any>();
  const onChange = (data: any) => {
    setinput(data.value);
    setid(data.id);
    setoldImage(data.image);
    setidCategory(data.categoryId);
    setindex(data.index);
  };
  const onChangeImage = ({ target, currentTarget }: React.ChangeEvent<any>) => {
    const file = currentTarget.files[0];
    setnewImage(file);
    const url = URL.createObjectURL(file);
    setnewImageUri(url);
  };
  const saveData = async () => {
    setloading(true);
    try {
      let message = "";
      if (!id) message += "selecione una categoria.\n";
      if (!input) message += "el campo nombre esta vacio. \n";
      if (message) return toast.error(message);
      let imageAnswer = { data: { imageUri: "" } };
      if (newImage) {
        const formData = new FormData();
        formData.append("file", newImage);
        imageAnswer = await fetchMethod(
          `${process.env.REACT_APP_BACKEND_API}/products/uploadImage`,
          {
            method: "POST",
            body: formData,
            mode: "cors",
          }
        );
      }
      const newCategory = { ...category };
      newCategory.subCategories[index] = {
        _id: id,
        imageUrl: newImage ? imageAnswer.data.imageUri : oldImage,
        nombre: input,
      };
      const answer = await fetchMethod(
        `${process.env.REACT_APP_BACKEND_API}/categories/${idCategory}`,
        {
          method: "PUT",
          headers: new Headers({
            Accept: "application/json",
            Authorization: `Bearer ${contextData.user?.access_token}`,
            "Content-Type": "application/json",
          }),
          body: JSON.stringify(newCategory),
          mode: "cors",
        }
      );
      toast[answer.success ? "success" : "error"](answer.message);
      closeModal();
    } catch (error: any) {
      toast.error(error.message);
    }
    setloading(false);
  };
  return (
    <Modal
      ariaHideApp={false}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Edit category"
    >
      <Text type="H6" content={`editar sub categoria`} color="#5C6562" />
      <Hr />

      <div className="row">
        <Select
          name="subcategoria"
          options={category.subCategories.map((data, index) => ({
            value: data.nombre,
            label: data.nombre,
            id: data._id,
            image: data.imageUrl,
            categoryId: category._id,
            index: index,
          }))}
          onChange={onChange}
          placeholder="Seleccionar categoría"
          className="m-3"
        />
        <div className="col-lg-6 justify-content-center">
          <LabelFile htmlFor={`newimage`} disabled={loading} className="mb-3">
            {!loading ? (
              <>
                <BsUpload className="me-2" /> subir imagen
              </>
            ) : (
              <LoadButton message="Cargando imágen..." />
            )}
            <InputFile
              type="file"
              name={`newimage`}
              id={`newimage${categoryName}`}
              onChange={onChangeImage}
            />
          </LabelFile>
          <br />
          <img src={oldImage} height={100} width={100} alt="" className="m-2" />
          <img
            src={newImageUri}
            height={100}
            width={100}
            alt=""
            className="m-2"
          />
        </div>
        <div className="mt-3 mb-3">
          <Input
            value={input}
            type="text"
            placeholder="Nombre de la categoria"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-lg"
            id={`input-${categoryName}`}
            onChange={(e) => {
              setinput(e.target.value);
            }}
          />
        </div>
      </div>

      <div className="d-flex justify-content-end align-items-center">
        <Button
          type="button"
          primary
          className="me-2 my-1"
          onClick={saveData}
          disabled={loading}
        >
          guardar
        </Button>
        <Button type="button" light onClick={closeModal} disabled={loading}>
          Cerrar
        </Button>
      </div>
    </Modal>
  );
};

export default ModalEditSubCategory;
