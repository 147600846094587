import React from "react";
import Select from "react-select";
import Pagination from "rc-pagination";
import Table from "rc-table";
import InputSearch from "../search/InputSearch";
import { Text } from "../elementsDS/Content";
import { SHeaderTable, SHeaderItem } from "../elementsDS/SHeaderTable";

const options = [
  { value: "Todos", label: "Todos" },
  { value: "Pendiente", label: "Pendiente" },
  { value: "Rechazada", label: "Rechazada" },
  { value: "Aprobado", label: "Aprobado" },
];

interface Props {
  filterSelect?: any;
  titleHeader?: any;
  searchHeader?: any;
  data?: any;
  columns: any;
  onchangePagination?: (value: number) => void;
  totalDocument?: number;
}

const TableComponent: React.FC<Props> = ({
  filterSelect,
  titleHeader,
  searchHeader,
  data,
  columns,
  onchangePagination,
  totalDocument,
}) => {
  return (
    <div>
      <SHeaderTable>
        {titleHeader ? (
          <SHeaderItem>
            <Text type="H6" content="Listado de categorías" color="#5C6562" />
          </SHeaderItem>
        ) : (
          ""
        )}
        {filterSelect ? (
          <SHeaderItem>
            <Select options={options} placeholder="filtrar por estado" />
          </SHeaderItem>
        ) : (
          ""
        )}
        {searchHeader ? (
          <SHeaderItem>
            <InputSearch placeholder="Buscar" nameInputSearch="Buscar" />
          </SHeaderItem>
        ) : (
          ""
        )}
      </SHeaderTable>
      <Table
        scroll={{ x: 999 }}
        columns={columns}
        rowClassName={(record, i) => `row-${i}`}
        // expandedRowRender={(record) => <p>extra: {record.a}</p>}
        expandedRowClassName={(record, i) => `ex-row-${i}`}
        data={data}
        className="table"
        emptyText={() => <h5>No hay datos...</h5>}
      />
      <Pagination total={totalDocument} onChange={onchangePagination} />
    </div>
  );
};

export default TableComponent;
