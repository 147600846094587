/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from "react";
import LayoutAdmin from "../../../layouts/layoutAdmin";
import TableComponent from "../../../components/table/table";
import { FcNews } from "react-icons/fc";
import { Button } from "../../../components/elementsDS/buttons/Buttons";
import { BsEye, BsPencil, BsPlusLg, BsTrash } from "react-icons/bs";
import { Link } from "react-router-dom";
import { fetchMethod } from "../../../utils/fetch/fecth";
import {
  Iarticles,
} from "../../../commons/interfaces/article.interface";

const ListArticles: React.FC = () => {
  const [current, setcurrent] = useState(0);
  const [totalDocument, settotalDocument] = useState(0);
  const [articles, setarticles] = useState<Iarticles[]>([
    {
      _id:"",
      titulo: "",
      descripcion: "",
      imagen: "",
      body: "",
      type: "",
    },
  ]);
  const [skip, setskip] = useState(0);
  const getArtciles = async () => {
    const answer: any = await fetchMethod(
      `${process.env.REACT_APP_BACKEND_API}/articles?skip=${skip}`,
      {}
    );
    setarticles(answer.data);
  };
  const getTotalDocument = async () => {
    const answer: any = await fetchMethod(
      `${process.env.REACT_APP_BACKEND_API}/articles/countDocuments`,
      {}
    );
    settotalDocument(answer.data);
  };
  useEffect(() => {
    getTotalDocument();
  }, []);
  useEffect(() => {
    getArtciles();
  }, [skip]);
  const columns = [
    {
      title: "Id",
      dataIndex: "_id",
      className: "text-center",
      key: "a",
      width: 100,
    },
    {
      title: "Titulo",
      dataIndex: "titulo",
      className: "text-center",
      key: "titulo",
      width: 100,
    },
    {
      title: "Descripción",
      dataIndex: "descripcion",
      className: "text-center",
      key: "descripcion",
      width: 100,
    },
    {
      title: "Banner",
      dataIndex: "imagen",
      className: "text-center",
      key: "e",
      render(e: any, data: Iarticles, ad: any) {
        return (
          <>
            <img
              className="m-1"
              src={data.imagen}
              alt="imagen de articulo"
              width={150}
              height={150}
            />
          </>
        );
      },
    },
    {
      title: "Acciones",
      dataIndex: "d",
      className: "text-center",
      key: "d",
      render(e: any, data: Iarticles, ad: any) {
        return (
          <Link to={`/dashboard/agregar/articulo/${data._id}`}>
            <Button type="button" info className="me-2">
              <BsPencil />
            </Button>
          </Link>
        );
      },
    },
  ];
  return (
    <LayoutAdmin title="Listado de Articulos" icon={<FcNews size={34} />}>
      <div className="d-flex justify-content-end align-items-center">
        <Link to={`/dashboard/agregar/articulo/${false}`}>
          <Button primary type="button" className="me-2 mb-3">
            <BsPlusLg /> Crear nuevo articulo
          </Button>
        </Link>
      </div>
      <TableComponent
        titleHeader
        // searchHeader
        data={articles}
        columns={columns}
        onchangePagination={(value: number) => {
          if (value > current) setskip(skip + 8);
          if (value < current) setskip(skip - 8);
          setcurrent(value);
        }}
        totalDocument={totalDocument}
      />
    </LayoutAdmin>
  );
};

export default ListArticles;
