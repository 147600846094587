//  TOKENS PALETTE COLOR COINSA

const blue = {
    50:  '#B9D3C9',
    100: '#A9ADE9',
    200: '#8289D6',
    300: '#5E68C5',
    400: '#414CB8',
    500: '#2232AC',
    600: '#1C2AA3',
    700: '#132095',
    800: '#086141',
    900: '#023E28',
    a100: '#727EF9',
    a200: '#4049F0',
    a400: '#2733F1',
    a700: '#2820EF',
};

const blueRadiant = {
    50:  '#DEF1FF',
    100: '#B6DCFC',
    200: '#8BCAFE',
    300: '#5EB5FA',
    400: '#3BA4F8',
    500: '#0F95F6',
    600: '#0C87E7',
    700: '#1375D5',
    800: '#0765C3',
    900: '#0046A5',
    a100: '#7EAFFF',
    a200: '#4388FF',
    a400: '#2D77FF',
    a700: '#2860FF',
}

const yellow = {
    50:  '#FFFFD8',
    100: '#FFFEB6',
    200: '#FFF98C',
    300: '#FFF765',
    400: '#FFF441',
    500: '#FFF112',
    600: '#FFDD00',
    700: '#FFC500',
    800: '#FFAC00',
    900: '#FD7F00',
    a100: '#FFFF7D',
    a200: '#FDFF00',
    a400: '#FEF200',
    a700: '#FFDC00',
}

const gray = {
    0:   '#FFFFFF',
    50:  '#FAFAFA',
    100: '#F4F4F4',
    200: '#EEEEEE',
    300: '#E0E0E0',
    400: '#BCBCBC',
    500: '#9E9E9E',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#222222',
}

const green = {
    50:  '#D4E5DA',
    100: '#D5F4AC',
    200: '#BCE688',
    300: '#A5DB61',
    400: '#94D141',
    500: '#81C819',
    600: '#70B70B',
    700: '#5DA400',
    800: '#489100',
    900: '#1B6F00',
    a100: '#C7FF6F',
    a200: '#AAFF23',
    a400: '#5FFF00',
    a700: '#51E200',
}

const red = {
    50:  '#FFDFE5',
    100: '#FFC0C4',
    200: '#FF8C85',
    300: '#F6645D',
    400: '#FD4333',
    500: '#FD320C',
    600: '#EE2411',
    700: '#DC0C0C',
    800: '#CF0303',
    900: '#C00000',
    a100: '#FF7F66',
    a200: '#FF3E34',
    a400: '#FF0028',
    a700: '#DB0000',
}

const orange = {
    50:  '#FEF2DF',
    100: '#FFE1B3',
    200: '#FFCC81',
    300: '#FDB64C',
    400: '#FDA625',
    500: '#FF9800',
    600: '#FB8D00',
    700: '#F67C01',
    800: '#EF6D02',
    900: '#E75204',
    a100: '#FED17E',
    a200: '#FDAA3F',
    a400: '#FF8F00',
    a700: '#FE6C00',
}

export const colors = {
    // brand colors ands complementarys
    blue: `${blue[800]}`,
    blueDeep: `${blue[900]}`,
    blueLight: `${blue[50]}`,
    yellow: `${yellow[600]}`,
    yellowDeep: `${yellow[700]}`,
    yellowLight: `${yellow[50]}`,
    blueRadiant: `${blueRadiant[600]}`,
    blueRadiantDeep: `${blueRadiant[800]}`,
    blueRadiantLight: `${blueRadiant[50]}`,
    // states colors
    // danger
    red: `${red[800]}`,
    redDeep: `${red[900]}`,
    redLight: `${red[50]}`,
    // success
    green: `${green[600]}`,
    greenDeep: `${green[800]}`,
    greenLight: `${green[50]}`,
    // warning or pending
    orange: `${orange[700]}`,
    orangeDeep: `${orange[900]}`,
    orangeLight: `${orange[50]}`,
    // info
    blueBright: `${blueRadiant['a700']}`,
    blueBrightDeep: `${blue['a700']}`,
    blueBrightLight: `${blueRadiant[100]}`,
    // grays colors
    black: `${gray[900]}`,
    dark: `${gray[800]}`,
    gray: `${gray[600]}`,
    gray1: `${gray[500]}`,
    gray2: `${gray[300]}`,
    gray3: `${gray[100]}`,
    grayLight: `${gray[50]}`,
    white: `${gray[0]}`,
};