/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import LayoutMain from "../../layouts/layoutMain";
import {
  Text,
  BoxText,
  BlockText,
  Section,
  Image,
} from "../../components/elementsDS/Content";
import { useUser } from "../../context/userContext";
import { Animate } from "../../components/elementsDS/animations/animations";

const Services: React.FC = () => {
  const contextData = useUser();
  const [content, setcontent] = useState("");

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_API}/articles/notArticles/NUESTROSERVICIOS`
    )
      .then((data) => data.json())
      .then((data) => {
        setcontent(data.data[0].body);
      })
      .catch((e) => toast.error(e.message));
  }, []);
  return (
    <LayoutMain>
      {content ? (
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      ) : (
        <div className="container-fluider mb-5 mt-5 text-center">
          <Animate.FadeUp>
            <div className="d-flex flex-wrap align-items-center">
              <div className="col-12 col-lg-5">
                <Image
                  src="/img/lineas-produccion.png"
                  alt="lineas de produccion coinsa"
                  className="w-100"
                  loading="lazy"
                />{" "}
              </div>
              <div className="col-12 col-lg-7">
                <Text
                  type="H5"
                  content="Líneas de Producción"
                  bold
                  padding={3}
                  align="center"
                  color="#5C6562"
                />
                <Text
                  type="Paragraph"
                  padding={3}
                  align="center"
                  content="Ponemos a disposición nuestra experiencia y capacidad de producción con altos estándares de calidad y de servicio."
                  color="#5C6562"
                />
              </div>
            </div>
          </Animate.FadeUp>
          <Section>
            <Animate.FadeUp>
              <div className="container">
                <Image
                  src="/img/bodega-producto.jpg"
                  alt="bodega producto coinsa"
                  className="m-auto d-block"
                  loading="lazy"
                />{" "}
              </div>
            </Animate.FadeUp>
          </Section>
          <Animate.FadeUp>
            <BoxText>
              <BlockText>
                <Text type="H4" bold content="Tecnología" color="#002C1C" />
              </BlockText>
              <BlockText right>
                <p>
                  Para alcanzar los más altos niveles de calidad, y cumplir con
                  todas las actividades y normativas vigentes en libros
                  Oficiales y Reglamento Técnico Centroamericano, nuestras
                  instalaciones cuentan con las más amplia y diversa tecnología
                  de punta para la producción de medicamentos y cosméticos.
                  <br /> <br />
                  <img
                    src="/img/tabla-tecnologia.png"
                    alt="tabla coinsa tecnologia"
                    className="img-fluid w-100"
                  />
                  {/* <ul>
                  <li>
                    Sistema de agua purificada doble osmosis inversa con una
                    capacidad de 220 galones por hora;
                  </li>
                  <li>
                    Sistema de purificación de aire, con filtros HEPA
                    garantizando las áreas que lo requieren;
                  </li>
                  <li>
                    Tres máquinas de llenado de sachet con capacidad de 4,200
                    unidades por hora;{" "}
                  </li>
                  <li>
                    Línea completamente automatizada para el llenado de líquidos
                    orales con capacidad de 2,400 unidades por hora;{" "}
                  </li>
                  <li>
                    Línea automática para llenado de crema y shampoo con
                    capacidad de 2,000 unidades por hora;{" "}
                  </li>
                  <li>
                    Tanques de acero inoxidable para fabricación en caliente en
                    área cosmética con una capacidad de fabricación diaria de
                    5,000 kilos de producto;
                  </li>
                  <li>Tanques de acero inoxidable para fabricación;</li>
                  <li>
                    Area medicamentos con capacidad diaria de 2,000 kilos de
                    fabricación;
                  </li>
                  <li>
                    Capacidad de almacenamiento de semi elaborado en tanques de
                    acero inoxidable (productos cosméticos) hasta 15,000 kilos;{" "}
                  </li>
                  <li>
                    2 líneas para reacondicionamiento o termoformado de producto
                    (armado de packs); · Tres Bodegas acondicionadas y equipadas
                    con estantería con gran capacidad de almacenamiento;
                  </li>
                  <li>
                    Equipo de levante (montacargas y stackers) para la logística
                    del almacenamiento en las bodegas de materia prima, material
                    de empaque y producto terminado; · Planta de emergencia con
                    capacidad de 300voltios.
                  </li>
                </ul> */}
                </p>
              </BlockText>
            </BoxText>
          </Animate.FadeUp>
          <Animate.FadeUp>
            <BoxText>
              <BlockText right alignTextRight>
                <p>
                  Cumpliendo con nuestro compromiso en Garantizar la Calidad,
                  Seguridad y Eficacia de nuestros productos, se realiza un
                  estricto Control de Calidad al ingreso de todo tipo de
                  materias primas en la planta, así como en su proceso
                  intermedio y final al producto terminado. Anualmente
                  realizamos en el laboratorio un promedio de 200 análisis de
                  materias primas y 2,000 análisis de calidad en productos
                  terminados, con su respectivo control microbiológico para
                  aquellos productos que así lo requieren. Nuestra principal
                  materia prima, el agua, pasa por un riguroso sistema de
                  control de calidad, ingresando a un tratamiento de Osmosis
                  Inversa, proceso por el cual garantizamos, agua de excelente
                  calidad sin interferencia de ningún químico o sustancia. El
                  equipo especializado de control de calidad realiza los
                  análisis de: Aspecto, PH, Conductividad, Dureza y Cloro,
                  Carbono orgánico total (TOC). El departamento de CONTROL
                  calidad cuenta con todos los equipos y dispositivos necesarios
                  para garantizar EL CUMPLIMIENTO de la normativa.
                </p>
                <br /> <br />
                <img
                  src="/img/tabla-control-calidad.png"
                  alt="tabla coinsa calidad"
                  className="img-fluid w-100"
                />
              </BlockText>
              <BlockText>
                <Text
                  type="H4"
                  bold
                  content="Control de Calidad"
                  color="#002C1C"
                />
              </BlockText>
            </BoxText>
          </Animate.FadeUp>
          <Animate.FadeUp>
            <BoxText>
              <BlockText>
                <Text
                  type="H4"
                  bold
                  content="Líneas de
Acondicionamiento"
                  color="#002C1C"
                />
              </BlockText>
              <BlockText right>
                <p>
                  Ofrecemos a la industria el acondicionamiento secundario de
                  una gran variedad de formatos para sus productos. Estamos en
                  capacidad de ejecutar procesos como: estuchado, desestuchado,
                  termoencogido, empaque, codificado Ink Jet, etiquetado,
                  colocación de sticker e insertos, reacondicionamiento y armado
                  de paquetes promocionales.
                </p>
              </BlockText>
            </BoxText>
          </Animate.FadeUp>
          <Animate.FadeUp>
            <BoxText>
              <BlockText right alignTextRight>
                <p>
                  Nuestro proceso de Investigación y desarrollo de productos,
                  brinda una respuesta integral para el desarrollo de la
                  formulación personalizada que cada cliente solicite según su
                  mercado. Siempre orientado a responder a las demandas y
                  tendencias del consumidor. Tenemos capacidad instalada para el
                  desarrollo de producto farmacéuticos, cosméticos y cuidado del
                  hogar desde el diseño de material de empaque primario y
                  secundario y la elaboración de dossier. Todos nuestros
                  desarrollos siempre están apegados a las buenas prácticas de
                  manufactura vigentes en el mercado. Actualmente contamos con
                  un portfolio de cuatro marcas privadas de medicamentos en
                  constante crecimiento. Y una marca de Cuidado del Hogar
                  perteneciente a una de las cadenas de supermercados más
                  importantes de la región. Contamos con todos los recursos,
                  equipos, tecnología y conocimiento técnico para acelerar el
                  crecimiento de marcas y productos de nuestros asociados
                  comerciales.
                </p>
              </BlockText>
              <BlockText>
                <Text
                  type="H4"
                  bold
                  content="Investigación y Desarrollo"
                  color="#002C1C"
                />
              </BlockText>
            </BoxText>
          </Animate.FadeUp>
        </div>
      )}
    </LayoutMain>
  );
};

export default Services;
