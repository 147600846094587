import React, { FC, useEffect, useState } from "react";
import { createContext, useContext } from "react";
import { contextUser } from "../commons/interfaces/contextInterface/userContext.interface";
import { userLogged } from "../commons/interfaces/user.interface";

const userContext = createContext<contextUser>({
  user: {
    access_token: null,
    user: {
      password: null,
      user: null,
    },
  },
  setuser: () => {
    return;
  },
});

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setuser] = useState<userLogged>({
    access_token: null,
    user: {
      password: null,
      user: null,
    },
  });
  useEffect(() => {
    const stringCredential = sessionStorage.getItem("credential");
    if (typeof stringCredential === "string") {
      const credential: userLogged = JSON.parse(stringCredential);
      setuser(credential);
    }
  }, []);

  return (
    <userContext.Provider value={{ user, setuser }}>
      {children}
    </userContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(userContext);
  return context;
};
