import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { BsList } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { Image } from "../elementsDS/Content";
import NavDropdownBrands from "./NavDropdownBrands";
import InputSearch from "../search/InputSearch";
import NavDropdownAbaout from "./NavDropdownCategories";
import NavDropdownCategories from "./NavDropdownCategories";

const Header: React.FC = () => {
  const [navbar, setNavbar] = useState(false);
  const navigate = useNavigate();
  const [value, setValue] = useState("");

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value);
  }

  function handlePress() {
    navigate(`/productos/all/all/${value ? value : "vitacreme"}`);
  }

  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    changeBackground();
    window.addEventListener("scroll", changeBackground);
  });

  return (
    <nav
      className={
        navbar
          ? "navbar sticky-top navbar-expand-lg active"
          : "navbar sticky-top navbar-expand-lg"
      }
      style={navbar ? { background: "#024E34" } : { background: "#026141" }}
    >
      <div className="container text-center">
        <Link className="navbar-brand" to="/">
          <Image
            className="navbar-brand"
            src="/img/logo-coinsa-blanco_500x.webp"
            alt="logo"
            width={100}
          />
        </Link>
        <button
          className="navbar-toggler bg-white"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <BsList color="#076142" size={40} />
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <Link className="nav-link" to="/que-nos-hace-diferentes">
              Que nos hace diferentes
            </Link>
            <Link className="nav-link" to="/quienes-somos">
              Quienes Somos
            </Link>
            <div >
              <NavDropdownCategories />
            </div>
            <Link className="nav-link" to="/marcas">
              <NavDropdownBrands />
            </Link>
            <Link className="nav-link" to="/nuestros-servicios">
              Servicios
            </Link>
            <Link className="nav-link" to="/certificaciones">
              Certificaciones
            </Link>
            <Link className="nav-link" to="/contactanos">
              Contáctanos
            </Link>
            <form
              className="d-flex"
              role="search"
              onSubmit={(e) => {
                e.preventDefault();
                navigate(`/productos/all/all/${value ? value : "vitacreme"}`);
              }}
            >
              <InputSearch
                searchGlobal
                globalSearchButtom
                value={value}
                onChange={handleChange}
                eventButton={handlePress}
                placeholder="Busqueda"
              />
            </form>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
