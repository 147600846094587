/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from "react";
import { FcNews } from "react-icons/fc";
import LayoutAdmin from "../../../layouts/layoutAdmin";
import LoadButton from "../../../components/elementsDS/Loaders/LoadButton";
import {
  Input,
  InputFile,
  Label,
  LabelFile,
  Textarea,
} from "../../../components/elementsDS/Forms";
import { Button } from "../../../components/elementsDS/buttons/Buttons";
import { Card } from "../../../components/card/card";
import {
  BsPencil,
  BsPlus,
  BsPlusLg,
  BsTrash,
  BsUpload,
  BsSave,
  BsSaveFill,
} from "react-icons/bs";
import { Hr, Image, Text } from "../../../components/elementsDS/Content";
import { IarticlesPresentation } from "../../../commons/interfaces/article.interface";
import { toast } from "react-toastify";
import { fetchMethod } from "../../../utils/fetch/fecth";
import { useUser } from "../../../context/userContext";
import { useParams } from "react-router-dom";

const EditPresentation: React.FC = () => {
  const { mongoId } = useParams();
  const contextData = useUser();
  const [loading, setloading] = useState(false);
  const [form, setform] = useState<IarticlesPresentation>({
    descripcion: "",
    imagen: "",
    imagenItem: "",
    items: [""],
    titulo: "",
  });
  const [edit, setEdit] = useState(false);
  const [editSelected, setEditSelected] = useState<null | number>(null);
  const [itemInput, setitemInput] = useState("");
  const [items, setItems] = useState<string[]>([]);
  const [image, setimage] = useState<any>();
  const [imageUrl, setImageUrl] = useState<any>();
  const [imageIcon, setimageIcon] = useState<any>("");
  const [newItemValue, setnewItemValue] = useState("");
  const [imageIconUrl, setimageIconUrl] = useState<any>("");

  const updateArticle = async () => {
    setloading(true);
    try {
      let answerImage, answerImage2;
      let message = "";
      if (!form.titulo) message += "agregue un titulo. \n";
      if (!form.descripcion) message += "agregue una descripcion. \n";
      if (message) {
        toast.info(message);
      }
      if (image) {
        const formData = new FormData();
        formData.append("file", image);
        answerImage = await fetchMethod(
          `${process.env.REACT_APP_BACKEND_API}/products/uploadImage`,
          {
            method: "POST",
            body: formData,
            mode: "cors",
          }
        );
        if (!answerImage.success)
          return toast.error("no se pudo subir la imagen");
      }
      if (imageIcon) {
        const formData2 = new FormData();
        formData2.append("file", imageIcon);
        answerImage2 = await fetchMethod(
          `${process.env.REACT_APP_BACKEND_API}/products/uploadImage`,
          {
            method: "POST",
            body: formData2,
            mode: "cors",
          }
        );
        if (!answerImage2.success)
          return toast.error("no se pudo subir la imagen");
      }
      console.log(answerImage2);
      const answer = await fetchMethod(
        `${process.env.REACT_APP_BACKEND_API}/articles/articleBanner/${mongoId}`,
        {
          method: "PUT",
          headers: new Headers({
            Accept: "application/json",
            Authorization: `Bearer ${contextData.user?.access_token}`,
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({
            titulo: form.titulo,
            descripcion: form.descripcion,
            imagen: answerImage?.data?.imageUri
              ? answerImage.data.imageUri
              : imageUrl,
            imagenItem: answerImage2?.data?.imageUri
              ? answerImage2.data.imageUri
              : imageIconUrl,
            items: items,
          }),
        }
      );
      toast[answer.success ? "success" : "error"](answer.message);
    } catch (error: any) {
      toast.error(error.message);
    }
    setloading(false);
  };
  const onchange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setform((value) => ({
      ...value,
      [target.name]: target.value,
    }));
  };
  const onChangeImage = ({ target, currentTarget }: React.ChangeEvent<any>) => {
    const file = currentTarget.files[0];
    setimage(file);
    const url = URL.createObjectURL(file);
    setImageUrl(url);
  };
  const onChangeImage2 = ({
    target,
    currentTarget,
  }: React.ChangeEvent<any>) => {
    const file = currentTarget.files[0];
    setimageIcon(file);
    const url = URL.createObjectURL(file);
    setimageIconUrl(url);
  };
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_API}/articles/findArticleBanner/${mongoId}`
    )
      .then((data) => data.json())
      .then((data) => {
        setform(data.data);
        setItems(data.data.items);
        setImageUrl(data.data.imagen);
        setimageIconUrl(data.data.imagenItem);
      })
      .catch((e) => toast.error(e.message));
  }, []);
  const showWhenEditing = (el: string, iteracion: number) => {
    if (edit) {
      if (editSelected === iteracion) {
        return (
          <input
            className="form-control w-50"
            type="text"
            placeholder=".form-control-sm"
            aria-label=".form-control-sm example"
            value={newItemValue}
            onChange={({ target }: any) => setnewItemValue(target.value)}
          />
        );
      } else {
        return <Text type="Paragraph" content={el} color="#000" />;
      }
    } else {
      return <Text type="Paragraph" content={el} color="#000" />;
    }
  };
  return (
    <LayoutAdmin
      title="Agregar nueva presentación de producto"
      icon={<FcNews size={34} />}
    >
      <div className="container-fluider">
        <div className="row justify-content-center mt-5 g-3">
          <div className="col-12 col-lg-6">
            <Card>
              <Text type="H6" content="Info de producto" color="#000" />
              <Hr />
              <Label>Título</Label>
              <div className="input-group flex-nowrap">
                <Input
                  type="text"
                  placeholder=""
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-lg"
                  id="titulo"
                  name="titulo"
                  value={form.titulo}
                  onChange={onchange}
                />
              </div>
              <Label>Sub Título</Label>
              <div className="input-group flex-nowrap">
                <Input
                  type="text"
                  placeholder=""
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-lg"
                  name="descripcion"
                  value={form.descripcion}
                  onChange={onchange}
                />
              </div>
              <Hr />
              <div>
                <Label className="me-3 mt-4">Cargar imagen de producto</Label>
                <br />
                <LabelFile htmlFor="imageProduct" disabled={loading}>
                  {!loading ? (
                    <>
                      <BsUpload className="me-2" /> Subir
                    </>
                  ) : (
                    <LoadButton message="cargando imágen..." />
                  )}
                  <InputFile
                    type="file"
                    name="imageProduct"
                    id="imageProduct"
                    onChange={onChangeImage}
                  />
                </LabelFile>
                <img
                  src={imageUrl}
                  alt=""
                  width={50}
                  height={100}
                  className="m-1"
                />
                <br />
                <Label className="me-3">Cargar icono de objetivo</Label>
                <br />
                <LabelFile htmlFor="imageIcon" disabled={loading}>
                  {!loading ? (
                    <>
                      <BsUpload className="me-2" /> Subir
                    </>
                  ) : (
                    <LoadButton message="cargando icono..." />
                  )}
                  <InputFile
                    type="file"
                    name="imageIcon"
                    id="imageIcon"
                    onChange={onChangeImage2}
                  />
                </LabelFile>
                <img
                  src={imageIconUrl}
                  alt=""
                  width={50}
                  height={50}
                  className="m-1"
                />
              </div>
            </Card>
          </div>
          <div className="col-12 col-lg-6">
            <Card>
              <Text type="H6" content="Objetivos" color="#000" />
              <Hr />
              <Label>Descripción de objetivo</Label>
              <div className="input-group flex-nowrap">
                <Input
                  type="text"
                  placeholder="texto objetivo"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-lg"
                  value={itemInput}
                  onChange={({ target }) => setitemInput(target.value)}
                />
              </div>
              <div className="d-flex mt-3 justify-content-between align-items-center flex-wrap">
                <Button
                  type="button"
                  light
                  onClick={() => {
                    setitemInput("");
                    setItems([...items, itemInput]);
                  }}
                >
                  Agregar objetivo
                </Button>
              </div>
              <Hr />
              <div className="">
                <div>
                  <Label>Objetivos agregados</Label>
                  <ul>
                    {items.map((el, i) => (
                      <li
                        className="d-flex align-items-center justify-content-between"
                        key={`${el}-${i}`}
                      >
                        <Image
                          src={imageIconUrl}
                          alt="image banner coinsa"
                          loading="lazy"
                          className="me-1"
                          width={60}
                          height={60}
                        />
                        {showWhenEditing(el, i)}
                        <div>
                          <Button
                            type="button"
                            danger
                            className="me-2 m-2"
                            onClick={() => {
                              setItems(items.filter((key, it) => it !== i));
                            }}
                          >
                            <BsTrash />
                          </Button>
                          <Button
                            type="button"
                            className=""
                            onClick={
                              !edit
                                ? () => {
                                    setnewItemValue(el);
                                    setEdit(true);
                                    setEditSelected(i);
                                    toast.info(
                                      "El botón de editar, cliquéalo cuando quieras guardar el nuevo valor"
                                    );
                                  }
                                : () => {
                                    setEdit(false);
                                    setEditSelected(null);
                                    const copyItems = [...items];
                                    copyItems[i] = newItemValue;
                                    setItems(copyItems);
                                  }
                            }
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            data-bs-title="Tooltip on top"
                          >
                            {edit && editSelected === i ? (
                              <BsSaveFill />
                            ) : (
                              <BsPencil />
                            )}
                          </Button>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <Hr />
            </Card>
          </div>
        </div>
      </div>
      <Button
        className="w-100  mx-10 my-2"
        primary={true}
        type="button"
        onClick={updateArticle}
        disabled={loading}
      >
        {loading ? <LoadButton /> : "Actualizar"}
      </Button>
    </LayoutAdmin>
  );
};

export default EditPresentation;
