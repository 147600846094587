import styled from "styled-components";
import { colors } from "./utilsStyle/PaletteColor";
import { typography } from "./utilsStyle/Typography";

const HeaderTitle = styled.div`
  padding-top: 10px;
  padding-bottom: 8px;
  margin-top: 100px;
  font-family: ${typography.fontSecondary};
  border-radius: 0;
  background-color: ${colors.gray3};
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 1.7em;
  font-family: ${typography.fontSecondary};
  color: #086141;
  font-weight: 600;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-color: ${colors.gray3};
`;

const Content = styled.div`
  background-color: ${colors.gray3};
  min-height: 100vh;
  width: 100%;
  transition: all 0.3s;
  padding-left: 160px;
  padding-right: 80px;
  padding-bottom: 50px;
  margin: auto;
  @media (max-width: 600px) {
    padding-left: 30px;
    padding-right: 30px;
  }
`;

const HeaderNav = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border: 0;
  padding-top: 1.1rem;
  padding-bottom: 1.1rem;
  padding-right: 4.2rem;
  padding-left: 9rem;
  margin-bottom: 1rem;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  @media (max-width: 600px) {
    padding-left: 30px;
    padding-right: 14px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1366px) {
    padding-left: 10px;
    padding-right: 20px;
  }
`;

export { Title, Wrapper, Content, HeaderNav, HeaderTitle };
