/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from "react";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { toast } from "react-toastify";
import Pagination from "rc-pagination";
import { BsPlusLg } from "react-icons/bs";
import { GrDeploy } from "react-icons/gr";
import { Button } from "../../../../components/elementsDS/buttons/Buttons";
import LayoutAdmin from "../../../../layouts/layoutAdmin";
import { Text } from "../../../../components/elementsDS/Content";
import { fetchMethod, fetchMethodPost } from "../../../../utils/fetch/fecth";
import {
  IanswerProduct,
  Iproduct,
} from "../../../../commons/interfaces/products.interface";
import {
  IapiAnswerGeneral,
  Icategory,
  IcategoryAnswer,
} from "../../../../commons/interfaces/category.interface";
import ProductCard from "./ProductCard";
import ModalAddProducts from "./ModalAddProducts";
import { storageFirebase } from "../../../../App";
import { useUser } from "../../../../context/userContext";
import ModalUpdateImageProduct from "./ModalUpdateImage";
import {
  deleteImage,
  uploadImage,
} from "../../../../utils/uploadFiles/firebaseStorage";
import { imagePath } from "../../../../commons/const/firebaseImagePath";
import getBase64 from "../../../../utils/uploadFiles/imageTransform";
import { json } from "stream/consumers";
import InputSearch from "../../../../components/search/InputSearch";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    height: "100vh",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    zIndex: 1060,
  },
};
const customStyles2 = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    zIndex: 1060,
  },
};

interface Props {
  subtitle?: string;
  loading?: boolean;
}
const ListProducts: React.FC<Props> = ({ subtitle }) => {
  const contextData = useUser();
  const [loading, setloading] = useState(false);
  const [productName, setproductName] = useState("");
  const [form, setform] = useState<Iproduct>({
    cod_ldcom: "",
    presentacion: "",
    _id: "",
    codigoBarra: 0,
    GNEXUS: 0,
    nombreProducto: "",
    precio: 0,
    SAP: 0,
    urlImage: "",
    uxc: "",
    categoria: "",
    subCategoria: "",
    marca: "",
    imageName: "",
  });
  const [productIdEdit, setproductIdEdit] = useState("");
  const [categories, setCategories] = useState<Icategory[]>([]);
  const [urlImage, seturlImage] = useState<any>();
  const [subCategories, setsubCategories] = useState<any>([]);
  const [image, setImage] = useState<any>();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [products, setproducts] = useState<Iproduct[]>();
  const [skip, setskip] = useState(0);
  const [limit, setlimit] = useState(32);
  const [current, setcurrent] = useState(1);
  const [counterDocuments, setcounterDocuments] = useState<any | number>(0);
  const [isEditing, setisEditing] = useState(false);
  const [openUpdateImageModal, setopenUpdateImageModal] = useState(false);
  const [imageUpdate, setImageUpdate] = useState<any>();
  const [urlImageUpdate, seturlImageUpdate] = useState<any>();
  function openModal() {
    setIsOpen(true);
  }

  // function afterOpenModal() {
  //   // references are now sync'd and can be accessed.
  //   subtitle.style.color = '#f00';
  // }

  function closeModal() {
    setloading(false);
    setform({
      cod_ldcom: "",
      presentacion: "",
      _id: "",
      codigoBarra: 0,
      GNEXUS: 0,
      nombreProducto: "",
      precio: 0,
      SAP: 0,
      urlImage: "",
      uxc: "",
      categoria: "",
      subCategoria: "",
      marca: "",
      imageName: "",
    });
    setisEditing(false);
    setImage(null);
    seturlImage(null);
    setIsOpen(false);
  }

  const options = [
    { value: "VITACREME", label: "VITACREME" },
    { value: "MADONNA", label: "MADONNA" },
    { value: "ROY VIGAN", label: "ROY VIGAN" },
    { value: "DERMA NATURAL", label: "DERMA NATURAL" },
    { value: "CHARISMA", label: "CHARISMA" },
    { value: "NODOR ", label: "NODOR " },
    { value: "HOMBRE", label: "HOMBRE" },
    { value: "BUG OFF", label: "BUG OFF" },
    { value: "PULMOVAPOR", label: "PULMOVAPOR" },
    { value: "FUNGIPLUS", label: "FUNGIPLUS" },
    { value: "BETAFARM", label: "BETAFARM" },
    { value: "BRONCOMED", label: "BRONCOMED" },
    { value: "FARINTER", label: "FARINTER" },
    { value: "MEGALAB", label: "MEGALAB" },
    { value: "STERISCRUB", label: "STERISCRUB" },
    { value: "MEDILAB", label: "MEDILAB" },
    { value: "GENÉRICO", label: "GENÉRICO" },
    { value: "HYDE PARK", label: "HYDE PARK" },
    { value: "HAND CLEANER", label: "HAND CLEANER" },
    { value: "FUCIFARM", label: "FUCIFARM" },
  ];
  const onChangeImage = ({ target, currentTarget }: React.ChangeEvent<any>) => {
    const file = currentTarget.files[0];
    setImage(file);
    const url = URL.createObjectURL(file);
    seturlImage(url);
  };
  const onChangeImageUpdate = ({
    target,
    currentTarget,
  }: React.ChangeEvent<any>) => {
    const file = currentTarget.files[0];
    setImageUpdate(file);
    const url = URL.createObjectURL(file);
    seturlImageUpdate(url);
  };
  const onChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setform({
      ...form,
      [target.name]: target.value,
    });
  };
  const onChangeMarca = (data: any) => {
    setform({
      ...form,
      marca: data.value,
    });
  };
  const onChangeCategory = (data: any) => {
    setform({
      ...form,
      categoria: data.value,
    });
    const subCategoriesOptions = categories
      .find((el) => el.nombre === data.value)
      ?.subCategories.map((el) => ({
        value: el.nombre,
        label: el.nombre,
      }));
    setsubCategories(subCategoriesOptions);
  };
  const onChangeSubCategory = (data: any) => {
    setform({
      ...form,
      subCategoria: data.value,
    });
  };
  const saveProducts = async () => {
    setloading(true);
    try {
      let message = "";
      if (!image) message += "agregue una imagen de producto. \n";
      if (!form.nombreProducto) message += "agregue el nombre de producto. \n";
      if (!form.presentacion) message += "agregue la presentacion. \n";
      if (!form.categoria) message += "selecione una categoria. \n";
      if (!form.subCategoria) message += "agregue la subcategoria. \n";
      if (!form.marca) message += "agregue una marca. \n";
      if (message) return toast.info(message);
      const formData = new FormData();
      formData.append("file", image);
      const answerUploadImage = await fetchMethod(
        `${process.env.REACT_APP_BACKEND_API}/products/uploadImage`,
        {
          method: "POST",
          body: formData,
          mode: "cors",
        }
      );
      const answer: IapiAnswerGeneral = await fetchMethod(
        `${process.env.REACT_APP_BACKEND_API}/products`,
        {
          method: "POST",
          headers: new Headers({
            Accept: "application/json",
            Authorization: `Bearer ${contextData.user?.access_token}`,
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({
            nombreProducto: form.nombreProducto,
            precio: 0,
            GNEXUS: 0,
            SAP: 0,
            codigoBarra: 0,
            presentacion: form.presentacion,
            uxc: "---",
            categoria: [form.categoria],
            subCategoria: [form.subCategoria],
            marca: form.marca,
            urlImage: answerUploadImage.data.imageUri,
            imageName: image.name,
          }),
        }
      );
      if (answer.success) {
        getProducts();
        toast.success(answer.message);
        setform({
          cod_ldcom: "",
          presentacion: "",
          _id: "",
          codigoBarra: 0,
          GNEXUS: 0,
          nombreProducto: "",
          precio: 0,
          SAP: 0,
          urlImage: "",
          uxc: "",
          categoria: "",
          subCategoria: "",
          marca: "",
          imageName: "",
        });
      } else toast.error(answer.message);
    } catch (error: any) {
      toast.error(error.message);
    }
    setloading(false);
  };
  const closeModalUpdateImage = () => {
    setform({
      cod_ldcom: "",
      presentacion: "",
      _id: "",
      codigoBarra: 0,
      GNEXUS: 0,
      nombreProducto: "",
      precio: 0,
      SAP: 0,
      urlImage: "",
      uxc: "",
      categoria: "",
      subCategoria: "",
      marca: "",
      imageName: "",
    });
    setopenUpdateImageModal(false);
    setImageUpdate(null);
    seturlImageUpdate(null);
    setproductIdEdit("");
  };
  const updateProduct = async () => {
    setloading(true);
    let message = "";
    if (!form.nombreProducto) message += "agregue el nombre de producto. \n";
    if (!form.presentacion) message += "agregue la presentacion. \n";
    if (!form.categoria) message += "selecione una categoria. \n";
    if (!form.subCategoria) message += "agregue la subcategoria. \n";
    if (!form.marca) message += "agregue una marca. \n";
    if (message) {
      setloading(false);
      return toast.info(message);
    }
    const answer: IapiAnswerGeneral = await fetchMethod(
      `${process.env.REACT_APP_BACKEND_API}/products/${productIdEdit}`,
      {
        method: "PUT",
        headers: new Headers({
          Accept: "application/json",
          Authorization: `Bearer ${contextData.user?.access_token}`,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          nombreProducto: form.nombreProducto,
          precio: form.precio,
          GNEXUS: form.GNEXUS,
          SAP: form.SAP,
          codigoBarra: form.codigoBarra,
          presentacion: form.presentacion,
          uxc: form.uxc,
          categoria: [form.categoria],
          subCategoria: [form.subCategoria],
          marca: form.marca,
          urlImage: form.urlImage,
        }),
        mode: "cors",
      }
    );
    setloading(false);
    if (answer.success) {
      toast.success(answer.message);
      closeModal();
      getProducts();
    } else toast.error(answer.message);
  };
  const updateImage = async () => {
    setloading(true);
    try {
      const formData = new FormData();
      formData.append("file", imageUpdate);
      formData.append(
        "idLdcom",
        form.cod_ldcom !== "#ERROR_#N/A" ? form.cod_ldcom : form._id
      );
      const annswer = await fetchMethod(
        `${process.env.REACT_APP_BACKEND_API}/products/uploadImage`,
        {
          method: "POST",
          body: formData,
          mode: "cors",
        }
      );
      const formData2 = new FormData();
      formData2.append("urlImage", annswer.data.imageUri);
      const answer2 = await fetchMethod(
        `${process.env.REACT_APP_BACKEND_API}/products/${form._id}`,
        {
          method: "PUT",
          headers: new Headers({
            Authorization: `Bearer ${contextData.user?.access_token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          }),
          body: JSON.stringify({
            urlImage: annswer.data.imageUri,
          }),
          mode: "cors",
        }
      );
      toast[answer2.success ? "success" : "error"](answer2.message);
      getProducts();
      closeModalUpdateImage();
    } catch (error: any) {
      toast.error(error.message);
    }
    setloading(false);
  };
  const getProducts = async () => {
    setproducts([]);
    const data: IanswerProduct = await fetchMethod(
      `${process.env.REACT_APP_BACKEND_API}/products/findAll?skip=${skip}&limit=10&productName=${productName}`,
      {}
    );
    setproducts(data.data);
  };
  const countDocuments = async () => {
    const data: IapiAnswerGeneral = await fetchMethod(
      `${process.env.REACT_APP_BACKEND_API}/products/countDocuments`,
      {}
    );
    setcounterDocuments(data.data);
  };
  const getCategories = async () => {
    const data: IcategoryAnswer = await fetchMethod(
      `${process.env.REACT_APP_BACKEND_API}/categories?limit=${limit}&skip=${skip}`,
      {}
    );
    setCategories(data.data);
  };
  const editProduct = (product: Iproduct) => {
    setisEditing(true);
    setproductIdEdit(product._id);
    setform(product);
    setIsOpen(true);
  };
  useEffect(() => {
    getProducts();
  }, [skip, limit]);
  useEffect(() => {
    getCategories();
    countDocuments();
  }, []);
  return (
    <LayoutAdmin title="Dashboard / Productos /" icon={<GrDeploy size={34} />}>
      <ModalUpdateImageProduct
        modalIsOpen={openUpdateImageModal}
        customStyles={customStyles2}
        loading={loading}
        closeModal={closeModalUpdateImage}
        onChangeImage={onChangeImageUpdate}
        updateImage={updateImage}
        urlImage={urlImageUpdate}
      />
      <ModalAddProducts
        updateProduct={updateProduct}
        isEditing={isEditing}
        productData={form}
        closeModal={closeModal}
        customStyles={customStyles}
        loading={loading}
        modalIsOpen={modalIsOpen}
        optionsMarcas={options}
        optionsSubCategoria={subCategories}
        optionsCategoria={categories.map((category) => ({
          value: category.nombre,
          label: category.nombre,
        }))}
        imageUrl={urlImage}
        onChangeImage={onChangeImage}
        onChange={onChange}
        onChangeMarca={onChangeMarca}
        onChangeCategory={onChangeCategory}
        onChangeSubCategory={onChangeSubCategory}
        saveProducts={saveProducts}
      />
      <div className="d-flex justify-content-between align-items-center">
        <Button primary type="button" className="mb-3" onClick={openModal}>
          <BsPlusLg /> Agregar nuevo producto
        </Button>

        <Text type="H6" content="Lista de Productos" color="#5C6562" />
        <div>
          <InputSearch
            placeholder="Busqueda de nombre de producto"
            onChange={({ target }: any) => setproductName(target.value)}
            eventButton={() => getProducts()}
          />
        </div>
      </div>
      <div className="row mt-3 g-3">
        {products?.map((product) => (
          <ProductCard
            key={product._id}
            categoria={product.categoria[0]}
            nombreProducto={product.nombreProducto}
            urlImage={product.urlImage}
            editProduct={() => {
              setisEditing(true);
              // setform({
              //   ...form,
              //   categoria: data.value,
              // });
              editProduct(product);
            }}
            editProductImage={() => {
              setform(product);
              setopenUpdateImageModal(true);
              setproductIdEdit(product._id);
            }}
          />
        ))}
        <Pagination
          total={counterDocuments}
          className="mt-5"
          defaultPageSize={10}
          current={current}
          onChange={(actualPostion) => {
            setskip(actualPostion * 10 - 10);
            setcurrent(actualPostion);
          }}
        />
      </div>
    </LayoutAdmin>
  );
};

export default ListProducts;
