import React from "react";
import {
  Navigate,
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

//Home
import Home from "./pages/home/home";
import AboutUs from "./pages/home/aboutUs";
import MakesDifferent from "./pages/home/makesDifferent";
import Brands from "./pages/home/brands";
import Contact from "./pages/home/contact";
import Services from "./pages/home/services";
import Certifications from "./pages/home/certifications";
import ArticlesPage from "./pages/home/articles/articlesPage";
import PageNotFound from "./pages/pageNotFound";
import PageTermsCond from "./pages/pageTermsCond";

// shop
import Category from "./pages/shop/CategoryPage";
import SearchProducts from "./pages/shop/searchProducts";

// dashboard
import Dashboard from "./pages/dashboard/dashboard";
import Login from "./pages/dashboard/auth/login";
import ListCategories from "./pages/dashboard/catalogue/category/ListCategories";
import ListProducts from "./pages/dashboard/catalogue/products/listProducts";
import { UserProvider, useUser } from "./context/userContext";
import { userLogged } from "./commons/interfaces/user.interface";
import ListArticles from "./pages/dashboard/content/ListArticles";
import AddArticle from "./pages/dashboard/content/AddArticle";
import EditPage from "./pages/dashboard/content/EditPage";
import ListPage from "./pages/dashboard/content/ListPage";
import ListBanner from "./pages/dashboard/content/ListBanner";
import AllArticles from "./pages/home/articles/allArticles";
import ListPresentation from "./pages/dashboard/content/ListPresentation";
import AddPresentation from "./pages/dashboard/content/AddPresentation";
import EditPresentation from "./pages/dashboard/content/EditPresentation";
import SearchProductsBrands from "./pages/shop/searchProductsBrands";
import ExperiencePage from "./pages/home/experiencePage";

const firebaseConfig = {
  apiKey: "AIzaSyDOBrZ7NQcBvz3_8Ukj2chzdsSJhUzcjww",
  authDomain: "coinsa-70990.firebaseapp.com",
  projectId: "coinsa-70990",
  storageBucket: "coinsa-70990.appspot.com",
  messagingSenderId: "1022630669497",
  appId: "1:1022630669497:web:1e7ae33154a24266d13f68",
};
const app = initializeApp(firebaseConfig);
export const storageFirebase = getStorage(app);

const App: React.FC = () => {
  const contextData = useUser();
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/detalle/articulo/:mongoId" element={<ArticlesPage />} /> */}
          {/* <Route path="/listado/articulos" element={<AllArticles />} /> */}
          <Route path="/360-experiencia-virtual" element={<ExperiencePage />} />
          <Route path="/que-nos-hace-diferentes" element={<MakesDifferent />} />
          <Route path="/quienes-somos" element={<AboutUs />} />
          <Route path="/marcas" element={<Brands />} />
          <Route path="/nuestros-servicios" element={<Services />} />
          <Route path="/certificaciones" element={<Certifications />} />
          <Route path="/contactanos" element={<Contact />} />
          <Route path="/terminos-condiciones" element={<PageTermsCond />} />
          <Route path="/categorias" element={<Category />} />
          <Route path="/categoria/:categoria" element={<Category />} />
          <Route
            path="/productos/:categoria/:sucategoria/:productssearch"
            element={<SearchProducts />}
          />
          <Route
            path="/productos/marca/:marca"
            element={<SearchProductsBrands />}
          />
          <Route path="/dashboard/login" element={<Login />} />
          {contextData?.user?.access_token && (
            <>
              <Route
                path="/dashboard/"
                element={<Navigate to="/dashboard/productos" />}
              />
              <Route
                path="/dashboard/categorias"
                element={<ListCategories />}
              />
              <Route path="/dashboard/productos" element={<ListProducts />} />
              <Route path="/dashboard/articulos" element={<ListArticles />} />
              <Route
                path="/dashboard/agregar/articulo/:articleId"
                element={<AddArticle />}
              />
              <Route
                path="/dashboard/editar/pagina/:type"
                element={<EditPage />}
              />
              <Route path="/dashboard/paginas" element={<ListPage />} />
              <Route path="/dashboard/banners" element={<ListBanner />} />
              <Route
                path="/dashboard/presentacion"
                element={<ListPresentation />}
              />
              <Route
                path="/dashboard/agregar/presentacion"
                element={<AddPresentation />}
              />
              <Route
                path="/dashboard/editar/presentacion/:mongoId"
                element={<EditPresentation />}
              />
            </>
          )}

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

const WrappedApp = () => {
  return (
    <UserProvider>
      <App />
    </UserProvider>
  );
};

export default WrappedApp;
