/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button } from "../elementsDS/buttons/Buttons";
import { ICategoryProps } from "../../commons/ICategoryProps";
import { Animate } from "../elementsDS/animations/animations";
export const ImageCategory = styled.img`
  &:hover {
    filter: grayscale(0);
    border: 3px solid #086141;
  }
  object-fit: cover;
  height: 250px;
  width: 250px;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
`;

export const CardCategory = (newsItem: ICategoryProps) => {
  return (
    <div className="col-12 col-lg-3">
      <Link to={newsItem.link}>
        <Animate.ScaleIn>
          <div>
            <ImageCategory
              src={newsItem.image}
              alt="image categorias"
              className="img-fluid"
              loading="lazy"
            />
            <br />
            <Button primary type="button" floating>
              {newsItem.name}
            </Button>
          </div>
        </Animate.ScaleIn>
      </Link>
    </div>
  );
};
