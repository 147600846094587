import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Background = styled.div`
  background-color: white;
  padding: 150px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
`;

const Text = styled.p`
  font-size: 15px;
  font-weight: normal;
  animation-name: bounce;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  color: #787878;
  @keyframes bounce {
    0% {
      transform: translateY(0px);
    }
    40% {
      transform: translateY(-40px);
    }
    80%,
    100% {
      transform: translateY(0px);
    }
  }
`;

const SearchData = ({ text, sizeIcon }) => (
  <Background>
    <Image src="/img/searchinfo.gif" alt="gif de busqueda" width={sizeIcon} />{" "}
    <Text> {text} </Text>{" "}
  </Background>
);
SearchData.propTypes = {
  text: PropTypes.string.isRequired,
  sizeIcon: PropTypes.string.isRequired,
};

export default SearchData;
