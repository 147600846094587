/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from "react";
import { FcImageFile } from "react-icons/fc";
import LayoutAdmin from "../../../layouts/layoutAdmin";
import {
  InputFile,
  Label,
  LabelFile,
} from "../../../components/elementsDS/Forms";
import LoadButton from "../../../components/elementsDS/Loaders/LoadButton";
import { Button } from "../../../components/elementsDS/buttons/Buttons";
import { Hr, Image, Text } from "../../../components/elementsDS/Content";
import { BsTrash, BsUpload } from "react-icons/bs";
import { fetchMethod } from "../../../utils/fetch/fecth";
import { toast } from "react-toastify";
import {
  IapiAnswer,
  IapiAnswerGeneral,
} from "../../../commons/interfaces/category.interface";
import { useUser } from "../../../context/userContext";
import { GrUpdate } from "react-icons/gr";

const ListBanner: React.FC = () => {
  const contextData = useUser();
  const [loading, setloading] = useState(false);
  const [image, setimage] = useState<any[]>([]);
  const [mongoIdSelected, setmongoIdSelected] = useState("");
  const uploadImage = async ({
    target,
    currentTarget,
  }: React.ChangeEvent<any>) => {
    setloading(true);
    try {
      const file = currentTarget.files[0];
      const formData = new FormData();
      formData.append("file", file);
      const answerImage = await fetchMethod(
        `${process.env.REACT_APP_BACKEND_API}/products/uploadImage`,
        {
          method: "POST",
          body: formData,
          mode: "cors",
        }
      );

      const data = JSON.stringify({
        imageUrl: answerImage.data.imageUri,
        type: "PRINCIPAL_BANNER",
      });

      const answer: IapiAnswerGeneral = await fetchMethod(
        `${process.env.REACT_APP_BACKEND_API}/images-banners`,
        {
          headers: new Headers({
            Accept: "application/json",
            Authorization: `Bearer ${contextData.user?.access_token}`,
            "Content-Type": "application/json",
          }),
          method: "POST",
          body: data,
        }
      );

      toast[answer.success ? "success" : "error"](answer.message);
      getImageBanner();
    } catch (error: any) {
      toast.error("error     " + error.message);
    }
    setloading(false);
  };
  const updateImage = async (mongoId: string, iteracionPosition: number) => {
    try {
      const answer: IapiAnswer = await fetchMethod(
        `${process.env.REACT_APP_BACKEND_API}/images-banners/${mongoId}`,
        {
          headers: new Headers({
            Accept: "application/json",
            Authorization: `Bearer ${contextData.user?.access_token}`,
            "Content-Type": "application/json",
          }),
          method: "PUT",
          body: JSON.stringify({
            // @ts-ignore
            link: document.getElementById(`link-${iteracionPosition}`).value,
            // @ts-ignore
            position: document.getElementById(`position-${iteracionPosition}`).value,
          }),
        }
      );
      toast[answer.success ? "success" : "error"](answer.message);
      if (answer.success) getImageBanner();
    } catch (error: any) {
      toast.error(error.message);
    }
  };
  const deleteImage = async (mongoId: string) => {
    try {
      const answer: IapiAnswerGeneral = await fetchMethod(
        `${process.env.REACT_APP_BACKEND_API}/images-banners/${mongoId}`,
        {
          headers: new Headers({
            Authorization: `Bearer ${contextData.user?.access_token}`,
          }),
          method: "DELETE",
        }
      );
      toast[answer.success ? "success" : "error"](answer.message);
      getImageBanner();
    } catch (error: any) {
      toast.error(error.message);
    }
  };
  const getImageBanner = async () => {
    try {
      const answer: any = await fetchMethod(
        `${process.env.REACT_APP_BACKEND_API}/images-banners/PRINCIPAL_BANNER`,
        {}
      );
      setimage(answer.data);
    } catch (error: any) {
      toast.error(error.message);
    }
  };
  useEffect(() => {
    getImageBanner();
  }, []);
  return (
    <LayoutAdmin title="Listado de Banners" icon={<FcImageFile size={34} />}>
      <div className="container-fluider">
        <div className="d-flex justify-content-end">
          <LabelFile htmlFor="newImage" disabled={loading}>
            {!loading ? (
              <>
                <i className="fa fa-upload me-2" />{" "}
                {loading ? "cargando " : "Cargar nueva Imágen"}
              </>
            ) : (
              <LoadButton message="cargando imágen..." />
            )}
            <InputFile
              disabled={loading}
              type="file"
              name="newImage"
              id="newImage"
              onChange={uploadImage}
            />
          </LabelFile>
        </div>
        {image.map((el, i) => (
          <>
            <div
              className="d-flex justify-content-between flex-wrap"
              key={el._id}
            >
              <div>
                <Label>Imagen #</Label>
                <br />
                <Text type="Paragraph" color="#000" content={el._id} />
              </div>
              <div>
                <Label>Banner</Label>
                <br />
                <Image
                  src={el.imageUrl}
                  alt="banner"
                  loading="lazy"
                  width={350}
                  height={100}
                />
              </div>
              <div>
                <div className="mb-3">
                  <label htmlFor={`link-${i}`} className="form-label">
                    link
                  </label>
                  <input
                    className="form-control"
                    id={`link-${i}`}
                    placeholder="link a donde ira"
                    defaultValue={el.link}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor={`position-${i}`} className="form-label">
                    posicion
                  </label>
                  <input
                    id={`position-${i}`}
                    type="number"
                    className="form-control"
                    placeholder="orden de posicion"
                    defaultValue={el.position}
                  />
                </div>
              </div>
              <div>
                <Label>Acciones</Label>
                <br />
                <Button
                  type="button"
                  danger
                  className="me-2"
                  onClick={() => deleteImage(el._id)}
                >
                  <BsTrash />
                </Button>
                <Button
                  type="button"
                  className="me-2"
                  onClick={() => updateImage(el._id, i)}
                >
                  <GrUpdate />
                </Button>
              </div>
            </div>
            <Hr />
          </>
        ))}
      </div>
    </LayoutAdmin>
  );
};

export default ListBanner;
