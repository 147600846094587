import { createGlobalStyle } from "styled-components";
export const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Avenir', sans-serif;
  }

  :root {
    --animate-duration: 800ms;
    --animate-delay: 0.9s;
  }

  .zoom:hover {
    transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
    transition: all ease-out 1s; /* Animation */
    
  }

  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    outline: 0;
  }

  .alice-carousel__dots-item:not(.__custom).__active {
    background-color: #A9C7A4;
  }

  .alice-carousel__dots {
    margin: 30px 3px 5px;
    padding: 0;
    list-style: none;
    text-align: center;
    position: relative;
    top: -110px;
    left: 0;
    right: 0;
  }

  .btn-prev-carrousel {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin-left: 40px;
    z-index: 1;
    padding: 0;
    border: 0;
    background: transparent;
    outline: 0;
  }

  .btn-next-carrousel {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin-right: 40px;
    z-index: 1;
    padding: 0;
    border: 0;
    background: transparent;
    outline: 0;
  }

  .icon-carrousel {
    font-size: 40px;
  }

  .nav-link {
    font-size: 1rem;
    font-weight: 500;
    line-height: 19px;
    font-style: normal;
    color: #fff;
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
  }
  
  .nav-link:hover {
    color: #3e8d90;
  }

  @media (max-width: 1399px) {
    .nav-link {
    margin: 0;
    font-size: 0.8rem;
  }

  .icon-carrousel {
    font-size: 20px;
  }
  }

  :focus {
    outline-color: transparent;
    outline-style: none;
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
  }

  // .Modal {
  //   position: absolute;
  //   top: 40px;
  //   left: 40px;
  //   right: 40px;
  //   bottom: 40px;
  //   background-color: papayawhip;
  // }

  // .Overlay {
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   background-color: papayawhip;
  //   opacity: 0.8;
  //   z-index: 1060;
  // }

  .rc-table {
    position: relative;
    box-sizing: border-box;
    color: #666;
    font-size: 12px;
    line-height: 1.5;
  }
  .rc-table-rtl {
    direction: rtl;
  }
  .rc-table table {
    width: 100%;
    border-spacing: 0px;
  }
  .rc-table th,
  .rc-table td {
    position: relative;
    box-sizing: border-box;
    padding: 0;
    padding: 16px 8px;
    white-space: normal;
    word-break: break-word;
    border: 1px solid gray;
    border-top: 0;
    border-left: 0;
    transition: box-shadow 0.3s;
  }
  .rc-table-rtl.rc-table th,
  .rc-table-rtl.rc-table td {
    border-right: 0;
    border-left: 1px solid gray;
  }
  .rc-table-cell-fix-left,
  .rc-table-cell-fix-right {
    z-index: 1;
  }
  .rc-table-cell-fix-right:last-child:not(.rc-table-cell-fix-sticky) {
    border-right-color: transparent;
  }
  .rc-table-rtl .rc-table-cell-fix-right:last-child {
    border-right-color: transparent;
  }
  .rc-table-rtl .rc-table-cell-fix-left:last-child {
    border-left-color: transparent;
  }
  .rc-table-rtl .rc-table-cell-fix-left-first {
    box-shadow: 1px 0 0 transparent;
  }
  .rc-table-cell-fix-left-first::after,
  .rc-table-cell-fix-left-last::after {
    position: absolute;
    top: 0;
    right: -1px;
    bottom: -1px;
    width: 20px;
    transform: translateX(100%);
    transition: box-shadow 0.3s;
    content: '';
    pointer-events: none;
  }
  .rc-table-cell-fix-left-all::after {
    display: none;
  }
  .rc-table-cell-fix-right-first,
  .rc-table-cell-fix-right-last {
    box-shadow: -1px 0 0 gray;
  }
  .rc-table-rtl .rc-table-cell-fix-right-first,
  .rc-table-rtl .rc-table-cell-fix-right-last {
    box-shadow: none;
  }
  .rc-table-cell-fix-right-first::after,
  .rc-table-cell-fix-right-last::after {
    position: absolute;
    top: 0;
    bottom: -1px;
    left: -1px;
    width: 20px;
    transform: translateX(-100%);
    transition: box-shadow 0.3s;
    content: '';
    pointer-events: none;
  }
  .rc-table-cell.rc-table-cell-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .rc-table-cell.rc-table-cell-ellipsis.rc-table-cell-fix-left-first,
  .rc-table-cell.rc-table-cell-ellipsis.rc-table-cell-fix-left-last,
  .rc-table-cell.rc-table-cell-ellipsis.rc-table-cell-fix-right-first .rc-table-cell.rc-table-cell-ellipsis.rc-table-cell-fix-right-last {
    overflow: visible;
  }
  .rc-table-cell.rc-table-cell-ellipsis.rc-table-cell-fix-left-first .rc-table-cell-content,
  .rc-table-cell.rc-table-cell-ellipsis.rc-table-cell-fix-left-last .rc-table-cell-content,
  .rc-table-cell.rc-table-cell-ellipsis.rc-table-cell-fix-right-first .rc-table-cell.rc-table-cell-ellipsis.rc-table-cell-fix-right-last .rc-table-cell-content {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .rc-table-cell.rc-table-cell-row-hover {
    background: #f1f1f1;
  }
  .rc-table-ping-left .rc-table-cell-fix-left-first::after,
  .rc-table-ping-left .rc-table-cell-fix-left-last::after {
    box-shadow: inset 10px 0 8px -8px #f1f1f1;
  }
  .rc-table-ping-right .rc-table-cell-fix-right-first::after,
  .rc-table-ping-right .rc-table-cell-fix-right-last::after {
    box-shadow: inset -10px 0 8px -8px #f1f1f1;
  }
  .rc-table-expand-icon-col {
    width: 60px;
  }
  .rc-table-row-expand-icon-cell {
    text-align: center;
  }
  .rc-table thead th {
    text-align: center;
    background: #f7f7f7;
  }
  .rc-table thead .rc-table-cell-scrollbar::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -1px;
    width: 1px;
    background: #f7f7f7;
    content: '';
  }
  .rc-table-rtl.rc-table thead .rc-table-cell-scrollbar::after {
    right: -1px;
    left: auto;
  }
  .rc-table-header {
    border: 1px solid gray;
    border-right: 0;
    border-bottom: 0;
  }
  .rc-table-placeholder {
    text-align: center;
  }
  .rc-table tbody tr td {
    background: #fff;
  }
  .rc-table tbody tr th {
    background: #f7f7f7;
  }
  .rc-table-content {
    border: 1px solid #f1f1f1;
    border-right: 0;
    border-bottom: 0;
    border-radius: 5px 0 0 0;
  }
  .rc-table-body {
    border: 1px solid #f1f1f1;
    border-right: 0;
    border-bottom: 0;
    border-top: 0;
  }
  .rc-table-fixed-column .rc-table-body::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    border-right: 1px solid #f1f1f1;
    content: '';
  }
  .rc-table-expanded-row .rc-table-cell {
    box-shadow: inset 0 8px 8px -8px #f1f1f1;
  }
  .rc-table-expanded-row-fixed {
    box-sizing: border-box;
    margin: -16px -8px;
    margin-right: -10px;
    padding: 16px 8px;
  }
  .rc-table-expanded-row-fixed::after {
    position: absolute;
    top: 0;
    right: 1px;
    bottom: 0;
    width: 0;
    border-right: 1px solid #f1f1f1;
    content: '';
  }
  .rc-table-row-expand-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    color: #aaa;
    line-height: 16px;
    text-align: center;
    vertical-align: middle;
    border: 1px solid currentColor;
    cursor: pointer;
  }
  .rc-table-row-expand-icon.rc-table-row-expanded::after {
    content: '-';
  }
  .rc-table-row-expand-icon.rc-table-row-collapsed::after {
    content: '+';
  }
  .rc-table-row-expand-icon.rc-table-row-spaced {
    visibility: hidden;
  }
  .rc-table-title {
    padding: 16px 8px;
    border: 1px solid #f1f1f1;
    border-bottom: 0;
  }
  .rc-table-footer {
    padding: 16px 8px;
    border: 1px solid #f1f1f1;
    border-top: 0;
  }
  .rc-table tfoot td {
    background: #fff;
  }
  .rc-table-summary {
    border-top: 1px solid gray;
    border-left: 1px solid gray;
  }
  .rc-table-sticky-holder {
    position: sticky;
    z-index: 2;
  }
  .rc-table-sticky-scroll {
    position: sticky;
    bottom: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    border-top: 1px solid #f3f3f3;
    opacity: 0.6;
    transition: transform 0.1s ease-in 0s;
  }
  .rc-table-sticky-scroll:hover {
    transform: scaleY(1.2);
    transform-origin: center bottom;
  }
  .rc-table-sticky-scroll-bar {
    height: 8px;
    background-color: #bbb;
    border-radius: 4px;
  }
  .rc-table-sticky-scroll-bar:hover {
    background-color: #999;
  }
  .rc-table-sticky-scroll-bar-active {
    background-color: #999;
  }

  /* paginador css */

  .rc-pagination {
    margin: 0;
    padding: 0;
    font-size: 16px;
  }
  .rc-pagination ul,
  .rc-pagination ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .rc-pagination::after {
    display: block;
    clear: both;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    content: ' ';
  }
  .rc-pagination-total-text {
    display: inline-block;
    height: 30px;
    margin-right: 8px;
    line-height: 30px;
    vertical-align: middle;
  }
  .rc-pagination-item {
    display: inline-block;
    min-width: 38px;
    height: 38px;
    margin-right: 8px;
    font-family: 'Avenir', sans-serif;
    line-height: 38px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    background-color: #fff;
    border: 1px solid #D4E5DA;
    border-radius: 0;
    outline: 0;
    cursor: pointer;
    user-select: none;
    color: #024E34;
  }
  .rc-pagination-item a {
    display: block;
    padding: 0 10px;
    color: #024E34;
    transition: none;
  }
  .rc-pagination-item a:hover {
    text-decoration: none;
    color: #fff;
  }
  .rc-pagination-item:focus,
  .rc-pagination-item:hover {
    border-color: #024E34;
    transition: all 0.3s;
  }
  .rc-pagination-item:focus a,
  .rc-pagination-item:hover a {
    color: #fff;
  }
  .rc-pagination-item-active {
    font-weight: 500;
    background: #024E34;
    border-color: #024E34;
    color: #fff;
  }
  .rc-pagination-item-active a {
    color: #fff;
  }
  .rc-pagination-item-active:focus,
  .rc-pagination-item-active:hover {
    border-color: #024E34;
  }
  .rc-pagination-item-active:focus a,
  .rc-pagination-item-active:hover a {
    color: #fff;
  }
  .rc-pagination-jump-prev,
  .rc-pagination-jump-next {
    outline: 0;
  }
  .rc-pagination-jump-prev button,
  .rc-pagination-jump-next button {
    background: transparent;
    border: none;
    cursor: pointer;
    color: #00BA1B;
  }
  .rc-pagination-jump-prev button:after,
  .rc-pagination-jump-next button:after {
    display: block;
    content: '•••';
  }
  .rc-pagination-prev,
  .rc-pagination-jump-prev,
  .rc-pagination-jump-next {
    margin-right: 8px;
  }
  .rc-pagination-prev,
  .rc-pagination-next,
  .rc-pagination-jump-prev,
  .rc-pagination-jump-next {
    display: inline-block;
    min-width: 30px;
    height: 30px;
    color: white;
    font-family: 'Avenir', sans-serif;
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    border-radius: 0;
    cursor: pointer;
    transition: all 0.3s;
  }
  .rc-pagination-prev,
  .rc-pagination-next {
    outline: 0;
  }
  .rc-pagination-prev button,
  .rc-pagination-next button {
    color: #00BA1B;
    cursor: pointer;
    user-select: none;
  }
  .rc-pagination-prev:hover button,
  .rc-pagination-next:hover button {
    border-color: #024E34;
  }
  .rc-pagination-prev .rc-pagination-item-link,
  .rc-pagination-next .rc-pagination-item-link {
    display: block;
    width: 100%;
    height: 100%;
    font-size: 16px;
    text-align: center;
    background-color: #fff;
    border: none;
    border-radius: 0;
    outline: none;
    transition: all 0.3s;
  }
  .rc-pagination-prev:focus .rc-pagination-item-link,
  .rc-pagination-next:focus .rc-pagination-item-link,
  .rc-pagination-prev:hover .rc-pagination-item-link,
  .rc-pagination-next:hover .rc-pagination-item-link {
    color: #024E34;
    border-color: #D4E5DA;
  }
  .rc-pagination-prev button:after {
    content: 'Anterior';
    display: block;
  }
  .rc-pagination-next button:after {
    content: 'Siguiente';
    display: block;
  }
  .rc-pagination-disabled,
  .rc-pagination-disabled:hover,
  .rc-pagination-disabled:focus {
    cursor: not-allowed;
  }
  .rc-pagination-disabled .rc-pagination-item-link,
  .rc-pagination-disabled:hover .rc-pagination-item-link,
  .rc-pagination-disabled:focus .rc-pagination-item-link {
    color: #024e34;
    border-color: #D4E5DA;
    cursor: not-allowed;
  }
  .rc-pagination-slash {
    margin: 0 10px 0 5px;
  }
  .rc-pagination-options {
    display: inline-block;
    margin-left: 16px;
    vertical-align: middle;
  }
  @media all and (-ms-high-contrast: none) {
    .rc-pagination-options *::-ms-backdrop,
    .rc-pagination-options {
      vertical-align: top;
    }
  }
  .rc-pagination-options-size-changer.rc-select {
    display: inline-block;
    width: auto;
    margin-right: 8px;
  }
  .rc-pagination-options-quick-jumper {
    display: inline-block;
    height: 28px;
    line-height: 28px;
    vertical-align: top;
  }
  .rc-pagination-options-quick-jumper input {
    width: 50px;
    margin: 0 8px;
  }
  .rc-pagination-simple .rc-pagination-prev,
  .rc-pagination-simple .rc-pagination-next {
    height: 24px;
    line-height: 24px;
    vertical-align: top;
  }
  .rc-pagination-simple .rc-pagination-prev .rc-pagination-item-link,
  .rc-pagination-simple .rc-pagination-next .rc-pagination-item-link {
    height: 24px;
    background-color: transparent;
    border: 0;
  }
  .rc-pagination-simple .rc-pagination-prev .rc-pagination-item-link::after,
  .rc-pagination-simple .rc-pagination-next .rc-pagination-item-link::after {
    height: 24px;
    line-height: 24px;
  }
  .rc-pagination-simple .rc-pagination-simple-pager {
    display: inline-block;
    height: 24px;
    margin-right: 8px;
  }
  .rc-pagination-simple .rc-pagination-simple-pager input {
    box-sizing: border-box;
    height: 100%;
    margin-right: 8px;
    padding: 0 6px;
    text-align: center;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    outline: none;
    transition: border-color 0.3s;
  }
  .rc-pagination-simple .rc-pagination-simple-pager input:hover {
    border-color: #1890ff;
  }
  .rc-pagination.rc-pagination-disabled {
    cursor: not-allowed;
  }
  .rc-pagination.rc-pagination-disabled .rc-pagination-item {
    background: #f5f5f5;
    border-color: #d9d9d9;
    cursor: not-allowed;
  }
  .rc-pagination.rc-pagination-disabled .rc-pagination-item a {
    color: rgba(0, 0, 0, 0.25);
    background: transparent;
    border: none;
    cursor: not-allowed;
  }
  .rc-pagination.rc-pagination-disabled .rc-pagination-item-active {
    background: #dbdbdb;
    border-color: transparent;
  }
  .rc-pagination.rc-pagination-disabled .rc-pagination-item-active a {
    color: #fff;
  }
  .rc-pagination.rc-pagination-disabled .rc-pagination-item-link {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    cursor: not-allowed;
  }
  .rc-pagination.rc-pagination-disabled .rc-pagination-item-link-icon {
    opacity: 0;
  }
  .rc-pagination.rc-pagination-disabled .rc-pagination-item-ellipsis {
    opacity: 1;
  }
  @media only screen and (max-width: 992px) {
    .rc-pagination-item-after-jump-prev,
    .rc-pagination-item-before-jump-next {
      display: none;
    }
  }
  @media only screen and (max-width: 576px) {
    .rc-pagination-options {
      display: none;
    }
  }

  // input::placeholder {
  //   color: white !important;
  //   font-size: 18px;
  //   border-bottom: 1px solid #fff;
  //   font-style: normal;
  // }

  .accordion-button::after {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNCAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyIDUuMDEyN1YxOS4wMTI3IiBzdHJva2U9IiMyNTI4MkIiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik01IDEyLjAxMjdIMTkiIHN0cm9rZT0iIzI1MjgyQiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
    background-repeat: no-repeat;
  }
  .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMyIgdmlld0JveD0iMCAwIDE2IDMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDEuMDEyN0gxNSIgc3Ryb2tlPSIjMjUyODJCIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K");
    transform: var(--bs-accordion-btn-icon-transform);
  }
  .accordion-button:not(.collapsed) {
    color: #002C1C;
    font-weight: bold;
    background-color: #D4E5DA;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
  }
  .accordion {
    border: none;
    outline: none;
  }
  .accordion-item {
    color: #136044;
    background-color: #fff;
    border: 1px solid #D4E5DA;
    outline: none;
  }
  `;
