/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useEffect } from "react";
import "react-accessible-accordion/dist/fancy-example.css";
import Pagination from "rc-pagination";
import LayoutMain from "../../layouts/layoutMain";
import { Text, Hr, Image } from "../../components/elementsDS/Content";
import {
  Iproduct,
  IanswerTypeProducts,
} from "../../commons/interfaces/products.interface";
import { fetchMethod } from "../../utils/fetch/fecth";
import { Card } from "../../components/card/card";
import { Link, useParams } from "react-router-dom";
import DataNotFound from "../../components/elementsDS/EmptyStates/DataNotFound";
import SearchData from "../../components/elementsDS/Loaders/SearchData";
import {
  CheckBox,
  Label,
  LabelCheck,
  LabelText,
} from "../../components/elementsDS/Forms";
import {
  IcategoryAnswer,
  IsubCategory,
  Icategory,
  IapiAnswerGeneral,
} from "../../commons/interfaces/category.interface";
import { Animate } from "../../components/elementsDS/animations/animations";
import { BRANDS, pathStringBrand } from "../../commons/const/brands";
import { toast } from "react-toastify";
import { IanswerProduct } from "../../commons/interfaces/products.interface";

const SearchProducts: React.FC = () => {
  const { productssearch, categoria, sucategoria } = useParams();
  const [products, setproducts] = useState<Iproduct[]>([]);
  const [subcategories, setSubcategories] = useState<string[]>(() => {
    if (sucategoria) {
      if (sucategoria !== "all") return [sucategoria];
    }
    return [];
  });
  const [total, setTotal] = useState<number>(0);
  const [current, setcurrent] = useState(1);
  const [skip, setSkip] = useState(0);
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<Icategory[]>([]);

  const getProducts = async () => {
    setLoading(false);
    setproducts([]);
    const data: IanswerProduct = await fetchMethod(
      `${process.env.REACT_APP_BACKEND_API}/products/searchProductsCategoryName?skip=${skip}&limit=32`,
      {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          subcategories: subcategories,
          search: productssearch === "all" ? "" : productssearch,
          brands: [],
          typeProducts: [],
        }),
      }
    );
    if (data.success) {
      setproducts(data.data);
      setReady(true);
    }
    setLoading(true);
  };
  const getCategories = async () => {
    const data: IcategoryAnswer = await fetchMethod(
      `${process.env.REACT_APP_BACKEND_API}/categories?limit=${10}&skip=${0}`,
      {}
    );
    setCategories(data.data);
  };
  useEffect(() => {
    getProducts();
  }, [skip, productssearch, subcategories]);
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_API}/products/searchProductsCategoryNameCounter`,
      {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          subcategories: subcategories,
          search: productssearch === "all" ? "" : productssearch,
        }),
      }
    )
      .then((data) => data.json())
      .then((data) => {
        if (data.success) {
          setTotal(data.data);
          setReady(true);
        }
      })
      .catch((err) => toast.error(err.message));
  }, [subcategories, productssearch]);

  useEffect(() => {
    getCategories();
  }, []);
  const productsCatalog = () => {
    if (!loading) return <SearchData text="Buscando datos..." sizeIcon="100" />;
    if (products.length === 0)
      return <DataNotFound text="NO HAY COINCIDENCIAS" sizeIcon="100" />;
    if (products.length !== 0)
      return products.map((product) => (
        <div className="col-12 col-lg-4" key={product._id}>
          <Animate.ScaleIn key={product._id}>
            <Card>
              <Image
                src={product.urlImage}
                onError={(e: any) => {
                  e.target.src = "/img/noproduct.png";
                }}
                alt="image alt coinsa"
                className="m-auto d-block zoom"
              />
              <div className="card-footer">
                <Hr />
                <Text
                  type="Paragraph"
                  content={product.nombreProducto}
                  bold
                  color="#002C1C"
                />
              </div>
            </Card>
          </Animate.ScaleIn>
        </div>
      ));
  };
  return (
    <LayoutMain>
      <div className="container pb-5">
        {categoria === pathStringBrand ? (
          <Image
            src={`/img/brands/banners/${sucategoria}.jpg`}
            width="100%"
            loading="lazy"
            alt="bannersubcategoria coinsa"
          />
        ) : (
          <center className="pt-5">
            <Text type="H5" content="Productos" bold color="#83B0A0" />
          </center>
        )}
        <Hr />
        <div className="row">
          <div className="col-12 col-lg-3">
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              {categories.map((category) => (
                <div className="accordion-item" key={category._id}>
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#flush-collapseOne-${category._id}`}
                      aria-expanded="false"
                      aria-controls={`flush-collapseOne-${category._id}`}
                    >
                      {category.nombre}
                    </button>
                  </h2>
                  <div
                    id={`flush-collapseOne-${category._id}`}
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      {category.subCategories.map((subcategory) => (
                        <div
                          className="d-flex justify-content-between align-items-center"
                          key={subcategory._id}
                        >
                          <LabelCheck>
                            <CheckBox
                              id={subcategory._id}
                              checked={
                                subcategories.find(
                                  (_subCategories) =>
                                    _subCategories === subcategory.nombre
                                )
                                  ? true
                                  : false
                              }
                              type="checkbox"
                              onClick={() => {
                                setSkip(0);
                                setcurrent(1);
                                if (
                                  subcategories.find(
                                    (el) => el === subcategory.nombre
                                  )
                                ) {
                                  setSubcategories(
                                    subcategories.filter(
                                      (el) => el !== subcategory.nombre
                                    )
                                  );
                                } else {
                                  setSubcategories((exCategories) => [
                                    ...exCategories,
                                    subcategory.nombre,
                                  ]);
                                }
                              }}
                            />
                            <LabelText>{subcategory.nombre}</LabelText>
                          </LabelCheck>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-12 col-lg-9">
            <div className="row align-items-center">{productsCatalog()}</div>
            <div className="mt-4 d-flex justify-content-end">
              <Pagination
                defaultPageSize={32}
                total={total}
                current={current}
                showTitle={false}
                hideOnSinglePage
                onChange={(actualPostion) => {
                  setSkip(actualPostion * 32 - 32);
                  setcurrent(actualPostion);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </LayoutMain>
  );
};

export default SearchProducts;
