import React from "react";
import { Link } from "react-router-dom";
import {
  TbBrandFacebook,
  TbBrandInstagram,
  TbBrandLinkedin,
  TbBrandTwitter,
} from "react-icons/tb";

import { Text, Hr } from "../elementsDS/Content";

interface Props {
  fixed?: boolean;
}

const Footer: React.FC<Props> = ({ fixed }) => {
  const year = new Date().getFullYear();
  const copyright = `Copyright © ${year} Coinsa | Todos los derechos reservados.`;
  return (
    <>
      <footer className={fixed ? "bg-white fixed-bottom" : "bg-white"}>
        {" "}
        {/* <div className="col-12 col-lg-12 text-center">
          <Hr />
          <Text
            type="H6"
            content="Contáctenos a nuestras redes sociales"
            color="#086141"
          />
          <div className="d-flex justify-content-center align-items-center">
            <TbBrandFacebook color="#086141" className="me-4" />
            <TbBrandInstagram color="#086141" className="me-4" />
            <TbBrandLinkedin color="#086141" className="me-4" />
            <TbBrandTwitter color="#086141" />
          </div>
          <Hr />
        </div> */}
        <div className="d-flex justify-content-center align-items-center">
          <Link
            className="text-decoration-none me-2"
            to="/terminos-condiciones"
          >
            <Text
              type="Paragraph"
              content="Politicas de privacidad"
              color="#000000"
            />
          </Link>
          <Link className="text-decoration-none" to="/terminos-condiciones">
            <Text type="Paragraph" content="Términos de uso" color="#000000" />
          </Link>
        </div>
        <div className="text-center mt-2 mb-2">
          <Text type="Paragraph" content={copyright} color="000000" />
        </div>
      </footer>
    </>
  );
};

export default Footer;
