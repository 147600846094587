/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import LayoutMain from "../../layouts/layoutMain";
import {
  Text,
  BoxText,
  BlockText,
  Image,
} from "../../components/elementsDS/Content";
import { Animate } from "../../components/elementsDS/animations/animations";
import { useUser } from "../../context/userContext";

const Parrafo = styled.p`
  text-align: justify;
  text-justify: inter-word;
  color: #002c1c;
`;

const experiencePage: React.FC = () => {
  return (
    <LayoutMain>
      <div className="container-fluider">
        <Animate.FadeUp>
          <img
            src="/img/360/Banners-experiencia-Virtual.jpg"
            alt="image categorias"
            className="img-fluid mb-5"
            loading="lazy"
          />
        </Animate.FadeUp>
        <Text
          type="H4"
          bold
          content="EXPERIENCIA 360°"
          color="#086141"
          align="center"
        />
        <div className="container">
          <Parrafo className="mt-3 mb-3">
            Laboratorios Coinsa revoluciona la forma de realizar las visitas
            medicas de la región. Les PRESENTAMOS{" "}
            <b>LA NUEVA VISITA MÉDICA VIRTUAL</b>
            &nbsp;Una experiencia de realidad virtual, completamente inmersiva,
            que le permitirá conocer e interactuar en 3 dimensiones con toda la
            gama de productos de laboratorios Coinsa Usted podrá conocer los
            beneficios de cada producto, sus principios activos y formas de
            aplicación en pacientes.
            <br /> <br />
            <b>
              Laboratorios COINSA, líderes en innovación, lo invita a ser parte
              de esta increible experiencia.
            </b>
          </Parrafo>
          <br />
          <video controls className="object-fit-contain m-auto d-block" autoplay='autoplay'
            width="100%" loop >
              <source
              src="/img/360/Video-Coinsa-corto.webm"
              type="video/mp4"
            />
            <source
              src="/img/360/Video-Coinsa-corto.mp4"
              type="video/mp4"
            />
            Tu navegador no admite el elemento de video.
          </video>
          <Animate.FadeUp>
            <img
              src="/img/360/asesor360.png"
              alt="image expierence"
              className="img-fluid m-auto d-block"
              loading="lazy"
            />
          </Animate.FadeUp>
        </div>
      </div>
    </LayoutMain>
  );
};

export default experiencePage;
