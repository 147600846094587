/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import LayoutMain from "../../layouts/layoutMain";
import { Text } from "../../components/elementsDS/Content";
import { useUser } from "../../context/userContext";
import { Animate } from "../../components/elementsDS/animations/animations";

const Certificaciones: React.FC = () => {
  const contextData = useUser();
  const [content, setcontent] = useState("");

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_API}/articles/notArticles/CERTIFICACIONES`
    )
      .then((data) => data.json())
      .then((data) => {
        setcontent(data.data[0].body);
      })
      .catch((e) => toast.error(e.message));
  }, []);
  return (
    <LayoutMain>
      {content ? (
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      ) : (
        <div className="container-fluider">
          <Animate.FadeUp>
            <img
              src="/img/banner-certificados.png"
              alt="image categorias"
              className="img-fluid m-auto d-block"
              loading="lazy"
              width="100%"
            />{" "}
            <br />
            <br />
            <Text
              type="H5"
              content="Certificaciones"
              color="#086141"
              align="center"
            />
          </Animate.FadeUp>
          <div className="p-5 text-center">
            <Animate.FadeUp>
              <p style={{ color: '#002C1C' }}>
                Sobre la premisa de mejorar nuestro desempeño global y
                proporcionar una base sólida para las iniciativas de desarrollo
                sostenible, en el año 2008, Coinsa implementó un sistema de
                gestión de Calidad en la documentación de procesos, optimización
                de actividades, mantenimiento de efectividad en las operaciones
                y creación de un entorno de trabajo de mejora continua. A través
                de la norma ISO 9001 COINSA consigue mantener consistentemente
                los altos niveles de calidad requeridos por el mercado y
                solicitados por nuestros socios comerciales.
                <br />
                Nuestro certificado en la Norma Internacional ISO 9001:2000
                acreditado por el ICONTEC, nos valida en estándares de calidad
                sobre las actividades de diseño y producción de productos
                cosméticos y de limpieza. En función de la expansión hacia
                nuevos países y canales junto con el rápido crecimiento de la
                empresa, se definió incorporar en la norma ISO 9001:2015 la
                certificación de calidad en el Diseño y Producción de
                medicamentos, manteniendo las categorías de productos cosméticos
                y de limpieza. Este sistema de certificación de calidad y mejora
                continua, nos permite cumplir con las normativas y disposiciones
                legales garantizando nuestros procesos, alienados con exigencias
                y requisitos de nuestros clientes.
              </p>
            </Animate.FadeUp>
          </div>
          <Animate.ScaleIn>
            <img
              src="/img/banner-iso.jpg"
              alt="image categorias"
              className="img-fluid m-auto d-block mt-5"
              loading="lazy"
              width="100%"
            />
          </Animate.ScaleIn>
          <div className="container mt-5 mb-5">
            <div className="row align-items-center justify-content-center g-3">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <Animate.ScaleIn>
                  <img
                    src="/img/icontec-certificado.png"
                    alt="image categorias"
                    className="img-fluid mb-3"
                    loading="lazy"
                  />
                </Animate.ScaleIn>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <Animate.ScaleIn>
                  <img
                    src="/img/network-certificado.png"
                    alt="image categorias"
                    className="img-fluid mb-3"
                    loading="lazy"
                  />
                </Animate.ScaleIn>
              </div>
            </div>
          </div>
        </div>
      )}
    </LayoutMain>
  );
};

export default Certificaciones;
