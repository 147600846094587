/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";
import { FcTemplate } from "react-icons/fc";
import LayoutAdmin from "../../../layouts/layoutAdmin";
import {
  Input,
  InputFile,
  Label,
  LabelFile,
  Textarea,
} from "../../../components/elementsDS/Forms";
import LoadButton from "../../../components/elementsDS/Loaders/LoadButton";
import { Button } from "../../../components/elementsDS/buttons/Buttons";
import { Hr, Text } from "../../../components/elementsDS/Content";
import { BsPencil } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Card } from "../../../components/card/card";

interface Props {
  loading?: boolean;
}

const ListPage: React.FC<Props> = ({ loading }) => {
  return (
    <LayoutAdmin title="Listado de páginas" icon={<FcTemplate size={34} />}>
      <div className="container-fluider">
        <Card>
          <div className="d-flex justify-content-between flex-wrap">
            <div>
              <Label>Id</Label>
              <br />
              <Text type="Paragraph" color="#000" content="#1" />
            </div>
            <div>
              <Label>Título de página</Label>
              <br />
              <Text type="Paragraph" color="#000" content="Quienes Somos" />
            </div>
            <div>
              <Label>Acciones</Label>
              <br />
              <Link
                to="/dashboard/editar/pagina/QUIENESSOMOS"
                className="text-decoration-none"
              >
                <Button type="button" info>
                  <BsPencil />
                </Button>
              </Link>
            </div>
          </div>
        </Card>{" "}
        <Hr />
        <Card>
          <div className="d-flex justify-content-between flex-wrap">
            <div>
              <Label>Id</Label>
              <br />
              <Text type="Paragraph" color="#000" content="#1" />
            </div>
            <div>
              <Label>Título de página</Label>
              <br />
              <Text type="Paragraph" color="#000" content="certidicaciones" />
            </div>
            <div>
              <Label>Acciones</Label>
              <br />
              <Link
                to="/dashboard/editar/pagina/CERTIFICACIONES"
                className="text-decoration-none"
              >
                <Button type="button" info>
                  <BsPencil />
                </Button>
              </Link>
            </div>
          </div>
        </Card>{" "}
        <Hr />
        <Card>
          <div className="d-flex justify-content-between flex-wrap">
            <div>
              <Label>Id</Label>
              <br />
              <Text type="Paragraph" color="#000" content="#1" />
            </div>
            <div>
              <Label>Título de página</Label>
              <br />
              <Text
                type="Paragraph"
                color="#000"
                content="Nuestros servicios"
              />
            </div>
            <div>
              <Label>Acciones</Label>
              <br />
              <Link
                to="/dashboard/editar/pagina/NUESTROSERVICIOS"
                className="text-decoration-none"
              >
                <Button type="button" info>
                  <BsPencil />
                </Button>
              </Link>
            </div>
          </div>
        </Card>{" "}
        <Hr />
        <Card>
          <div className="d-flex justify-content-between flex-wrap">
            <div>
              <Label>Id</Label>
              <br />
              <Text type="Paragraph" color="#000" content="#4" />
            </div>
            <div>
              <Label>Título de página</Label>
              <br />
              <Text
                type="Paragraph"
                color="#000"
                content="QUE NOS HACE DIFERENTES"
              />
            </div>
            <div>
              <Label>Acciones</Label>
              <br />
              <Link
                to="/dashboard/editar/pagina/QUENOSHACEDIFERENTES"
                className="text-decoration-none"
              >
                <Button type="button" info>
                  <BsPencil />
                </Button>
              </Link>
            </div>
          </div>
        </Card>
        <Hr />
      </div>
    </LayoutAdmin>
  );
};

export default ListPage;
