/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";
import LayoutMain from "../../layouts/layoutMain";
import { Text } from "../../components/elementsDS/Content";
import { Animate } from "../../components/elementsDS/animations/animations";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Background = styled.div`
  background: #002c1c33;
  padding: 20px;
  margin-top: 30px;
`;

const Brands: React.FC = () => {
  return (
    <LayoutMain>
      <div className="container mb-5 mt-5">
        <Animate.FadeUp>
          <Text
            type="H6"
            content="Nuestras Marcas"
            bold
            color="#086141"
            align="center"
          />
        </Animate.FadeUp>
        <Animate.FadeUp>
          <img
            src="/img/360/Banners-distribución-1.jpg"
            alt="image categorias"
            className="img-fluid"
            loading="lazy"
          />
        </Animate.FadeUp>
        {/* primera fila de marcas */}
        <Animate.FadeUp>
          <Background>
            <Text type="H6" content="MEDICAMENTOS" bold color="#002C1C" />
            <div className="row align-items-center justify-content-center g-3">
              <div className="col">
                <Link to="/productos/marca/BETAFARM">
                  <img
                    src="/img/brands/BETAFARM 2.png"
                    alt="image categorias"
                    className="img-fluid"
                    loading="lazy"
                  />
                </Link>
              </div>
              <div className="col">
                <Link to="/productos/marca/BRONCOMED">
                  <img
                    src="/img/brands/BRONCOMED 2.png"
                    alt="image categorias"
                    className="img-fluid"
                    loading="lazy"
                  />
                </Link>
              </div>
              <div className="col">
                <Link to="/productos/marca/FUNGIPLUS">
                  <img
                    src="/img/brands/FUNGIPLUS 1.png"
                    alt="image categorias"
                    className="img-fluid"
                    loading="lazy"
                  />
                </Link>
              </div>
              <div className="col">
                <Link to="/productos/marca/MEGALAB">
                  <img
                    src="/img/brands/MEGALAB 1.png"
                    alt="image categorias"
                    className="img-fluid"
                    loading="lazy"
                  />
                </Link>
              </div>
              <div className="col">
                <Link to="/productos/marca/NODOR ICE">
                  <img
                    src="/img/brands/NodorIce.png"
                    alt="image categorias"
                    className="img-fluid"
                    loading="lazy"
                  />
                </Link>
              </div>
              <div className="col">
                <Link to="/productos/marca/STERISCRUB">
                  <img
                    src="/img/brands/STERISCRUB 1.png"
                    alt="image categorias"
                    className="img-fluid"
                    loading="lazy"
                  />
                </Link>
              </div>
              <div className="col">
                <Link to="/productos/marca/PULMOVAPOR">
                  <img
                    src="/img/brands/NODOR-PULMOVAPOR 1.png"
                    alt="image categorias"
                    className="img-fluid"
                    loading="lazy"
                  />
                </Link>
              </div>
              <div className="col">
                <Link to="/productos/marca/FUCIFARM">
                  <img
                    src="/img/brands/FUCIFARM.png"
                    alt="image categorias"
                    className="img-fluid"
                    loading="lazy"
                  />
                </Link>
              </div>
            </div>
          </Background>
        </Animate.FadeUp>
        {/* segunda fila de marcas */}
        <Animate.FadeUp>
          <Background>
            <Text type="H6" content="COSMÉTICO" bold color="#002C1C" />
            <div className="row align-items-center justify-content-center g-3">
              <div className="col">
                <Link to="/productos/marca/BUG OFF">
                  <img
                    src="/img/brands/BUG-OFF 2.png"
                    alt="image categorias"
                    className="img-fluid"
                    loading="lazy"
                  />
                </Link>
              </div>
              <div className="col">
                <Link to="/productos/marca/CHARISMA">
                  <img
                    src="/img/brands/CHARISMA 2.png"
                    alt="image categorias"
                    className="img-fluid"
                    loading="lazy"
                  />
                </Link>
              </div>
              <div className="col">
                <Link to="/productos/marca/DERMA NATURAL">
                  <img
                    src="/img/brands/DERMANATURAL 2.png"
                    alt="image categorias"
                    className="img-fluid"
                    loading="lazy"
                  />
                </Link>
              </div>
              <div className="col">
                <Link to="/productos/marca/HOMBRE">
                  <img
                    src="/img/brands/HOMBRE 1.png"
                    alt="image categorias"
                    className="img-fluid"
                    loading="lazy"
                  />
                </Link>
              </div>
              <div className="col">
                <Link to="/productos/marca/MADONNA">
                  <img
                    src="/img/brands/MADONNA 1.png"
                    alt="image categorias"
                    className="img-fluid"
                    loading="lazy"
                  />
                </Link>
              </div>
              <div className="col">
                <Link to="/productos/marca/ROY VIGAN">
                  <img
                    src="/img/brands/ROY-VIGAN(2)-VARIACION-AZUL 1.png"
                    alt="image categorias"
                    className="img-fluid"
                    loading="lazy"
                  />
                </Link>
              </div>
              <div className="col">
                <Link to="/productos/marca/NODOR">
                  <img
                    src="/img/brands/NODOR(1)-VARIACION-AZUL 1.png"
                    alt="image categorias"
                    className="img-fluid"
                    loading="lazy"
                  />
                </Link>
              </div>
              <div className="col">
                <Link to="/productos/marca/VITACREME">
                  <img
                    src="/img/brands/VITACREME 1.png"
                    alt="image categorias"
                    className="img-fluid"
                    loading="lazy"
                  />
                </Link>
              </div>
            </div>
          </Background>
        </Animate.FadeUp>
        {/* tercera fila de marcas */}
        <Animate.FadeUp>
          <Background>
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <Text type="H6" content="BIO SEGURIDAD" bold color="#002C1C" />
                <div className="row g-3">
                  <div className="col-4 col-sm-4 col-md-4 col-lg-6">
                    <Link to="/productos/marca/HAND CLEANER">
                      <img
                        src="/img/brands/HAND-CLEANER-VARIACION-AZUL-CLARO 1.png"
                        alt="image categorias"
                        className="img-fluid"
                        loading="lazy"
                      />
                    </Link>
                  </div>
                  <div className="col-4 col-sm-4 col-md-4 col-lg-6">
                    <Link to="/productos/marca/FARINTER">
                      <img
                        src="/img/brands/farinter-6.png"
                        alt="image categorias"
                        className="img-fluid"
                        loading="lazy"
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="row g-3">
                <div className="col-4 col-sm-4 col-md-4 col-lg-12">
                  <Text
                    type="H6"
                    content="CUIDADO DEL HOGAR"
                    bold
                    color="#002C1C"
                  />
                  <Link to="/productos/marca/HYDE PARK">
                    <img
                      src="/img/brands/HYDE-PARK(3) 1.png"
                      alt="image categorias"
                      className="img-fluid"
                      loading="lazy"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </Background>
        </Animate.FadeUp>
      </div>
    </LayoutMain>
  );
};

export default Brands;
