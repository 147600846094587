/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useRef, useMemo, useEffect } from "react";
import { FcNews } from "react-icons/fc";
import LayoutAdmin from "../../../layouts/layoutAdmin";
import {
  Input,
  InputFile,
  Label,
  LabelFile,
  Textarea,
} from "../../../components/elementsDS/Forms";
import LoadButton from "../../../components/elementsDS/Loaders/LoadButton";
import { Button } from "../../../components/elementsDS/buttons/Buttons";
import { fetchMethod } from "../../../utils/fetch/fecth";
import { useUser } from "../../../context/userContext";
import { IapiAnswerGeneral } from "../../../commons/interfaces/general.interface";

import { Iarticles } from "../../../commons/interfaces/article.interface";
import { toast } from "react-toastify";

import JoditEditor from "jodit-react";

import { useParams } from "react-router-dom";

import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";

import { RadioButton } from "../../../components/elementsDS/Forms";

const AddArticle: React.FC = () => {
  const contextData = useUser();
  const editor = useRef(null);

  const params = useParams();
  const { articleId } = params;
  const [loading, setloading] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [content, setContent] = useState("");

  const [image, setImage] = useState<any>();
  const [urlImage, seturlImage] = useState<any>();

  const [isEditing, setIsEditing] = useState(false);
  const [imageArticle, setimageArticle] = useState("");
  const [article, setArticle] = useState<Iarticles>({
    _id: "",
    titulo: "",
    descripcion: "",
    imagen: "",
    body: "",
    type: "ARTICLES",
  });

  const [typeEditor, setTypeEditor] = useState(1);

  function onChange(newValue: string) {
    setContent(newValue);
  }
  const updateImageArticle = async () => {
    setloading(true);
    try {
      let message = "";
      if (!image) message += "agregue una imagen de articulo. \n";
      const formData = new FormData();
      formData.append("file", image);
      const answerUploadImage = await fetchMethod(
        `${process.env.REACT_APP_BACKEND_API}/products/uploadImage`,
        {
          method: "POST",
          body: formData,
          mode: "cors",
        }
      );
      if (answerUploadImage.statusCode === 400) {
        setloading(false);
        toast.error("la imagen debe ser png");
        return;
      }
      const answer: IapiAnswerGeneral = await fetchMethod(
        `${process.env.REACT_APP_BACKEND_API}/articles/${article._id}`,
        {
          method: "PUT",
          headers: new Headers({
            Accept: "application/json",
            Authorization: `Bearer ${contextData.user?.access_token}`,
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({
            imagen: answerUploadImage.data.imageUri,
          }),
          mode: "cors",
        }
      );
      if (answer.success) {
        toast.success(answer.message);
        getArticles();
      } else toast.error(answer.message);
    } catch (error: any) {
      toast.error(error.message);
    }
    setloading(false);
  };
  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/docs/,
  };

  const getArticles = async () => {
    setTitle("");
    setDescription("");
    setContent("");
    const data = await fetchMethod(
      `${process.env.REACT_APP_BACKEND_API}/articles/findOne/${articleId}`,
      {}
    );
    const article = data.data;
    setArticle(data.data);
    setTitle(article.titulo);
    setDescription(article.descripcion);
    setContent(article.body);
    setimageArticle(article.imagen);
  };

  useEffect(() => {
    if (articleId !== "false") {
      setIsEditing(true);
      getArticles();
    }
  }, []);

  const onChangeImage = ({ target, currentTarget }: React.ChangeEvent<any>) => {
    const file = currentTarget.files[0];
    setImage(file);
    const url = URL.createObjectURL(file);
    seturlImage(url);
  };

  const saveArticle = async () => {
    setloading(true);
    try {
      let message = "";
      if (!image) message += "agregue una imagen de articulo. \n";
      if (title === "") message += "agregue el titulo del articulo. \n";
      if (description === "")
        message += "agregue la descripcion del articulo. \n";
      if (content === "") message += "agregue un contenido. \n";
      if (message) {
        setloading(false);
        return toast.info(message);
      }
      const formData = new FormData();
      formData.append("file", image);
      const answerUploadImage = await fetchMethod(
        `${process.env.REACT_APP_BACKEND_API}/products/uploadImage`,
        {
          method: "POST",
          body: formData,
          mode: "cors",
        }
      );

      const answer: IapiAnswerGeneral = await fetchMethod(
        `${process.env.REACT_APP_BACKEND_API}/articles`,
        {
          method: "POST",
          headers: new Headers({
            Accept: "application/json",
            Authorization: `Bearer ${contextData.user?.access_token}`,
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({
            titulo: title,
            descripcion: description,
            imagen: answerUploadImage.data.imageUri,
            body: content,
            type: "ARTICLES",
          }),
        }
      );
      if (answer.success) {
        toast.success(answer.message);
      } else toast.error(answer.message);
    } catch (error: any) {
      toast.error(error.message);
    }
    setloading(false);
  };

  const updateArticle = async () => {
    setloading(true);
    let message = "";

    if (title === "") message += "agregue el titulo del articulo. \n";
    if (description === "")
      message += "agregue la descripcion del articulo. \n";
    if (content === "") message += "agregue un contenido. \n";
    if (message) {
      setloading(false);
      return toast.info(message);
    }
    const answer: IapiAnswerGeneral = await fetchMethod(
      `${process.env.REACT_APP_BACKEND_API}/articles/${article._id}`,
      {
        method: "PUT",
        headers: new Headers({
          Accept: "application/json",
          Authorization: `Bearer ${contextData.user?.access_token}`,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          titulo: title,
          descripcion: description,
          imagen: article.imagen,
          body: content,
          type: "ARTICLES",
        }),
        mode: "cors",
      }
    );
    setloading(false);
    if (answer.success) {
      toast.success(answer.message);
    } else toast.error(answer.message);
  };

  return (
    <LayoutAdmin
      title={isEditing ? `Editar articulo` : `Agregar nuevo articulo`}
      icon={<FcNews size={34} />}
    >
      <div className="container">
        <div className="row justify-content-center mt-5 align-items-center g-3">
          <div className="col-12 col-lg-7">
            {isEditing && (
              <div className="d-flex justify-content-center m-3 img-fluid">
                <img src={imageArticle} alt="imagen" height={250} width={250} />
              </div>
            )}
            <Label>Título de artículo</Label>
            <div className="input-group flex-nowrap">
              <Input
                type="text"
                placeholder="Título de artículo"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-lg"
                value={title}
                onChange={({ target }) => setTitle(target.value)}
              />
            </div>
          </div>
          <div className="col-12 col-lg-7">
            <Label className="mt-4">Descripción de artículo</Label>
            <Textarea
              placeholder="escriba descripción de articulo"
              value={description}
              onChange={({ target }) => setDescription(target.value)}
            />
          </div>
          <div className="col-12 col-lg-7">
            <Label className="me-3">Cargar banner de portada de articulo</Label>
            <LabelFile htmlFor="newImage" disabled={loading}>
              {!loading ? (
                <>
                  <i className="fa fa-upload me-2" /> Cargar nueva Imágen
                </>
              ) : (
                <LoadButton message="cargando imágen..." />
              )}
              <InputFile
                type="file"
                name="newImage"
                id="newImage"
                onChange={onChangeImage}
              />
            </LabelFile>
            {isEditing && (
              <Button
                primary={true}
                type="button"
                className="mt-3"
                block
                onClick={updateImageArticle}
                disabled={loading}
              >
                {loading ? "Procesando..." : "actualizar imagen"}
              </Button>
            )}

            <div className="d-flex justify-content-center m-3 img-fluid">
              <img src={urlImage} alt="imagen" height={250} width={300} />
            </div>
            <p className="text-center">Imagen de caratula</p>
          </div>

          <div className="col-12 col-lg-7">
            <Label className="mt-4">Agregar contenido</Label>
            <br />
            <div className="row justify-content-center align-items-center g-3">
              <div className="col-4">
                <Label className="mt-1">
                  Editor
                  <input
                    type="radio"
                    name="editor"
                    id=""
                    onChange={() => setTypeEditor(1)}
                    value={typeEditor}
                    className="mr-2"
                  />
                </Label>
              </div>
              <div className="col-4">
                <Label className="mt-1">
                  HTML{" "}
                  <input
                    type="radio"
                    name="editor"
                    id=""
                    onChange={() => setTypeEditor(2)}
                    value={typeEditor}
                  />
                </Label>
              </div>
            </div>
            <br />
            {typeEditor === 1 ? (
              <>
                <Label>Editor Basico</Label>
                <JoditEditor
                  ref={editor}
                  value={content}
                  config={config}
                  onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                  onChange={(newContent) => {
                    return;
                  }}
                />
              </>
            ) : (
              <>
                <Label>Editor HTML</Label>
                <AceEditor
                  value={content}
                  width="100%"
                  mode="java"
                  theme="github"
                  onChange={onChange}
                  name="UNIQUE_ID_OF_DIV"
                  editorProps={{ $blockScrolling: true }}
                />
              </>
            )}
          </div>
          <div className="col-12 col-lg-7">
            <Button
              primary={true}
              type="button"
              className="mt-3"
              block
              onClick={isEditing ? updateArticle : saveArticle}
              disabled={loading}
            >
              {loading ? "Procesando..." : "Guardar"}
            </Button>
          </div>
        </div>
      </div>
    </LayoutAdmin>
  );
};

export default AddArticle;
