import React, {useId} from "react";
import { Link } from "react-router-dom";
import { DataSidebar } from "./DataSidebar";
import { SidebarMenuMovil, List, ListItem } from '../elementsDS/Sidebar';

function SidebarMovil() {
  return (
    <SidebarMenuMovil>
      <List mobile>
        {DataSidebar.map(({ title, icon, path, index }) => (
          <Link to={path} className="text-decoration-none" key={useId(index)}>
            <ListItem>
              {icon}
              {title}
            </ListItem>
          </Link>
        ))}
      </List>
    </SidebarMenuMovil>
  );
}

export default SidebarMovil;
