/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useRef, useState } from "react";
import JoditEditor from "jodit-react";
import { FcTemplate } from "react-icons/fc";
import LayoutAdmin from "../../../layouts/layoutAdmin";
import {
  Input,
  InputFile,
  Label,
  LabelFile,
  Textarea,
} from "../../../components/elementsDS/Forms";
import LoadButton from "../../../components/elementsDS/Loaders/LoadButton";
import { Button } from "../../../components/elementsDS/buttons/Buttons";
import { Text } from "../../../components/elementsDS/Content";
import { useParams } from "react-router-dom";
import { Iarticles } from "../../../commons/interfaces/article.interface";
import { toast } from "react-toastify";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import { CheckBox } from "../../../components/elementsDS/Forms";
import { RadioButton } from "../../../components/elementsDS/Forms";
import { fetchMethod } from "../../../utils/fetch/fecth";
import { useUser } from "../../../context/userContext";

const EditPage: React.FC = () => {
  const contextData = useUser();
  const { type } = useParams();
  const editor = useRef(null);
  const [loading, setloading] = useState(false);
  const [typeEditor, settypeEditor] = useState(1);
  const [content, setContent] = useState("");
  const [data, setdata] = useState<Iarticles>({
    _id: "",
    body: "",
    descripcion: "",
    imagen: "",
    titulo: "",
    type: "",
  });

  function onChange(newValue: string) {
    setContent(newValue);
  }
  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/docs/,
  };
  const updateArticle = async () => {
    setloading(true);
    try {
      const answer = await fetchMethod(
        `${process.env.REACT_APP_BACKEND_API}/articles/${data._id}`,
        {
          headers: new Headers({
            Accept: "application/json",
            Authorization: `Bearer ${contextData.user?.access_token}`,
            "Content-Type": "application/json",
          }),
          method: "PUT",
          body: JSON.stringify({
            body: content,
          }),
        }
      );
      toast.info(answer.message);
      setloading(false);
    } catch (error: any) {
      toast.error(error.message);
    }
  };
  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_API}/articles/notArticles/${type}`)
      .then((data) => data.json())
      .then((data) => {
        setContent(data.data[0].body);
        setdata(data.data[0]);
      })
      .catch((e) => toast.error(e.message));
  }, []);

  return (
    <LayoutAdmin title="Editar Página" icon={<FcTemplate size={34} />}>
      <div className="container">
        <div className="row justify-content-center mt-5 align-items-center g-3">
          <div className="col-12 col-lg-7">
            <Label>{data.titulo}</Label>
          </div>
          <div className="col-12 col-lg-7">
            <Label className="mt-4">Agregar contenido</Label>
            <br />
            <Label className="mt-4">
              HTML{" "}
              <input
                type="radio"
                name="editor"
                id=""
                onChange={() => settypeEditor(1)}
              />
            </Label>
            <Label className="mt-4 mx-2">
              editor
              <input
                type="radio"
                name="editor"
                className="mx-2"
                onChange={() => settypeEditor(2)}
              />
            </Label>
            <br />
          </div>
          <div className="col-12 col-lg-7">
            <Button
              primary={true}
              type="button"
              className="mt-3"
              block
              onClick={updateArticle}
              disabled={loading}
            >
              Guardar
            </Button>
          </div>
        </div>
        <div className="row my-5">
          <div className="col">
            {typeEditor === 1 ? (
              <>
                <Label>Editor HTML</Label>
                <AceEditor
                  value={content}
                  width="100%"
                  mode="java"
                  theme="github"
                  onChange={onChange}
                  name="UNIQUE_ID_OF_DIV"
                  editorProps={{ $blockScrolling: true }}
                />
              </>
            ) : (
              <>
                <Label>Editor Basico</Label>
                <JoditEditor
                  ref={editor}
                  value={content}
                  config={config}
                  onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                  onChange={(newContent) => {
                    return;
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </LayoutAdmin>
  );
};

export default EditPage;
