/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from "react";
import { BsEye, BsPencil, BsPlusLg, BsTrash, BsTrash2 } from "react-icons/bs";
import { Link } from "react-router-dom";
import { FcTemplate } from "react-icons/fc";
import LayoutAdmin from "../../../layouts/layoutAdmin";
import {
  Input,
  InputFile,
  Label,
  LabelFile,
  Textarea,
} from "../../../components/elementsDS/Forms";
import LoadButton from "../../../components/elementsDS/Loaders/LoadButton";
import { Button } from "../../../components/elementsDS/buttons/Buttons";
import { Hr, Image, Text } from "../../../components/elementsDS/Content";
import { IarticlesPresentation } from "../../../commons/interfaces/article.interface";
import { toast } from "react-toastify";
import { fetchMethod } from "../../../utils/fetch/fecth";
import { IapiAnswerGeneral } from "../../../commons/interfaces/category.interface";
import { useUser } from "../../../context/userContext";
import { Card } from "../../../components/card/card";

interface Props {
  loading?: boolean;
}

const ListPresentation: React.FC<Props> = ({ loading }) => {
  const contextData = useUser();
  const [article, setarticle] = useState<IarticlesPresentation[]>([]);
  const getData = () => {
    fetch(`${process.env.REACT_APP_BACKEND_API}/articles/articleBanner`)
      .then((data) => data.json())
      .then((data) => setarticle(data.data))
      .catch((e) => toast.error(e.message));
  };
  const deleteItem = async (mongoId: string) => {
    try {
      const answer: IapiAnswerGeneral = await fetchMethod(
        `${process.env.REACT_APP_BACKEND_API}/articles/articleBanner/${mongoId}`,
        {
          headers: new Headers({
            Authorization: `Bearer ${contextData.user?.access_token}`,
          }),
          method: "DELETE",
        }
      );
      toast[answer.success ? "success" : "error"](answer.message);
      if (answer.success) getData();
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <LayoutAdmin
      title="Presentación de productos nuevos"
      icon={<FcTemplate size={34} />}
    >
      <div className="container-fluider">
        <div className="d-flex justify-content-end align-items-center">
          <Link to="/dashboard/agregar/presentacion">
            <Button primary type="button" className="mb-3">
              <BsPlusLg /> Agregar nuevo producto
            </Button>
          </Link>
        </div>
        {article.length >= 1 ? (
          article.map((el, i) => (
            <>
              <Card key={i}>
                <div
                  className="d-flex justify-content-between flex-wrap"
                  key={el._id}
                >
                  <div>
                    <Label>Id</Label>
                    <br />
                    <Text type="Paragraph" color="#000" content={`#${i}`} />
                  </div>
                  <div>
                    <Label>Título</Label>
                    <br />
                    <Text type="Paragraph" color="#000" content={el.titulo} />
                  </div>
                  <div>
                    <Label>Sub Titulo</Label>
                    <br />
                    <Text
                      type="Paragraph"
                      color="#000"
                      content={el.descripcion}
                    />
                  </div>
                  <div>
                    <Label>Producto</Label>
                    <br />
                    <Image
                      src={el.imagen}
                      alt="image prodcuto coinsa"
                      loading="lazy"
                      width={40}
                      height={40}
                    />
                  </div>
                  <div>
                    <Label>Objetivos</Label>
                    <br />
                    <ol>
                      {el.items.map((item, i) => (
                        <li key={`${item}-${i}`}>{item}</li>
                      ))}
                    </ol>
                  </div>
                  <div>
                    <Label>Acciones</Label>
                    <br />
                    <Button
                      type="button"
                      danger
                      className="me-2"
                      onClick={() => {
                        if (el._id) deleteItem(el._id);
                      }}
                    >
                      <BsTrash />
                    </Button>
                    <Link to={`/dashboard/editar/presentacion/${el._id}`}>
                      <Button type="button" info className="me-2">
                        <BsPencil />
                      </Button>
                    </Link>
                  </div>
                </div>
              </Card>
              <Hr />
            </>
          ))
        ) : (
          <div>
            <LoadButton />
          </div>
        )}
      </div>
    </LayoutAdmin>
  );
};

export default ListPresentation;
