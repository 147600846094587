import React, { useState, useId, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { fetchMethod } from "../../utils/fetch/fecth";

// const NavWrapper = styled.nav`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   height: 60px;
//   background-color: #222;
//   color: #fff;
// `;

const NavItem = styled.div`
  position: relative;
  padding: 0 20px;
  cursor: pointer;
  &:hover {
    color: #3e8d90;
  }
`;

const DropdownList = styled.ul`
  position: absolute;
  top: 60px;
  left: 0;
  background-color: #fff;
  padding: 48px 0 48px 20px;
  min-width: 270px;
  max-height: 80vh;
  -ms-box-shadow: 0 15px 15px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 15px 15px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 15px #0000001a;
  overflow-y: scroll;
  border: 1px solid #ddd;
  border-radius: 0;
  list-style: none;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
`;

const DropdownItem = styled.li`
  padding: 8px;
  cursor: pointer;
  text-align: left;
  color: #515050;
  font-weight: 300;
  &:hover {
    color: #639fa1;
  }
`;

const NavDropdownCategories = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showCategories, setshowCategories] = useState(true);
  const id = useId();

  const [categories, setCategories] = useState([]);
  const [subCategories, setsubCategories] = useState([]);
  const getCategories = async () => {
    const data = await fetchMethod(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_BACKEND_API}/categories?limit=${10}&skip=${0}`,
      {}
    );
    setCategories(data.data);
  };
  useEffect(() => {
    getCategories();
  }, []);

  return (
    <NavItem
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <Link className="nav-link" to="/categorias">
        Categorías
      </Link>

      <DropdownList isOpen={isOpen}>
        {showCategories
          ? categories.map((el) => (
              <DropdownItem
                key={el._id}
                onClick={() => { setsubCategories(el.subCategories);setshowCategories(false)}}
              >
                {el.nombre}
              </DropdownItem>
            ))
          : subCategories.map((el) => (
              <Link
                key={el._id}
                className="text-decoration-none"
                to={`/productos/Medicamentos/${el.nombre}/all`}
              >
                <DropdownItem>{el.nombre}</DropdownItem>
              </Link>
            ))}
      </DropdownList>
    </NavItem>
  );
};

export default NavDropdownCategories;
