/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import LayoutMain from "../../layouts/layoutMain";
import { Text, BlockText } from "../../components/elementsDS/Content";
import { useUser } from "../../context/userContext";
import { Animate } from "../../components/elementsDS/animations/animations";

const MakesDifferent: React.FC = () => {
  const contextData = useUser();
  const [content, setcontent] = useState("");

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_API}/articles/notArticles/QUENOSHACEDIFERENTES`
    )
      .then((data) => data.json())
      .then((data) => {
        setcontent(data.data[0].body);
      })
      .catch((e) => toast.error(e.message));
  }, []);
  return (
    <LayoutMain>
      {content ? (
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      ) : (
        <div className="container-fluid mb-5 mt-5">
          <Animate.FadeIn>
            <Text
              type="H5"
              content="¿Qué nos hace diferentes?"
              bold
              align="center"
              color="#5C6562"
            />
          </Animate.FadeIn>
          <br />
          <br />
          <Animate.FadeIn>
            <div className="row align-items-center g-0 mt-5 mb-5">
              <div className="col-12 col-lg-5">
                <img
                  src="/img/fabrica.png"
                  alt="fabrica"
                  className="img-fluid m-auto d-block w-100"
                />
              </div>
              <div className="col-12 col-lg-7">
                <BlockText right blockSmall>
                  <Text
                    type="H5"
                    content="FABRICA COMPLETA"
                    bold
                    align="left"
                    color="#002C1C"
                  />
                  <p className="fw-light fs-5">
                    Con más de 5,000 m2, equipada con tecnología de punta y 19
                    líneas de producción.
                  </p>
                </BlockText>
              </div>
            </div>
          </Animate.FadeIn>
          <Animate.FadeIn>
            <div className="row align-items-center g-0 mt-5 mb-5">
              <div className="col-12 col-lg-7">
                <BlockText right alignTextRight blockSmall>
                  <Text
                    type="H5"
                    content="COMPROMETIDOS CON LA CALIDAD"
                    bold
                    align="right"
                    color="#002C1C"
                  />
                  <p className="fw-light fs-5">
                    15 años continuos de calidad certificada, siendo una de las
                    primeras empresas de la región en certificar con normas de
                    Calidad ISO.
                  </p>
                </BlockText>
              </div>
              <div className="col-12 col-lg-5">
                <img
                  src="/img/logos-iso.png"
                  alt="logos iso"
                  className="img-fluid w-100"
                />
              </div>
            </div>
          </Animate.FadeIn>
          <Animate.FadeIn>
            <div className="row align-items-center g-0 mt-5 mb-5">
              <div className="col-12 col-lg-5">
                <img
                  src="/img/equipo.png"
                  alt="capacidad de producción"
                  className="img-fluid m-auto d-block w-100"
                />
              </div>
              <div className="col-12 col-lg-7">
                <BlockText right blockSmall>
                  <Text
                    type="H5"
                    content="EQUIPO ESPECIALIZADO"
                    bold
                    align="left"
                    color="#002C1C"
                  />
                  <p className="fw-light fs-5">
                    Contamos con un grupo de profesionales altamente calificados
                    que forman parte de un equipo de especialistas
                    interdisciplinarios garantizando los mas altos niveles de
                    calidad.
                  </p>
                </BlockText>
              </div>
            </div>
          </Animate.FadeIn>
          <Animate.FadeIn>
            <div className="row align-items-center g-0 mt-5 mb-5">
              <div className="col-12 col-lg-7">
                <BlockText right alignTextRight blockSmall>
                  <Text
                    type="H5"
                    content="AMPLIO PORTAFOLIO PRODUCTOS"
                    bold
                    align="right"
                    color="#002C1C"
                  />
                  <p className="fw-light fs-5">
                    Contamos con 17 marcas registradas, más de 450 SKU.
                  </p>
                </BlockText>
              </div>
              <div className="col-12 col-lg-5">
                <img
                  src="/img/portafolio.png"
                  alt="logos iso"
                  className="img-fluid w-100"
                />
              </div>
            </div>
          </Animate.FadeIn>
          <Animate.FadeIn>
            <div className="row align-items-center g-0 mt-5 mb-5">
              <div className="col-12 col-lg-5">
                <img
                  src="/img/capacidad.png"
                  alt="capacidad de producción"
                  className="img-fluid m-auto d-block w-100"
                />
              </div>
              <div className="col-12 col-lg-7">
                <BlockText right blockSmall>
                  <Text
                    type="H5"
                    content="CAPACIDAD DE PRODUCCIÓN"
                    bold
                    align="left"
                    color="#002C1C"
                  />
                  <p className="fw-light fs-5">
                    Mas de 3.5 millones de unidades producidas al año
                  </p>
                </BlockText>
              </div>
            </div>
          </Animate.FadeIn>
          <Animate.FadeIn>
            <div className="row align-items-center g-0 mt-5 mb-5">
              <div className="col-12 col-lg-7">
                <BlockText right alignTextRight blockSmall>
                  <Text
                    type="H5"
                    content="LÍDERES DEL MERCADO"
                    bold
                    align="right"
                    color="#002C1C"
                  />
                  <p className="fw-light fs-5">
                    Marcas sólidas y posicionadas con más de 40 años de
                    trayectoria, presentes en más de 650 puntos de venta, en 6
                    países de la región.
                  </p>
                </BlockText>
              </div>
              <div className="col-12 col-lg-5">
                <img
                  src="/img/nodor-products.png"
                  alt="logos iso"
                  className="img-fluid w-100"
                />
              </div>
            </div>
          </Animate.FadeIn>
        </div>
      )}
    </LayoutMain>
  );
};

export default MakesDifferent;
