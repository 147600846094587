/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";
import { Text, Image } from "../../../components/elementsDS/Content";
import { Animate } from "../../../components/elementsDS/animations/animations";
interface Props {
  iconItem?: string;
  firstTextDirection?: boolean;
  textItem?: string;
}

const ItemBanner: React.FC<Props> = ({
  firstTextDirection,
  iconItem,
  textItem,
}) => {
  return (
    <>
      {firstTextDirection ? (
        <div className="d-flex align-items-center justify-content-start mb-3 mt-3">
          <Animate.FadeUp>
            <Image
              src={iconItem}
              alt="producto image coinsa"
              loading="lazy"
              className="me-3"
              responsiveIcon
            />
          </Animate.FadeUp>
          <Text type="H5" content={textItem} color="#000" align="left" />
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-end mb-3 mt-3">
          <Text type="H5" content={textItem} color="#000" align="right" />
          <Image
            src={iconItem}
            alt="icono banner coinsa"
            loading="lazy"
            className="ms-3"
            responsiveIcon
          />
        </div>
      )}
    </>
  );
};

export default ItemBanner;
