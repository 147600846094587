import React from "react";
import { FcFlashOn, FcImageFile, FcNews, FcPackage, FcTemplate, FcViewDetails } from "react-icons/fc";
import { Text } from "../elementsDS/Content";

export const DataSidebar = [
  {
    title: <Text type="Paragraph" content="Categorías" />,
    icon: <FcViewDetails size={45} color="#086141" />,
    path: "/dashboard/categorias",
  },
  {
    title: <Text type="Paragraph" content="Productos" />,
    icon: <FcPackage size={45} color="#086141" />,
    path: "/dashboard/productos",
  },
  {
    title: <Text type="Paragraph" content="Artículos" />,
    icon: <FcNews size={45} color="#086141" />,
    path: "/dashboard/articulos",
  },
  {
    title: <Text type="Paragraph" content="Páginas" />,
    icon: <FcTemplate size={45} color="#086141" />,
    path: "/dashboard/paginas",
  },
  {
    title: <Text type="Paragraph" content="Banners" />,
    icon: <FcImageFile size={45} color="#086141" />,
    path: "/dashboard/banners",
  },
  {
    title: <Text type="Paragraph" content="Presentación" />,
    icon: <FcFlashOn size={45} color="#086141" />,
    path: "/dashboard/presentacion",
  },
];
