import React from "react";

interface Props {
  message?: any;
}

const LoadButton: React.FC<Props> = ({ message }) => {
  return (
    <div className="d-flex align-items-center">
      <span className="spinner-grow spinner-grow-sm me-1" role="status"></span>
      {message}
    </div>
  );
};

export default LoadButton;
