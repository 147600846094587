/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { fetchMethod } from "../../utils/fetch/fecth";
import LayoutMain from "../../layouts/layoutMain";
import { Image } from "../../components/elementsDS/Content";
import Articles from "./articles/articles";
import BannerLaunchProduct from "./launch/BannerLaunchProduct";
import { toast } from "react-toastify";
import { IarticlesPresentation } from "../../commons/interfaces/article.interface";
import { BsArrowLeftCircle, BsArrowRightCircle } from "react-icons/bs";

const Home: React.FC = () => {
  const [articles, setarticles] = useState<IarticlesPresentation[]>([]);
  const [image, setimage] = useState<any[]>([]);
  const getImageBanner = async () => {
    const answer: any = await fetchMethod(
      `${process.env.REACT_APP_BACKEND_API}/images-banners/PRINCIPAL_BANNER`,
      {}
    );
    setimage(answer.data);
  };
  const getArticleBanner = () => {
    fetch(`${process.env.REACT_APP_BACKEND_API}/articles/articleBanner`)
      .then((data) => data.json())
      .then((data) => setarticles(data.data))
      .catch((e) => toast.error(e.message));
  };
  useEffect(() => {
    getImageBanner();
    getArticleBanner();
  }, []);

  const responsive = {
    0: { items: 1 },
    568: { items: 1 },
    1024: { items: 1 },
  };
  const carousel = useRef<AliceCarousel>(null);

  return (
    <LayoutMain>
      <div key="btns" style={{ position: "relative" }}>
        <AliceCarousel
          key="carousel"
          mouseTracking
          ref={carousel}
          animationType="fadeout"
          animationDuration={800}
          responsive={responsive}
          // disableDotsControls
          disableButtonsControls
          items={image.map((value, i) => (
            <>
              <Link to={value.link}>
                <Image
                  src={value.imageUrl}
                  alt="image banner coinsa"
                  className="m-auto d-block"
                  loading="lazy"
                />
              </Link>
            </>
          ))}
        />
        <button
          className="btn-prev-carrousel"
          onClick={(e) => carousel?.current?.slidePrev(e)}
        >
          <BsArrowLeftCircle className="icon-carrousel" color="#ffffff" />
        </button>
        <button
          className="btn-next-carrousel"
          onClick={(e) => carousel?.current?.slideNext(e)}
        >
          <BsArrowRightCircle className="icon-carrousel" color="#ffffff" />
        </button>
      </div>
      {/* <div id="carouselExample" className="carousel slide" data-bs-ride="false">
        <div className="carousel-inner">
          {image.map((value, i) => (
            <div
              className={`carousel-item ${i === active && "active"}`}
              key={value._id}
            >
              <Image
                src={value.imageUrl}
                alt="image banner coinsa"
                className="d-block w-100"
                loading="lazy"
              />
            </div>
          ))}
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExample"
          data-bs-slide="prev"
        >
          <BsArrowLeftCircle size={40} color="#6e7772" style={{marginRight: '150px' }} />
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExample"
          data-bs-slide="next"
        >
          <BsArrowRightCircle size={40} color="#6e7772" style={{marginRight: '-150px' }} />
          <span className="visually-hidden">Next</span>
        </button>
      </div> */}
      <Articles />
      <AliceCarousel
        // autoPlay
        // autoPlayControls
        // autoPlayStrategy="none"
        // autoPlayInterval={1000}
        // animationDuration={1000}
        // animationType="fadeout"
        // infinite
        // touchTracking={false}
        // disableDotsControls
        disableButtonsControls
        items={articles.map((el, i) => (
          <div className="item" data-value={i} key={el._id}>
            <BannerLaunchProduct
              title={el.titulo}
              subtitle={el.descripcion}
              imageProduct={el.imagen}
              imageItem={el.imagenItem}
              items={el.items}
            />
          </div>
        ))}
      />
      {/* sección de categorías en home */}
      {/* <div className="container">
        <br /><br />
        <Text type="H5" content="Categorías de Productos" color="#B9D3C9" align="center" />
        <div className="row justify-content-center mt-5 text-center">
            <CardCategory
              key={"1"}
              name="Medicamentos"
              image="/img/cat-medicamentos.png"
              link="/productos/"
            />
        </div>
      </div> */}
    </LayoutMain>
  );
};

export default Home;
