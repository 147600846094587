import React from "react";
import styled from "styled-components";
import PropTypes from 'prop-types';

const Background = styled.div`
  background-color: #fafafa;
  padding: 150px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
`;

const Text = styled.p`
  font-size: 15px;
  font-weight: normal;
  color: #787878;
`;


const DataNotFound = ({ text, sizeIcon }) => (
  <Background>
    <Image src="/img/notfound.png" alt="icon nothing found" width={sizeIcon} />{" "}
    <Text> {text} </Text>{" "}
  </Background>
);

DataNotFound.propTypes = {
  text: PropTypes.string.isRequired,
  sizeIcon: PropTypes.string.isRequired,
};

export default DataNotFound;
