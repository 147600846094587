import styled from "styled-components";
const SHeaderTable = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px 20px; /* row-gap column gap */
  justify-content: space-evenly;
  margin-bottom: 40px;
`;
const SHeaderItem = styled.div`
  flex-grow: 3;
`;

export { SHeaderTable, SHeaderItem};