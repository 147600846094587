/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from "react";
import LayoutMain from "../../layouts/layoutMain";
import { Text, Hr } from "../../components/elementsDS/Content";
import { Button } from "../../components/elementsDS/buttons/Buttons";
import { Input, Textarea } from "../../components/elementsDS/Forms";
import { Card } from "../../components/card/card";
import { Animate } from "../../components/elementsDS/animations/animations";
import { toast } from "react-toastify";

const Contact: React.FC = () => {
  const [conctactData, setConctactData] = useState({
    name: "",
    lastName: "",
    email: "",
    country: "",
    message: "",
  });
  const [loading, setloading] = useState(false);
  const sendMessage = () => {
    setloading(true);
    if (
      !conctactData.country ||
      !conctactData.email ||
      !conctactData.lastName ||
      !conctactData.message ||
      !conctactData.name
    ) {
      toast.info("rellene todos los campos");
      return;
    }
    fetch(`${process.env.REACT_APP_BACKEND_API}/sendMail`, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(conctactData),
    })
      .then((data) => data.json())
      .then((data) => {
        toast.success("Correo enviado");
        setloading(true);
        return;
      })
      .catch((e) => toast.error(e.message));
  };
  return (
    <LayoutMain>
      <div className="container mb-5 mt-5">
        <Animate.FadeUp>
          <Text
            type="H5"
            content="Contáctanos"
            bold
            color="#83B0A0"
            align="center"
          />
          <Hr />
        </Animate.FadeUp>
        <div className="row justify-content-around g-4">
          <div className="col">
            <Animate.FadeUp>
              <div className="d-flex align-items-start">
                <img
                  src="/img/iconmap.png"
                  alt=""
                  loading="lazy"
                  className="me-3"
                  width={50}
                />
                <div>
                  <Text type="H6" bold content="Dirección:" color="#086141" />
                  <Text
                    type="Paragraph"
                    content="Colonia Miraflores, Boulevard Santa Cristina, continuo a HTC, frente a Autorepuestos NAPA, Tegucigalpa, Honduras C.A."
                    color="#787878"
                  />
                </div>
              </div>
            </Animate.FadeUp>
          </div>
          <div className="col">
            <Animate.FadeUp>
              <div className="d-flex align-items-start">
                <img
                  src="/img/iconemail.png"
                  alt=""
                  loading="lazy"
                  className="me-3"
                  width={50}
                />
                <div>
                  <Text type="H6" bold content="Email:" color="#086141" />
                  <Text
                    type="Paragraph"
                    content="info@coinsa.hn"
                    color="#787878"
                  />
                </div>
              </div>
            </Animate.FadeUp>
          </div>
          <div className="col-auto me-auto">
            <Animate.FadeUp>
              <div className="d-flex align-items-start">
                <img
                  src="/img/iconcall.png"
                  alt=""
                  loading="lazy"
                  className="me-3"
                  width={50}
                />
                <div>
                  <Text type="H6" bold content="Teléfono:" color="#086141" />
                  <Text
                    type="Paragraph"
                    content="(504) 2230-8900"
                    color="#0980E8"
                  />
                </div>
              </div>
            </Animate.FadeUp>
          </div>
        </div>
        <Card loginCard className="mt-5">
          <Text
            type="H6"
            content="Para más información escríbenos"
            bold
            color="#002C1C"
            align="left"
          />
          <div className="row mt-2">
            <div className="col-12 col-lg-6">
              <div className="input-group flex-nowrap">
                <Input
                  type="text"
                  placeholder="*Nombre"
                  className="mt-4"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-lg"
                  value={conctactData.name}
                  onChange={({ target }) => {
                    setConctactData({ ...conctactData, name: target.value });
                  }}
                />
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="input-group flex-nowrap">
                <Input
                  type="text"
                  className="mt-4"
                  placeholder="*Apellido"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-lg"
                  value={conctactData.lastName}
                  onChange={({ target }) => {
                    setConctactData({
                      ...conctactData,
                      lastName: target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="input-group flex-nowrap mt-4">
                <Input
                  type="text"
                  placeholder="*Email"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-lg"
                  value={conctactData.email}
                  onChange={({ target }) => {
                    setConctactData({ ...conctactData, email: target.value });
                  }}
                />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="input-group flex-nowrap mt-4">
                <Input
                  type="text"
                  placeholder="*País"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-lg"
                  value={conctactData.country}
                  onChange={({ target }) => {
                    setConctactData({ ...conctactData, country: target.value });
                  }}
                />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="input-group flex-nowrap mt-4">
                <Textarea
                  placeholder="Mensaje"
                  value={conctactData.message}
                  onChange={({ target }) => {
                    setConctactData({ ...conctactData, message: target.value });
                  }}
                />
              </div>
            </div>
            <div className="col-lg-12">
              <Button
                type="button"
                primary
                className="mt-4"
                onClick={sendMessage}
                disabled={loading}
              >
                Enviar
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </LayoutMain>
  );
};

export default Contact;
