/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useEffect } from "react";
import "react-accessible-accordion/dist/fancy-example.css";
import Pagination from "rc-pagination";
import LayoutMain from "../../layouts/layoutMain";
import { Text, Hr, Image } from "../../components/elementsDS/Content";
import {
  Iproduct,
  IanswerTypeProducts,
} from "../../commons/interfaces/products.interface";
import { fetchMethod } from "../../utils/fetch/fecth";
import { Card } from "../../components/card/card";
import { useParams } from "react-router-dom";
import DataNotFound from "../../components/elementsDS/EmptyStates/DataNotFound";
import SearchData from "../../components/elementsDS/Loaders/SearchData";
import {
  CheckBox,
  LabelCheck,
  LabelText,
} from "../../components/elementsDS/Forms";
import { IapiAnswerGeneral } from "../../commons/interfaces/category.interface";
import { Animate } from "../../components/elementsDS/animations/animations";
import { BRANDS } from "../../commons/const/brands";
import { toast } from "react-toastify";

const SearchProductsBrands: React.FC = () => {
  const { marca } = useParams();
  const [products, setproducts] = useState<Iproduct[]>();
  const [brands, setbrands] = useState<string[]>(() => {
    if (marca) {
      return [marca];
    }
    return [];
  });
  const [typeProducts, settypeProducts] = useState<string[]>([]);
  const [typeProductsSelected, settypeProductsSelected] = useState<string[]>(
    []
  );
  const [total, setTotal] = useState<number>(0);
  const [current, setcurrent] = useState(1);
  const [skip, setSkip] = useState(0);
  const [ready, setReady] = useState(false);
  const [imageError, setimageError] = useState(false);
  const [loading, setLoading] = useState(false);

  const getProducts = async () => {
    setLoading(false);
    setproducts([]);
    const data: IapiAnswerGeneral = await fetchMethod(
      `${process.env.REACT_APP_BACKEND_API}/products/searchProducts?skip=${skip}&limit=32`,
      {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          categories: [],
          subcategories: [],
          search: "",
          brands,
          typeProducts: typeProductsSelected,
        }),
      }
    );

    if (data.success) {
      setproducts(data.data);
      setReady(true);
    }
    setLoading(true);
  };

  useEffect(() => {
    getProducts();

    fetch(`${process.env.REACT_APP_BACKEND_API}/products/typePorducts`, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(brands),
    })
      .then((data) => data.json())
      .then((data: IanswerTypeProducts) => {
        const typeProducts2: string[] = [];
        data.data.forEach((el) => {
          if (!typeProducts2.find((el2) => el2 === el)) typeProducts2.push(el);
        });

        settypeProducts(typeProducts2);
      })
      .catch((e) => console.log(e));
    return () => {
      setproducts([]);
    };
  }, [skip, brands, typeProductsSelected, marca]);
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_API}/products/searchProductsBrands/counter`,
      {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          brands,
          typeProducts: typeProductsSelected,
        }),
      }
    )
      .then((data) => data.json())
      .then((data) => {
        if (data.success) {
          setTotal(data.data);
          setReady(true);
        }
      })
      .catch((err) => toast.error(err.message));
  }, [brands, typeProductsSelected]);
  useEffect(() => {
    if (marca) setbrands([marca]);
  }, [marca]);

  return (
    <LayoutMain>
      <div className="container pt-5 pb-5">
        {!imageError && (
          <Image
            src={`/img/brands/banners/${marca}.jpg`}
            width="100%"
            alt="brand image banner"
            loading="lazy"
            onError={() => {
              setimageError(true);
            }}
          />
        )}
        <Hr />
        <div className="row">
          <div className="col-12 col-lg-3">
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#flush-collapseOne-marca`}
                    aria-expanded="false"
                    aria-controls={`flush-collapseOne-marca`}
                  >
                    Marcas
                  </button>
                </h2>
                <div
                  id={`flush-collapseOne-marca`}
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    {BRANDS.map((brand, i) => (
                      <div
                        className="d-flex justify-content-between align-items-center"
                        key={`${brand}-${i}`}
                      >
                        <LabelCheck>
                          <CheckBox
                            checked={
                              brands.find((_brand) => _brand === brand)
                                ? true
                                : false
                            }
                            type="checkbox"
                            onClick={() => {
                              setSkip(0);
                              setcurrent(1);
                              if (brands.find((el) => el === brand)) {
                                setbrands(brands.filter((el) => el !== brand));
                              } else {
                                setbrands((exBrands) => [...exBrands, brand]);
                              }
                            }}
                          />
                          <LabelText>{brand}</LabelText>
                        </LabelCheck>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#flush-collapseOne-type-products`}
                    aria-expanded="false"
                    aria-controls={`flush-collapseOne-type-products`}
                  >
                    Tipo de productos
                  </button>
                </h2>
                <div
                  id={`flush-collapseOne-type-products`}
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    {typeProducts.map((typePorducts, i) => (
                      <div
                        className="d-flex justify-content-between align-items-center"
                        key={`${typePorducts}-${i}`}
                      >
                        <LabelCheck>
                          <CheckBox
                            type="checkbox"
                            checked={
                              typeProductsSelected.find(
                                (_type) => _type === typePorducts
                              )
                                ? true
                                : false
                            }
                            onClick={() => {
                              if (
                                typeProductsSelected.find(
                                  (el) => el === typePorducts
                                )
                              ) {
                                settypeProductsSelected(
                                  typeProductsSelected.filter(
                                    (el) => el !== typePorducts
                                  )
                                );
                              } else {
                                settypeProductsSelected((exBrands) => [
                                  ...exBrands,
                                  typePorducts,
                                ]);
                              }
                            }}
                          />
                          <LabelText>{typePorducts}</LabelText>
                        </LabelCheck>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-9">
            <div className="row align-items-center">
              {loading ? (
                loading && ready ? (
                  products?.map((product) => (
                    <div className="col-12 col-lg-4" key={product._id}>
                      <Animate.ScaleIn key={product._id}>
                        <Card>
                          <Image
                            src={product.urlImage}
                            onError={(e: any) => {
                              e.target.src = "/img/noproduct.png";
                            }}
                            alt="image alt coinsa"
                            className="m-auto d-block zoom"
                          />
                          <div className="card-footer">
                            <Hr />
                            <Text
                              type="Paragraph"
                              content={product.nombreProducto}
                              bold
                              color="#002C1C"
                            />
                          </div>
                        </Card>
                      </Animate.ScaleIn>
                    </div>
                  ))
                ) : (
                  <DataNotFound text="NO HAY COINCIDENCIAS" sizeIcon="100" />
                )
              ) : (
                <SearchData text="Buscando datos..." sizeIcon="100" />
              )}
            </div>
            <div className="mt-4 d-flex justify-content-end">
              <Pagination
                total={total}
                current={current}
                hideOnSinglePage
                showTitle={false}
                defaultPageSize={32}
                onChange={(actualPostion) => {
                  setSkip(actualPostion * 32 - 32);
                  setcurrent(actualPostion);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </LayoutMain>
  );
};

export default SearchProductsBrands;
