import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import InputSearch from "../components/search/InputSearch";
import { Animate } from "../components/elementsDS/animations/animations";
import { GlobalStyle } from "./globalStyle";
import { Image } from "../components/elementsDS/Content";

const theme = {
  space: [
    "4px",
    "8px",
    "12px",
    "16px",
    "24px",
    "32px",
    "48px",
    "64px",
    "96px",
    "128px",
    "192px",
    "256px",
    "384px",
  ],
};

interface Props {
  children: any;
}
const LayoutAdmin: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const [value, setValue] = useState("");

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value);
  }

  function handlePress() {
    navigate(`/productos/all/all/${value ? value : "all"}`);
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <div className="container-fluider">
        {/* <Animate.FadeIn>
            <div className="d-flex justify-content-end">
              <div className="col-12 col-lg-3">
                <InputSearch
                  searchGlobal
                  value={value}
                  onChange={handleChange}
                  eventButton={handlePress}
                  placeholder="Busqueda"
                />
              </div>{" "}
            </div>
        </Animate.FadeIn> */}
        <Header />
        <div>{children}</div>
        <div className="d-flex justify-content-center">
          <div className="col-12 col-md-12 col-sm-12 col-lg-6">
            <Link to="/contactanos" style={{ cursor: 'pointer' }}>
              <Animate.FadeIn>
                <Image
                  src="/img/banner3.png"
                  alt="banner coinsa"
                  className="mt-5 mb-5 d-block m-auto"
                  loading="lazy"
                />
              </Animate.FadeIn>
            </Link>
          </div>
        </div>
        <Animate.FadeIn>
          <Footer />
        </Animate.FadeIn>
      </div>
    </ThemeProvider>
  );
};

// LayoutMain.propTypes={children: PropTypes.any}

export default LayoutAdmin;
