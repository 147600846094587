export const fetchMethod = async (url: string, options: RequestInit) => {
  try {
    let answer: any = await fetch(url, options);
    answer = answer.json();
    return answer;
  } catch (error: any) {
    return {
      success: false,
      message: error.message,
    };
  }
};

export const fetchMethodPost = async (url: string, data: any): Promise<any> => {
  try {
    const answer = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      mode: "cors",
    });
    return answer.json();
  } catch (error: any) {
    return {
      success: false,
      message: error.message,
    };
  }
};

export const optionFecthPut = (data: any, header: any) => {
  return {
    method: "PUT",
    headers: new Headers(header),
    body: JSON.stringify(data),
    mode: "cors",
  };
};
