import React from "react";
import Select from "react-select";
import { BsUpload } from "react-icons/bs";
import { Button } from "../../../../components/elementsDS/buttons/Buttons";
import Modal from "react-modal";
import {
  Input,
  InputFile,
  LabelFile,
} from "../../../../components/elementsDS/Forms";
import { Hr, Text } from "../../../../components/elementsDS/Content";
import LoadButton from "../../../../components/elementsDS/Loaders/LoadButton";
import { ImageCategory } from "../../../../components/card/cardCategory";
import { Icategory } from "../../../../commons/interfaces/category.interface";
import { deleteImage } from "../../../../utils/uploadFiles/firebaseStorage";

interface Iprops {
  modalIsOpen: boolean;
  closeModal: any;
  customStyles: any;
  loading: boolean;
  onChangeCategoryName: any;
  categoryName: any;
  onChangeImage: any;
  onChangeImageBanner: any;
  imageCategory: any;
  imageCategoryBanner: any;
  updateImage: () => void;
  updateImageBanner: () => void;
  updateCategoryName: () => void;
  categorySeleted: Icategory;
  deleteImage: (key: string) => void;
}
const ModalUpdateCategory = ({
  modalIsOpen,
  closeModal,
  customStyles,
  loading,
  onChangeCategoryName,
  categoryName,
  onChangeImage,
  onChangeImageBanner,
  imageCategory,
  imageCategoryBanner,
  updateImageBanner,
  updateImage,
  updateCategoryName,
  categorySeleted,
  deleteImage,
}: Iprops) => {
  return (
    <Modal
      ariaHideApp={false}
      isOpen={modalIsOpen}
      // className="Modal"
      // overlayClassName="Overlay"
      // onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="row">
        <Text
          type="H6"
          content={`Actualizar categoria ${categoryName}`}
          color="#5C6562"
        />
        <Hr />
        <div className="col-lg-6 justify-content-center">
          <LabelFile htmlFor="newImage" disabled={loading} className="mb-3">
            {!loading ? (
              <>
                <BsUpload className="me-2" /> Subir imágen de categoria
              </>
            ) : (
              <LoadButton message="Cargando imágen..." />
            )}
            <InputFile
              type="file"
              name="newImage"
              id="newImage"
              onChange={onChangeImage}
            />
          </LabelFile>
          <Button
            onClick={updateImage}
            type="button"
            primary
            className="me-2 m-2"
            disabled={loading}
          >
            {!loading ? "Guardar Imagen" : "Procesando..."}
          </Button>
          <Button
            onClick={() => deleteImage("imageUrl")}
            type="button"
            danger
            className="me-2 m-2"
            disabled={loading}
          >
            {!loading ? "Eliminar imagen" : "Procesando..."}
          </Button>
          <br />
          <div className="d-flex justify-content-center">
            <div>
              <img
                className="m-1"
                src={imageCategory}
                height={100}
                width={100}
                alt=""
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title="imagen nueva para cargar"
              />
              <p>Imagen nueva</p>
            </div>

            <div>
              <img
                className="m-1"
                src={categorySeleted.imageUrl}
                height={100}
                width={100}
                alt=""
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title="imagen actual"
              />
              <p>Imagen actual</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <LabelFile htmlFor="ImageBanner" disabled={loading} className="mb-3">
            {!loading ? (
              <>
                <BsUpload className="me-2" /> Subir imágen de banner
              </>
            ) : (
              <LoadButton message="Cargando imágen..." />
            )}
            <InputFile
              type="file"
              name="ImageBanner"
              id="ImageBanner"
              onChange={onChangeImageBanner}
            />
          </LabelFile>
          <Button
            onClick={updateImageBanner}
            type="button"
            primary
            className="me-2 m-2"
            disabled={loading}
          >
            {!loading ? "Guardar Imagen de banner" : "Procesando..."}
          </Button>
          <Button
            type="button"
            danger
            className="me-2 m-2"
            disabled={loading}
            onClick={() => deleteImage("imageBannerUrl")}
          >
            {!loading ? "Eliminar Banner" : "Procesando..."}
          </Button>
          <br />
          <div className="d-flex justify-content-center">
            <div>
              <img
                className="m-1"
                src={imageCategoryBanner}
                height={80}
                width={250}
                alt=""
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title="imagen nueva para actualizar"
              />
              <p>Imagen nueva</p>
            </div>
            <div>
              <img
                className="m-1"
                src={categorySeleted.imageBannerUrl}
                height={80}
                width={250}
                alt=""
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title="imagen actual de categoria"
              />
              <p>Imagen actual</p>
            </div>
          </div>
        </div>
        <div className="mt-3 mb-3">
          <Input
            value={categoryName}
            type="text"
            placeholder="Nombre de la categoria"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-lg"
            onChange={onChangeCategoryName}
          />
        </div>
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <Button
          onClick={updateCategoryName}
          type="button"
          primary
          className="me-2"
          disabled={loading}
        >
          {!loading ? "actualizar nombre" : "Procesando..."}
        </Button>
        <Button type="button" light onClick={closeModal} disabled={loading}>
          Cerrar
        </Button>
      </div>
    </Modal>
  );
};

export default ModalUpdateCategory;
