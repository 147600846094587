import styled, { css } from "styled-components";
import { colors } from "./utilsStyle/PaletteColor";

const SidebarMenuWeb = styled.div`
  min-height: 100vh;
  overflow-y: auto;
  // width: 140px;
  left: 0;
  height: 100%;
  position: fixed;
  display: flex;
  z-index: 1050;
  align-items: center;
  justify-content: center;
  align-content: center;
`;

const SidebarMenuMovil = styled.div`
  min-height: 100vh;
  overflow-y: auto;
  border-right: 0;
  // background-color: rgba(0,0,0,0.3);
  width: 80%;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1100;
  position: fixed;
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  padding-left: 0;
  background: #fff;
  margin-bottom: 0;
  border: 0;
  border-radius: 0;
  ${(props) =>
    props.mobile &&
    css`
      border: 0;
      border-radius: 0;
      width: 150px;
      min-height: 100vh;
  `}
`;

const ListItem = styled.li`
  text-align: center;
  border-radius: 0; 
  position: relative;
  display: block;
  color: ${colors.dark};
  padding: 9px 9px;
  margin: 4px 6px;
  text-decoration: none;
  background-color: inherit;
  transition: 1s;
  &:hover {
    background: #F4F4F4;
    background-size: 100%;
    color: ${colors.blue};
  }
`;

export {SidebarMenuWeb, SidebarMenuMovil, List, ListItem};
