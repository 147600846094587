import React from "react";
import { TbError404 } from "react-icons/tb";
import { Link } from "react-router-dom";
import { Button } from "../components/elementsDS/buttons/Buttons";
import { Text } from "../components/elementsDS/Content";
import { Wrapper } from "../components/elementsDS/Layout";

const PageNotFound = () => {
  return (
    <Wrapper>
      <Text
        type="H2"
        content="¡Oops! No se encontró la página solicitada..."
        color="#136044"
        bold
        align="center"
      />
      <TbError404 size={250} color="#136044" />
      <Text
        type="H6"
        content="Parece que has intentado abrir una
        página que no existe. Pudo haberse eliminado,
        movido o no está disponible en este momento."
        color="#002C1C"
      />
      <Link to="/">
        <Button primary type="button" className="text-uppercase mt-4">
          Regresar a inicio
        </Button>
      </Link>
    </Wrapper>
  );
};

export default PageNotFound;
