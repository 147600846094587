/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";
import { BsArrowRepeat, BsImage, BsImageFill, BsPencil, BsTrash } from "react-icons/bs";
import { Button } from "../../../../components/elementsDS/buttons/Buttons";
import { Hr, Tag, Text } from "../../../../components/elementsDS/Content";
import { Card } from "../../../../components/card/card";
import { ProductsCard } from "../../../../commons/interfaces/Props/products.interface";

const ProductCard = ({
  nombreProducto,
  categoria,
  urlImage,
  editProduct,
  editProductImage,
}: ProductsCard) => {
  return (
    <div className="col-12 col-sm-6 col-md-6 col-lg-3">
      <Card className="position-relative">
        <div className="card-header">
          <Text type="Paragraph" content={nombreProducto} color="#5C6562" />
          <Hr />
        </div>
        <img
          src={urlImage}
          onError={(e: any) => {
            e.target.src = "/img/noproduct.png";
          }}
          alt="image alt coinsa"
          className="img-fluid card-img-top"
          loading="lazy"
        />
        <br />
        <br />
        <br />
        <br />
        <div className="position-absolute bottom-0 bg-white text-start">
          <Tag primary className="mb-2">
            Categoría: {categoria}
          </Tag>
          <div className="mt-2 mb-2">
            {/* <Button type="button" danger className="me-2">
              <BsTrash />
            </Button> */}
            <Button type="button" info className="me-2" onClick={editProduct}>
              <BsPencil />
            </Button>
            <Button light type="button" onClick={editProductImage}>
              <BsImage />
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ProductCard;
