/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { BsPencil, BsTrash } from "react-icons/bs";
import { BsPlusLg } from "react-icons/bs";
import { GrDeploy } from "react-icons/gr";
import { Button } from "../../../../components/elementsDS/buttons/Buttons";
import TableComponent from "../../../../components/table/table";
import LayoutAdmin from "../../../../layouts/layoutAdmin";
import { fetchMethod } from "../../../../utils/fetch/fecth";
import {
  IcategoryAnswer,
  Icategory,
  IsubCategory,
  IapiAnswerGeneral,
} from "./../../../../commons/interfaces/category.interface";
import ModalCategories from "./ModalCategories";
import ModalAddSubCategory from "./ModalAddSubCategory";
import { useUser } from "../../../../context/userContext";
import ModalUpdateCategory from "./ModalUpdateCategory";
import ModalEditSubCategory from "./ModalEditSubCategory";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    "max-height": "80vh",
    "max-width": "80%",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    zIndex: 1060,
  },
};

interface Props {
  subtitle?: string;
  loading?: boolean;
}
const Listcategories: React.FC<Props> = ({ subtitle }) => {
  const contextData = useUser();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalUpdateCategoryIsOpen, setmodalUpdateCategoryIsOpen] =
    useState(false);
  const [modalUpdateSubcategory, setmodalUpdateSubcategory] = useState(false);
  const [loading, setloading] = useState({
    loadingModalCategory: false,
    loadingModalSubCategory: false,
  });
  const [openSubcategoryModal, setopenSubcategoryModal] = useState(false);
  const [skip, setSkip] = useState(0);
  const [limit, setlimit] = useState(0);
  const [categories, setCategories] = useState<Icategory[]>([
    {
      _id: "",
      imageBannerName: "",
      imageBannerUrl: "",
      imageName: "",
      imageUrl: "",
      nombre: "",
      subCategories: [
        {
          _id: "",
          imageUrl: "",
          nombre: "",
        },
      ],
    },
  ]);
  const [categoryName, setcategoryName] = useState("");
  const [subCategoryName, setSubCategoryName] = useState("");
  const [image, setImage] = useState<any>();
  const [imageSubCategory, setImageSubCategory] = useState<any>();
  const [imageBanner, setImageBanner] = useState<any>();
  const [subCategories, setsubCategories] = useState<IsubCategory[]>([]);
  const [urlImage, seturlImage] = useState<any>();
  const [urlImageSubCategory, seturlImageSubCategory] = useState<any>();
  const [urlImageBanner, seturlImageBanner] = useState<any>();
  const [categorySelected, setcategorySelected] = useState("");
  const [categorySelectedObject, setcategorySelectedObject] =
    useState<Icategory>({
      _id: "",
      imageBannerUrl: "",
      imageUrl: "",
      nombre: "",
      subCategories: [],
      imageBannerName: "",
      imageName: "",
    });
  const [categorySeleted, setcategorySeleted] = useState<Icategory>({
    _id: "",
    imageBannerName: "",
    imageBannerUrl: "",
    imageName: "",
    imageUrl: "",
    nombre: "",
    subCategories: [],
  });
  function openModal() {
    setIsOpen(true);
  }
  const onChangeCategoryName = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    setcategoryName(target.value);
  };
  const onChangeSubCategoryName = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    setSubCategoryName(target.value);
  };
  const onChangeImage = ({ target, currentTarget }: React.ChangeEvent<any>) => {
    const file = currentTarget.files[0];
    setImage(file);
    const url = URL.createObjectURL(file);
    seturlImage(url);
  };
  const onChangeImageBanner = ({
    target,
    currentTarget,
  }: React.ChangeEvent<any>) => {
    const file = currentTarget.files[0];
    setImageBanner(file);
    const url = URL.createObjectURL(file);
    seturlImageBanner(url);
  };
  const onChangeImageSubCategory = ({
    target,
    currentTarget,
  }: React.ChangeEvent<any>) => {
    const file = currentTarget.files[0];
    setImageSubCategory(file);
    const url = URL.createObjectURL(file);
    seturlImageSubCategory(url);
  };
  const saveCategory = async () => {
    let message = "";
    setloading({
      ...loading,
      loadingModalCategory: true,
    });
    if (!image?.name) message += "no se agrego ninguna imagen. \n";
    if (!imageBanner?.name)
      message += "no se agrego ninguna imagen de banner. \n";
    if (!categoryName)
      message += "no se agrego ninguna nombre de categoria. \n";
    if (message) {
      setloading({
        ...loading,
        loadingModalCategory: false,
      });
      toast.info(message);
    }
    const formData = new FormData();
    formData.append("file", image);
    const answerImage = await fetchMethod(
      `${process.env.REACT_APP_BACKEND_API}/products/uploadImage`,
      {
        method: "POST",
        body: formData,
        mode: "cors",
      }
    );
    const formDataBanner = new FormData();
    formDataBanner.append("file", imageBanner);
    const answerImageBanner = await fetchMethod(
      `${process.env.REACT_APP_BACKEND_API}/products/uploadImage`,
      {
        method: "POST",
        body: formDataBanner,
        mode: "cors",
      }
    );
    const answer: IapiAnswerGeneral = await fetchMethod(
      `${process.env.REACT_APP_BACKEND_API}/categories`,
      {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
          Authorization: `Bearer ${contextData.user?.access_token}`,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          nombre: categoryName,
          imageUrl: answerImage.data.imageUri,
          subCategories: subCategories,
          imageBannerUrl: answerImageBanner.data.imageUri,
          imageBannerName: imageBanner.name,
          imageName: image.name,
        }),
      }
    );
    setloading({
      ...loading,
      loadingModalCategory: false,
    });
    if (answer.success) {
      getCategories();
      toast.success(answer.message);
      setcategoryName("");
      setImage(null);
      setImageBanner(null);
      seturlImage(null);
      seturlImageBanner(null);
    } else toast.error(answer.message);
  };
  const saveSubCategory = async () => {
    let message = "";
    setloading({
      ...loading,
      loadingModalSubCategory: true,
    });
    if (!imageSubCategory?.name) message += "no se agrego ninguna imagen. \n";
    if (!subCategoryName)
      message += "no se agrego ninguna nombre de sub categoria. \n";
    if (message) {
      setloading({
        ...loading,
        loadingModalCategory: false,
      });
      toast.info(message);
    }
    const formData = new FormData();
    formData.append("file", imageSubCategory);
    const answerImage = await fetchMethod(
      `${process.env.REACT_APP_BACKEND_API}/products/uploadImage`,
      {
        method: "POST",
        body: formData,
        mode: "cors",
      }
    );
    const answer: IapiAnswerGeneral = await fetchMethod(
      `${process.env.REACT_APP_BACKEND_API}/categories/subcategory`,
      {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
          Authorization: `Bearer ${contextData.user?.access_token}`,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          mongoId: categorySelected,
          nombre: subCategoryName,
          imageUrl: answerImage.data.imageUri,
        }),
      }
    );
    setloading({
      ...loading,
      loadingModalSubCategory: false,
    });
    if (answer.success) {
      getCategories();
      toast.success(answer.message);
      setSubCategoryName("");
      setImageSubCategory(null);
      seturlImageSubCategory(null);
    } else toast.error(answer.message);
  };
  function closeModal() {
    setcategoryName("");
    setImage(null);
    setImageBanner(null);
    seturlImage(null);
    seturlImageBanner(null);
    setIsOpen(false);
  }
  function closeModalUpdateCategory() {
    setcategoryName("");
    setImage(null);
    setImageBanner(null);
    seturlImage(null);
    seturlImageBanner(null);
    setcategorySelectedObject({
      _id: "",
      imageBannerUrl: "",
      imageUrl: "",
      nombre: "",
      subCategories: [],
      imageBannerName: "",
      imageName: "",
    });
    setmodalUpdateCategoryIsOpen(false);
  }
  const closeModalSubCategoyr = () => {
    setSubCategoryName("");
    setImageSubCategory(null);
    seturlImageSubCategory(null);
    setcategorySelected("");
    setopenSubcategoryModal(false);
  };
  const updateCategoryImage = async ({
    imageObject,
    keyImage,
    keyImageUrl,
  }: {
    imageObject: any;
    keyImage: string;
    keyImageUrl: string;
  }) => {
    setloading({ ...loading, loadingModalCategory: true });
    try {
      if (!imageObject?.name) {
        setloading({ ...loading, loadingModalCategory: false });
        toast.error("selecione una imagen");
        return;
      }
      const formData = new FormData();
      formData.append("file", imageObject);
      const answerImage = await fetchMethod(
        `${process.env.REACT_APP_BACKEND_API}/products/uploadImage`,
        {
          method: "POST",
          body: formData,
          mode: "cors",
        }
      );
      const answer: IapiAnswerGeneral = await fetchMethod(
        `${process.env.REACT_APP_BACKEND_API}/categories/${categorySelected}`,
        {
          method: "PUT",
          headers: new Headers({
            Accept: "application/json",
            Authorization: `Bearer ${contextData.user?.access_token}`,
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({
            [keyImageUrl]: answerImage.data.imageUri,
          }),
        }
      );
      toast[answer.success ? "success" : "error"](answer.message);
      getCategories();
      closeModalUpdateCategory();
    } catch (error) {
      toast.error("error al actualizar la imagen");
    }
    setloading({ ...loading, loadingModalCategory: false });
  };
  const deleteImage = async (keyImageUrl: string) => {
    setloading({ ...loading, loadingModalCategory: true });
    try {
      const answer: IapiAnswerGeneral = await fetchMethod(
        `${process.env.REACT_APP_BACKEND_API}/categories/${categorySelected}`,
        {
          method: "PUT",
          headers: new Headers({
            Accept: "application/json",
            Authorization: `Bearer ${contextData.user?.access_token}`,
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({
            [keyImageUrl]: "",
          }),
        }
      );
      toast[answer.success ? "success" : "error"](answer.message);
      getCategories();
      closeModalUpdateCategory();
    } catch (error) {
      toast.error("error al actualizar la imagen");
    }
    setloading({ ...loading, loadingModalCategory: false });
  };
  const getCategories = async () => {
    try {
      const data: IcategoryAnswer = await fetchMethod(
        `${process.env.REACT_APP_BACKEND_API}/categories?limit=${limit}&skip=${skip}`,
        {}
      );
      setCategories(data.data);
    } catch (error: any) {
      toast.error(error.message);
    }
  };
  useEffect(() => {
    getCategories();
  }, []);
  const columns = [
    {
      title: "Id",
      dataIndex: "_id",
      className: "text-center",
      key: "a",
      width: 100,
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      className: "text-center",
      key: "b",
      width: 100,
    },
    {
      title: "imagen",
      dataIndex: "imagenUrl",
      className: "text-center",
      key: "e",
      render(value: string, row: Icategory, index: any) {
        return (
          <>
            <img
              className="m-1"
              src={row.imageUrl}
              alt="imagen de categoria"
              width={100}
              height={100}
            />
            <img
              className="m-1"
              src={row.imageBannerUrl}
              alt="imagen de categoria"
              width={300}
              height={100}
            />
          </>
        );
      },
    },
    {
      title: "Sub Categorías",
      dataIndex: "subCategories",
      className: "text-center",
      key: "c",
      width: 200,
    },
    {
      title: "Acciones",
      dataIndex: "d",
      className: "text-center",
      key: "d",
      render(value: string, row: Icategory, index: any) {
        return (
          <>
            {/* <Button type="button" danger className="me-2">
              <BsTrash />
            </Button> */}
            <Button
              type="button"
              info
              className="me-2"
              onClick={() => {
                setcategorySeleted(row);
                setmodalUpdateCategoryIsOpen(true);
                //setcategorySelectedObject(row);
                setcategorySelected(row._id);
                setcategoryName(row.nombre);
              }}
            >
              <BsPencil />
            </Button>
            <Button
              type="button"
              info
              className="me-2"
              onClick={() => {
                const categorySelected = categories.find(
                  (category) => category._id === row._id
                );
                if (categorySelected) {
                  setcategorySelectedObject(categorySelected);
                }
                setmodalUpdateSubcategory(true);
              }}
            >
              <BsPencil className="me-1" /> Sub categoría
            </Button>
            <Button
              type="button"
              className="me-2"
              onClick={() => {
                setcategorySelected(row._id);
                setcategoryName(row.nombre);
                setopenSubcategoryModal(true);
              }}
            >
              <BsPlusLg className="me-1" /> Sub categoría
            </Button>
          </>
        );
      },
    },
  ];
  return (
    <LayoutAdmin title="Dashboard / Categorias /" icon={<GrDeploy size={34} />}>
      <ModalCategories
        saveCategory={saveCategory}
        closeModal={closeModal}
        customStyles={customStyles}
        loading={loading.loadingModalCategory}
        modalIsOpen={modalIsOpen}
        onChangeCategoryName={onChangeCategoryName}
        categoryName={categoryName}
        onChangeImage={onChangeImage}
        onChangeImageBanner={onChangeImageBanner}
        imageCategory={urlImage}
        imageCategoryBanner={urlImageBanner}
      />
      <ModalUpdateCategory
        deleteImage={deleteImage}
        categorySeleted={categorySeleted}
        closeModal={closeModalUpdateCategory}
        customStyles={customStyles}
        loading={loading.loadingModalCategory}
        modalIsOpen={modalUpdateCategoryIsOpen}
        onChangeCategoryName={onChangeCategoryName}
        categoryName={categoryName}
        onChangeImage={onChangeImage}
        onChangeImageBanner={onChangeImageBanner}
        imageCategory={urlImage}
        imageCategoryBanner={urlImageBanner}
        updateCategoryName={() => {
          return;
        }}
        updateImage={() => {
          updateCategoryImage({
            imageObject: image,
            keyImage: "imageName",
            keyImageUrl: "imageUrl",
          });
        }}
        updateImageBanner={() => {
          updateCategoryImage({
            imageObject: imageBanner,
            keyImage: "imageBannerName",
            keyImageUrl: "imageBannerUrl",
          });
        }}
      />
      <ModalAddSubCategory
        saveCategory={saveSubCategory}
        closeModal={closeModalSubCategoyr}
        customStyles={customStyles}
        loading={loading.loadingModalSubCategory}
        modalIsOpen={openSubcategoryModal}
        onChangeCategoryName={onChangeSubCategoryName}
        categoryName={subCategoryName}
        onChangeImage={onChangeImageSubCategory}
        imageCategory={urlImageSubCategory}
        category={categoryName}
      />
      <ModalEditSubCategory
        closeModal={() => {
          setmodalUpdateSubcategory(false);
        }}
        customStyles={customStyles}
        modalIsOpen={modalUpdateSubcategory}
        categoryName={subCategoryName}
        category={categorySelectedObject}
      />
      <div className="d-flex justify-content-end align-items-center">
        <Button primary type="button" className="me-2 mb-3" onClick={openModal}>
          <BsPlusLg /> Crear nueva categoría
        </Button>
      </div>
      <TableComponent
        titleHeader
        // searchHeader
        data={categories.map((el) => ({
          ...el,
          key: el._id,
          subCategories: el.subCategories.map((el) => el.nombre).toString(),
        }))}
        columns={columns}
      />
    </LayoutAdmin>
  );
};

export default Listcategories;
