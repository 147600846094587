import styled, { css } from "styled-components";

export interface ICardProps {
  loginCard?: boolean;
}

const Card = styled.div`
  border: 0;
  // overflow: auto;
  padding: 1em 1em;
  background-color: white;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  width: 100%;
  // cursor: pointer;
  object-fit: cover;

  ${({ loginCard }: ICardProps) =>
    loginCard &&
    css`
      border: 1px solid #086141;
      border-radius: 0;
      padding: 2.5em 2.5em;
      margin: 0 auto;
      width: 500px;
      @media (max-width: 600px) {
        width: 100%;
      }
    `}
`;

export { Card };
