export const BRANDS = [
  "VITACREME",
  "MADONNA",
  "ROY VIGAN",
  "DERMA NATURAL",
  "CHARISMA",
  "NODOR",
  "NODOR ICE",
  "HOMBRE",
  "BUG OFF",
  "PULMOVAPOR",
  "FUNGIPLUS",
  "BETAFARM",
  "BRONCOMED",
  "FARINTER",
  "MEGALAB",
  "STERISCRUB",
  "MEDILAB",
  "GENÉRICO",
  "HYDE PARK",
  "HAND CLEANER",
  "FUCIFARM"
];
export const pathStringBrand = "marca";
