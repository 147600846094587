import styled, { css } from "styled-components";
import { colors } from "./utilsStyle/PaletteColor";
import { typography } from "./utilsStyle/Typography";

interface PropsFormsStyle {
  searchGlobal?: boolean;
  disabled?: boolean;
}

const Form = styled.form`
  width: 100%;
  max-width: 350px;
  padding-top: 100px;
  margin: auto;
`;

const InputFile = styled.input.attrs({ type: "file" })`
  display: none;
  cursor: pointer;
`;

const InputDate = styled.input.attrs({ type: "date" })`
  border-radius: 18px;
  border: 1px solid ${colors.gray2};
  cursor: pointer;
  font-size: 1rem;
  ::placeholder {
    color: ${colors.gray1};
  }
`;

const LabelFile = styled.label`
  font-family: Avenir, sans-serif;
  color: ${colors.dark};
  background-color: ${colors.grayLight};
  border: 1px solid ${colors.dark};
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 0;
  ${({ disabled }: PropsFormsStyle) =>
    disabled &&
    css`
      background: ${colors.grayLight};
      pointer-events: none;
      border: 0;
      opacity: 0.55;
      box-shadow: none;
    `}
`;

const Textarea = styled.textarea`
  width: 100%;
  outline: 0;
  border: 1px solid ${colors.black};
  border-radius: 0;
  color: ${colors.gray};
  background-color: ${colors.white};
  font-size: 14px;
  line-height: 1.5;
`;

const CheckBox = styled.input.attrs({ type: "checkbox" })`
  width: 1.1rem;
  height: 1.1rem;
  color: ${colors.blue};
  vertical-align: middle;
  -webkit-appearance: none;
  background: none;
  border: 0;
  outline: 0;
  flex-grow: 0;
  border-radius: 0px;
  background-color: ${colors.greenLight};
  transition: background 300ms;
  cursor: pointer;
  ::before {
    content: "";
    color: transparent;
    display: block;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    border: 0;
    background-color: transparent;
    background-size: contain;
    box-shadow: inset 0 0 0 1px ${colors.blue};
  }

  :checked {
    background-color: currentcolor;
  }

  :checked::before {
    box-shadow: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
  }

  :disabled {
    background-color: ${colors.gray2};
    color: ${colors.gray2};
    box-shadow: inset 0 0 0 1px ${colors.gray2};
    border: 0;
    opacity: 0.84;
    cursor: not-allowed;
  }

  /* IE */

  ::-ms-check {
    content: "";
    color: transparent;
    display: block;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    border: 0;
    background-color: transparent;
    background-size: contain;
    box-shadow: inset 0 0 0 1px #ccd3d8;
  }

  :checked::-ms-check {
    box-shadow: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
  }
`;

const LabelCheck = styled.label`
  align-items: center;
  display: flex;
  gap: 9px;
  margin-bottom: 9px;
`;

const LabelText = styled.span`
  ${(props) => {
    switch (props.$mode) {
      case "primary":
        return css`
          color: ${colors.blueBright};
          ${CheckBox}:checked + && {
            color: ${colors.blueBrightLight};
          }
        `;
      default:
        return css`
          background-color: none;
          color: ${colors.dark};
          ${CheckBox}:checked + && {
            color: ${colors.dark};
          }
        `;
    }
  }}
`;

const Input = styled.input`
  display: block;
  width: 100%;
  // padding: 0.375rem 0.75rem;
  // padding: 0.35rem 0.15rem;
  padding: 7px;
  ::placeholder {
    color: ${colors.gray};
  }
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: ${colors.blue};
  background-color: ${colors.white};
  background-clip: padding-box;
  border: 1px solid ${colors.blue};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s;
  ${(props) =>
    props.disabled &&
    css`
      background-color: ${colors.gray3};
      pointer-events: none;
      border: 0;
      opacity: 0.8;
      box-shadow: none;
    `}
  ${({ searchGlobal }: PropsFormsStyle) =>
    searchGlobal &&
    css`
      background-color: transparent;
      color: #f1f1f1;
      border-top: 0px;
      border-right: 0px;
      border-bottom: 1px solid white;
      border-left: 0px;
      ::placeholder {
        color: ${colors.white};
      }
    `}
`;

const InputText = styled.span`
  display: flex;
  align-items: center;
  padding: 0.2rem 0.9rem;
  text-align: center;
  white-space: nowrap;
  background-color: white;
  border: 1px solid ${colors.black};
  border-right: 0;
  border-radius: 0;
`;

const Label = styled.label`
  margin-bottom: 0.5rem;
  margin-top: 0.7rem;
  color: ${colors.blue};
  font-family: ${typography.fontSecondary};
  font-weight: 400;
  font-size: 14px;
`;

const Wrapper = styled.div`
  height: auto;
  width: 100%;
  padding: 0px 16px 24px 16px;
  box-sizing: border-box;
  ${(props) =>
    props.login &&
    css`
      justify-content: center;
      align-items: center;
      min-height: 100vh;
      background-image: url("/img/BackgroundKielsa.png");
      background-color: ${colors.grayLight};
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    `}
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  position: relative;
`;

const RadioButtonLabel = styled.label`
  position: absolute;
  top: 15%;
  left: 1px;
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;
  background: white;
  border: 1px solid ${colors.gray2};
`;

const RadioButton = styled.input.attrs({ type: "radio" })`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 1.3rem;
  height: 1.3rem;
  align-self: center;
  margin-right: 10px;
  &:hover ~ ${RadioButtonLabel} {
    background: #bebebe;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 7px;
      height: 7px;
      margin: 6px;
      background: ${colors.gray2};
    }
  }
  ${(props) =>
    props.checked &&
    ` 
    &:checked + ${RadioButtonLabel} {
      background: ${colors.blueBright};
      border: 1px solid ${colors.blueBrightLight};
      &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 7px;
        height: 7px;
        margin: 6px;
        box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.1);
        background: white;
      }
    }
  `}
`;

const Range = styled.input.attrs({ type: "range" })`
  overflow: hidden;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: linear-gradient(to right, red, yellow, green);
  border-radius: 18px;
  width: 100%;
  box-shadow: 0px 0px 5px 5px gainsboro;

  &::-webkit-slider-runnable-track,
  &::-moz-range-track,
  &::-ms-track,
  &::-moz-range-progress {
    width: 100%;
    background: -webkit-linear-gradient(to right, green, yellow, red);
    background: linear-gradient(to right, red, yellow, green);
  }

  &::-webkit-slider-thumb {
    background-color: white;
    border: 1px solid #ccc;
  }

  &::-ms-thumb,
  &::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    background: white;
    border-radius: 100%;
    border: 1px solid #ccc;
    transition: background-color 150ms;
  }

  &::-ms-fill-lower {
    // background: transparent;
    background: linear-gradient(to right, red, yellow, green);
  }

  &::-ms-fill-upper {
    background: linear-gradient(to right, red, yellow, green);
  }

  &:hover,
  &:focus {
    &::-webkit-slider-thumb {
      background-color: white;
      border: 1px solid #ccc;
    }
    &::-moz-range-thumb {
      background-color: white;
    }
    &::-ms-thumb {
      background-color: white;
    }
  }
`;

const SwitchBoxWrapper = styled.div`
  position: relative;
`;
const SwitchBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 26px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;

const SwitchBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  &:checked + ${SwitchBoxLabel} {
    background: #4fbe79;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

const InputButton = styled.button`
  border-top: 1px solid ${colors.gray2};
  border-right: 1px solid ${colors.gray2};
  border-bottom: 1px solid ${colors.gray2};
  border-left: 0px;
  border-radius: 35px;
  font-size: 14px;
  background-color: ${colors.white};
`;

export {
  Form,
  RadioButton,
  CheckBox,
  LabelCheck,
  LabelText,
  Input,
  Label,
  InputText,
  RadioButtonLabel,
  Item,
  Wrapper,
  Textarea,
  InputFile,
  LabelFile,
  InputDate,
  Range,
  SwitchBoxWrapper,
  SwitchBox,
  SwitchBoxLabel,
  InputButton,
};
