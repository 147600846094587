import React from "react";
import LayoutMain from "./../layouts/layoutMain";
import { Animate } from "../components/elementsDS/animations/animations";
import { Text, Hr } from "./../components/elementsDS/Content";
const PageTermsCond: React.FC = () => {
  return (
    <LayoutMain>
        <div className="container pt-5">
          <Animate.FadeIn>
            <Text type="H4" content="TÉRMINOS Y CONDICIONES" color="#086141" />
          </Animate.FadeIn>
          <Hr />
          <p style={{ textAlign: "justify" }}>
            1. QUIÉNES SOMOS: Está página web es propiedad de Cosmética
            Internacional S.A. de C.V. (COINSA). Estos términos establecen los
            términos y condiciones bajo los cuales puedes usar nuestra página
            web y servicios ofrecidos por nosotros. Esta página web ofrece a los
            visitantes de las diferentes líneas que cuenta la empresa en el
            ámbito de cuidado personal y limpieza del hogar. Al acceder o usar
            la página web de nuestro servicio, usted aprueba que haya leído,
            entendido y aceptado estar sujeto a estos Términos:
            <Hr />
            2. DERECHOS DE AUTOR Y MARCAS REGISTRADAS: Cosmética Internacional
            S.A de C.V. (COINSA) es titular de todos los derechos de propiedad
            intelectual e industrial referidos a los contenidos que en esta
            página web se incluyan, los cuales no pueden ser utilizados, de modo
            alguno, por terceros sin autorización de su propietario exceptuando
            los derechos sobre productos y servicios que no son propiedad de la
            empresa, cuyas marcas se encuentran registradas a favor de sus
            respectivos titulares. Este sitio web está protegido por marcas,
            derechos de autor, y otros derechos legítimos registrados. Todo el
            contenido de esta página web, incluyendo, pero sin limitarse a los
            logos, imágenes, texto, ilustraciones, y archivos de audio y vídeo,
            están protegidos por derechos de autor, marcas comerciales, marcas
            de servicios u otros derechos de propiedad. El material de esta
            página web y de cualquier otro sitio web de propiedad, operado,
            controlado o licenciado por Cosmética Internacional S.A. de C.V.
            (COINSA) no puede ser copiado, reproducido, republicado, cargado,
            transmitido o distribuido de ninguna manera sin autorización expresa
            y por escrito de Cosmética Internacional de S.A. de C.V. (COINSA).
            Con excepción de lo expresamente previsto en el presente documento,
            no se le concede ningún derecho y/o licencia de las patentes,
            derechos de autor, secretos comerciales, derechos de publicidad o
            marcas comerciales con respecto a cualquier contenido, y Cosmética
            Internacional S.A. de C.V. (COINSA) se reserva todos los derechos no
            expresamente otorgados en el presente documento. Cosmética
            Internacional S.A. de C.V (COINSA) renuncia expresamente a toda
            responsabilidad por los usos que el Usuario y/o Cliente dé a
            cualquier contenido obtenido en esta página web y/o en conexión con
            la misma.
            <Hr />
            3. POLÍTICA DE CALIDAD Y CONFIDENCIALIDAD: Para Cosmética
            Internacional S.A. de C.V. (COINSA) es de suma importancia que todos
            los Usuarios de nuestra página web y/o Clientes conozcan la política
            de privacidad, confidencialidad y seguridad de los datos que
            suministran a nuestra organización, así como los términos de uso y
            las condiciones sobre las cuales se manejará la información que nos
            suministren en razón a las diferentes actividades que realizamos, lo
            cual garantiza la privacidad de la información personal. Para ello,
            le solicitamos leer todas las condiciones que a continuación se
            señalan. Si no está de acuerdo con el tratamiento que se dará a la
            información por usted suministrada es recomendable que se abstenga
            de utilizar los servicios que presta nuestra organización o de usar
            esta página web. El Usuario y/o Cliente acepta y reconoce que la
            información personal que ofrece a Cosmética Internacional S.A. de
            C.V. (COINSA), lo hace voluntariamente y teniendo en cuenta las
            características de esta página web y las facultades de uso por parte
            de Cosmética Internacional S.A. de C.V. (COINSA). Igualmente, acepta
            y reconoce que la información que suministra hará parte de un
            archivo y/o base de datos que contenderá su perfil, que podrá ser
            utilizada por Cosmética Internacional S.A. de C.V. (COINSA) en los
            términos que a continuación se indican.
            <Hr />
            3.1 Modificaciones a la Política de Privacidad: Cosmética
            Internacional S.A. de C.V. (COINSA) actualiza las políticas de
            privacidad para lo cual se publica en esta misma página indicando la
            fecha de actualización, cambios que serán ajustados a la
            normatividad que sobre la misma sean expedidos por el legislador y
            conlleven la modificación de la política adoptada por la
            organización, entrando en vigencia desde el mismo momento de su
            publicación. <Hr />
            3.2. Tipo de Información Solicitada: Cosmética Internacional S.A. de
            C.V (COINSA) no solicitará en ningún momento información de tipo
            sensible y que describa información relacionada con el origen racial
            o étnico, la orientación política, las convicciones religiosas o
            filosóficas, la pertenencia a sindicatos, organizaciones sociales,
            de derechos humanos o que promuevan intereses de cualquier partido
            político, o datos relativos a la salud, entre otros. Está página web
            utiliza cookies y tecnologías similares. Por cookies se refiere a un
            pequeño archivo de datos que se instala en su equipo cuando visita
            un sitio web. Las cookies y tecnologías similares pueden mejorar su
            experiencia de usuario al guardar sus preferencias, personalizar su
            experiencia en línea, almacenar artículos en su carrito de compras
            y, en algunas ocasiones, proporcionarle anuncios publicitarios
            adaptados a sus intereses.
            <Hr />
            3.2.1 Uso y Divulgación de su Información Cosmética Internacional
            S.A. de C.V (COINSA) no divulgará información personal acerca de los
            visitantes de nuestro Sitio de Internet, excepto los casos que se
            describen a continuación:
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                • Salvo que se indique específicamente, se puede utilizar la
                información recopilada en los sitios de Internet de Cosmética
                Internacional S.A. de C.V. (COINSA) para mejorar el contenido de
                nuestro sitio, personalizar el sitio de acuerdo con sus
                preferencias, comunicarle información (si la solicitó) con fines
                de comercialización e investigación, y para cualquier otro fin
                especificado en un sitio web.
              </li>
              <li className="list-group-item">
                • Cosmética Internacional S.A. de C.V. (COINSA) también se puede
                compartir la información personal con terceros que contratemos
                para que nos presten servicios de apoyo. Dichos terceros tienen
                la obligación de utilizar la información personal que hayamos
                compartido solo para prestar servicios en nuestro nombre y
                tratar su información personal de manera estrictamente
                confidencial, conforme a todas las leyes de protección de datos
                y privacidad vigentes.
              </li>
              <li className="list-group-item">
                • Cosmética Internacional S.A. de C.V. (COINSA) también se puede
                compartir la información personal con terceros que contratemos
                para que nos presten servicios de apoyo. Dichos terceros tienen
                la obligación de utilizar la información personal que hayamos
                compartido solo para prestar servicios en nuestro nombre y
                tratar su información personal de manera estrictamente
                confidencial, conforme a todas las leyes de protección de datos
                y privacidad vigentes.
              </li>
              <li className="list-group-item">
                • En algunos casos, Cosmética Internacional S.A. de C.V.
                (COINSA) puede compartir la información personal con terceros
                que estén asociados con nosotros para proporcionar productos y
                servicios a nuestros clientes, por ejemplo, distribuidores. Si
                es así, les exigiremos a nuestros socios comerciales que
                utilicen dicha información de manera coherente con los fines
                para los cuales se recopiló originalmente (o para los que usted
                otorgue su consentimiento posteriormente), y solo en la medida
                en que lo permita esta Política, las Declaraciones de Privacidad
                vigentes del Sitio Web o del País, y todas las leyes de
                protección de datos y privacidad vigentes.{" "}
              </li>
            </ul>
            <Hr />
            3.3. Seguridad: Cosmética Internacional S.A. de C.V. (COINSA) se
            compromete a garantizar la seguridad de la información recopilada,
            por lo que para compartir la información obtenida con terceros,
            solicitará consentimiento previo y escrito del Usuario y/o Cliente.
            <Hr />
            4. USO DE SITIO WEB: Este sitio web, es única y exclusivamente para
            uso personal de los Usuario y/o Clientes. Se prohíbe su
            modificación, reproducción, duplicación, copia, distribución, venta,
            reventa y demás formas de explotación con fines comerciales o no.
            Usted se compromete a no utilizar este sitio web para fines ilegales
            o prohibidos. Su derecho a utilizar esta página web se regirá por
            los presentes Términos y Condiciones de uso (en adelante los
            Términos), así como por las Condiciones particulares de uso de los
            servicios puestos a disposición del Usuario y/o Cliente a través de
            ésta página web. Usted debe tomarse el tiempo necesario para revisar
            ambos cuidadosamente antes de hacer uso de la página web. Al acceder
            a esta página web de cualquier forma usted, Usuario y/o Cliente, se
            compromete a cumplir estos Términos. Cosmética Internacional S.A. de
            C.V. (COINSA) se reserva el derecho de modificar los Términos en
            cualquier momento y por cualquier motivo. Las versiones actualizadas
            de los Términos se publicarán en la página web de COINSA y Usted
            debe visitar esta página periódicamente para mantenerse informado de
            cualquier cambio. Al continuar utilizando la página web después de
            cualquier cambio, ello significa que usted acepta y está de acuerdo
            con los Términos modificados. Cosmética Internacional S.A. de C.V.
            (COINSA) se reserva el derecho de modificar o interrumpir, temporal
            o permanentemente, la página web cualquiera de sus características,
            reglas o condiciones, todo ello sin previo aviso, a pesar de que
            estos cambios puedan afectar la forma en que usted hace uso de la
            página web. Usted acepta que Cosmética Internacional S.A. de C.V.
            (COINSA) no será responsable ante usted o cualquier tercero por
            cualquier modificación o interrupción de la página web. Estos
            Términos constituyen el acuerdo total entre usted y Cosmética
            Internacional S.A. de C.V. (COINSA) y rigen el uso de éste,
            remplazando cualquier acuerdo anterior. Usted también puede estar
            sujeto a términos y condiciones adicionales que puedan serle
            aplicables cuando utilice servicios afiliados, contenido de terceros
            o software de terceros, o cuando visite otro sitio vinculado a éste.
            <Hr />
            5. CONDICIONES DE USO: Las siguientes condiciones son un contrato
            legal entre el Usuario y/o Cliente de este sitio web y Cosmética
            Internacional S.A. de C.V. (COINSA) al entrar y/o utilizar este
            sitio web el Usuario o Cliente declara expresamente que ha leído,
            entendido y aceptado las condiciones de uso; además el Usuario o
            Cliente acepta todas las leyes aplicables. Si no acepta estas
            condiciones, no utilice el sitio ni descargue el material que
            contenga. Cosmética Internacional S.A. de C.V. (COINSA) se reserva
            el derecho a actualizar, modificar o eliminar la información
            contenida en este sitio web, pudiendo incluso limitar o no permitir
            el acceso a dicha información, sin previo aviso. En ningún caso
            Cosmética Internacional S.A. de C.V. (COINSA) será responsable de
            las pérdidas, daños o perjuicios de cualquier tipo que surjan por
            acceder y usar el sitio web y sus funcionalidades, incluyéndose,
            pero no limitándose, a los producidos en los sistemas informáticos o
            los provocados por la introducción de virus y/o ataques
            informáticos. Cosmética Internacional S.A. de C.V.
            <Hr />
            (COINSA) tampoco será responsable de los daños que pudieran sufrir
            los Usuario y/o Cliente por un uso inadecuado de este sitio web y,
            en modo alguno, de las caídas, interrupciones, ausencia o defecto en
            las telecomunicaciones. La información, servicios y productos
            disponibles para el Usuario y/o Cliente en esta página web pueden
            contener errores y están sujetos a períodos de interrupción. Si bien
            esta página web hace todo lo posible para mantener la información,
            servicios y productos que ofrece en, no se responsabiliza de los
            errores, defectos, pérdida de beneficios u otros daños y perjuicios
            ocasionados por el uso del sitio. Cosmética Internacional S.A. de
            C.V.
            <Hr />
            (COINSA) no responde de la veracidad, integridad o actualización de
            las informaciones que no sean de elaboración propia y de las que se
            indique otra fuente, así como tampoco de las contenidas en otros
            sitios web mediante hiperenlace o vínculo desde este sitio web,
            facilitados al Usuario y/o Cliente como fuentes alternativas de
            información. Cosmética Internacional S.A. de C.V.
            <Hr />
            (COINSA) no asume responsabilidad alguna en cuanto a hipotéticos
            perjuicios que pudieran originarse por el uso de las citadas
            informaciones. Cosmética Internacional S.A. de C.V.
            <Hr />
            (COINSA) no se responsabiliza de las posibles discrepancias que con
            carácter transitorio, puedan surgir entre la versión de sus
            documentos impresos y la versión electrónica de los mismos
            publicados en sus páginas web. Se entiende que el Usuario y/o
            Cliente por el sólo hecho de acceder a la página web o de utilizar
            sus servicios, renuncia expresamente a cualquier indemnización,
            reclamo o derecho que pueda tener en contra de Cosmética
            Internacional S.A. de C.V.
            <Hr />
            (COINSA) y sus representantes, empleados, asociados y
            corresponsales. Bajo ninguna circunstancia Cosmética Internacional
            S.A. de C.V.
            <Hr />
            (COINSA) sus asociados, corresponsales y/o empleados serán
            responsables por daños que el Usuario y/o Cliente pueda tener por el
            uso de la página web o por cualquier link al sitio, salvo expresa
            mención en contrario. El alcance de esta limitación de
            responsabilidad es aplicable a todo tipo de daño cualquiera sea su
            naturaleza, incluyendo, pero no limitándola, a pérdidas de datos y
            de programas, pérdidas en los resultados, pérdidas o interrupción de
            negocios, reclamos de terceros. Cosmética Internacional S.A. de C.V.
            <Hr />
            (COINSA) y sus asociados o afiliados bajo ninguna circunstancia,
            incluyendo negligencia, serán responsables de los daños directos,
            indirectos, incidentales, especiales y ulteriores que resulten del
            uso o imposibilidad de uso de los servicios de Cosmética
            Internacional S.A. de C.V.
            <Hr />
            (COINSA), incluyendo pero no limitados a la dependencia de la
            información obtenida de los servicios prestados por Cosmética
            Internacional S.A. de C.V. (COINSA); o que resulten de los errores,
            omisiones, interrupciones, eliminación de archivos o correos
            electrónicos, pérdida o daño de datos, errores, defectos, virus,
            demoras operacionales o de transmisión, o cualquier falla en la
            ejecución, estén limitadas a hechos de la naturaleza, fuerza mayor o
            casos fortuitos, fallas en la comunicación, robo, destrucción o
            acceso no autorizado a los registros, programas o servicios de
            Cosmética Internacional S.A. de C.V.
            <Hr />
            (COINSA). El Usuario y/o Cliente en este acto toma conocimiento de
            que esta disposición se aplicará ya sea que Cosmética Internacional
            S.A. de C.V. (COINSA) esté o no advertida sobre la posibilidad de
            dichos daños. Asimismo que esta disposición se aplicará a todo
            contenido, productos o servicios disponibles a través sus aliados. A
            pesar de los esfuerzos desplegados por Cosmética Internacional S.A.
            de C.V. (COINSA), la información entregada en esta página web, puede
            contener errores de tipografía o de datos, los cuales serán
            rectificados inmediatamente al ser detectados. El Usuario o Cliente
            asume toda la responsabilidad y riesgo por el uso de esta página web
            y por la información obtenida de él. Todas las gestiones de
            Cosmética Internacional S.A. de C.V.
            <Hr />
            (COINSA) y sus asociados, empleados, aliados, corresponsales serán
            realizadas de acuerdo a la información entregada por el Usuario y/o
            Cliente. A tal efecto, Cosmética Internacional S.A. de C.V. (COINSA)
            y sus asociados, empleados, aliados, corresponsales, no serán
            responsables en caso de que esta información infrinja derechos de
            terceros o contenga errores u omisiones.
            <Hr />
            El Usuario y/o Cliente es absolutamente responsable por los datos
            entregados en cualquier Formulario de solicitud de servicio
            disponible en el esta página web, sean estos recomendados o no por
            Cosmética Internacional S.A. de C.V. (COINSA). Cosmética
            Internacional S.A. de C.V. (COINSA) ha creado una Política de
            Privacidad que establece cómo se recopila, utiliza y almacena la
            información recopilada sobre el Usuario y/o Cliente. La utilización
            del sitio web por parte de los Usuarios o Clientes implica el
            reconocimiento y consentimiento de nuestra política de privacidad.
            Asimismo, el Usuario y/o Cliente reconoce y acepta que Cosmética
            Internacional S.A. de C.V. (COINSA) puede utilizar su información
            personal de la manera descrita en nuestra Política de Privacidad.
            Cosmética Internacional S.A. de C.V. (COINSA) se reserva el derecho,
            sin expresión de causa, de rechazar cualquier solicitud de servicio.
            Cosmética Internacional S.A. de C.V. (COINSA) tiene derecho a
            cancelar su acceso por cualquier motivo, si creemos que usted ha
            infringido estos Términos de alguna manera. Usted se compromete a no
            responsabilizar a Cosmética Internacional S.A. de C.V.
            <Hr />
            (COINSA) de dicha cancelación y, además, se compromete a no intentar
            hacer uso de esta página web después de la cancelación. Usted
            reconoce y acepta que si cualquier disposición de estos Términos
            fuese declarada ilegal, nula o, por algún motivo, inaplicable, dicha
            disposición no afectará la validez y aplicabilidad de las
            disposiciones restantes. Asimismo, si alguna disposición de estos
            términos es considerada no válida por un tribunal de jurisdicción
            competente, las partes acuerdan, no obstante, que dicho tribunal
            debe procurar dar lugar a las intenciones de las partes tal como se
            refleja en la disposición.
            <Hr />
            Cosmética Internacional S.A. de C.V. (COINSA) no tiene control y no
            es responsable por el contenido de o las declaraciones efectuadas en
            sitios web que puedan estar vinculados desde o hacia el esta página
            web, ya sea que estos estén o no afiliados a la página web, ni se
            responsabiliza de ello. Cosmética Internacional S.A. de C.V.
            (COINSA) tampoco se responsabiliza de las prácticas de privacidad o
            el contenido de otros sitios web que contengan enlaces hacia o desde
            esta página web. Si tiene alguna pregunta acerca de estos Términos y
            Condiciones o sobre el uso que le demos a su información personal,
            comuníquese con nosotros por correo electrónico o escríbanos a
            Cosmética Internacional S.A. de C.V. (COINSA)
            <Hr />
            info@coinsa.hn
          </p>
        </div>
    </LayoutMain>
  );
};

export default PageTermsCond;
