/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Text, Hr } from "../../../components/elementsDS/Content";
import { Button } from "../../../components/elementsDS/buttons/Buttons";
import { Animate } from "../../../components/elementsDS/animations/animations";
import { fetchMethod } from "../../../utils/fetch/fecth";

interface Iarticles {
  _id: string;
  titulo: string;
  descripcion: string;
  imagen: string;
  body: string;
  type: string;
}

const Articles: React.FC = () => {
  const [articles, setarticles] = useState<Iarticles[]>([]);
  const getArtciles = async () => {
    const answer: any = await fetchMethod(
      `${process.env.REACT_APP_BACKEND_API}/articles?skip=0&limit=4`,
      {}
    );
    setarticles(answer.data);
  };
  useEffect(() => {
    getArtciles();
  }, []);
  return (
    <div className="container mb-5">
      <div>
        {articles.map((article, i) => {
          if (i % 2 !== 0) {
            return (
              <Animate.ScaleIn key={article._id}>
                <div className="row justify-content-center mt-5">
                  <div className="col-12 col-lg-8">
                    <div className="row align-items-center">
                      <div className="col-12 col-lg-6">
                        <img
                          src={article.imagen}
                          alt="image articulo"
                          className="img-fluid zoom"
                          loading="lazy"
                        />
                      </div>
                      <div className="col-12 col-lg-6">
                        <br />
                        <Text
                          type="H6"
                          content={article.titulo}
                          bold
                          color="#024E34"
                        />
                        <Text
                          type="Paragraph"
                          //span
                          content={article.descripcion}
                          color="#5C6562"
                        />
                        <Hr />
                        <Link to={`/nuestros-servicios`} onClick={()=> scroll(0, 0)}>
                          <Button primary={true} type="button">
                            Descubre más
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Animate.ScaleIn>
            );
          } else {
            return (
              <Animate.ScaleIn key={article._id}>
                <div className="row justify-content-center mt-5">
                  <div className="col-12 col-lg-8">
                    <div className="row align-items-center">
                      <div className="col-12 col-lg-6">
                        <Text
                          type="H6"
                          content={article.titulo}
                          bold
                          color="#024E34"
                        />
                        <Text
                          type="Paragraph"
                          //span
                          align={"right"}
                          content={article.descripcion}
                          color="#5C6562"
                        />
                        <Hr />
                        <Link to={`/nuestros-servicios`}>
                          <Button primary={true} type="button" className="mb-2">
                            Descubre más
                          </Button>
                        </Link>
                      </div>
                      <div className="col-12 col-lg-6">
                        <img
                          src={article.imagen}
                          alt="image articulo"
                          className="img-fluid zoom"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Animate.ScaleIn>
            );
          }
        })}
      </div>
      {/* <Animate.ScaleIn>
      <Link to="/listado/articulos" className="text-decoration-none">
        <Button secondary type="button" className="mt-5 mb-5 m-auto d-block">
          Ver más artículos
        </Button>
      </Link>
      </Animate.ScaleIn> */}
    </div>
  );
};

export default Articles;
