import React from "react";
import { Input } from "../elementsDS/Forms";
import { ButtonSearchIcon } from "../elementsDS/buttons/Buttons";
import { BsSearch } from "react-icons/bs";

interface Props {
  placeholder?: string;
  onChange?: any;
  nameInputSearch?: any;
  eventButton?: any;
  searchGlobal?: any;
  value?: any;
  globalSearchButtom?: any;
}

const InputSearch: React.FC<Props> = ({
  placeholder,
  onChange,
  nameInputSearch,
  eventButton,
  searchGlobal,
  value,
  globalSearchButtom,
}) => (
  <div className="input-group input-group-lg flex-nowrap">
    <Input
      id="searchOrder"
      type="text"
      searchGlobal={searchGlobal ? searchGlobal : ""}
      placeholder={placeholder}
      aria-label="search"
      aria-describedby="search"
      value={value}
      onChange={onChange}
    />
    <ButtonSearchIcon
      type="button"
      id="search"
      onClick={eventButton}
      globalSearchButtom={globalSearchButtom ? globalSearchButtom : ""}
    >
      <BsSearch className="me-1" /> {nameInputSearch}
    </ButtonSearchIcon>
  </div>
);

export default InputSearch;
