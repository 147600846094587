/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";
import Select from "react-select";
import Modal from "react-modal";
import { BsUpload } from "react-icons/bs";
import { Button } from "../../../../components/elementsDS/buttons/Buttons";
import {
  Input,
  InputFile,
  LabelFile,
} from "../../../../components/elementsDS/Forms";
import { Hr, Image, Text } from "../../../../components/elementsDS/Content";
import LoadButton from "../../../../components/elementsDS/Loaders/LoadButton";
import { IPmodalAddProduct } from "../../../../commons/interfaces/Props/products.interface";
const ModalUpdateImageProduct = ({
  modalIsOpen,
  closeModal,
  customStyles,
  loading,
  onChangeImage,
  updateImage,
  urlImage,
}: {
  modalIsOpen: boolean;
  closeModal: () => any;
  customStyles: any;
  loading: boolean;
  onChangeImage: any;
  updateImage: () => any;
  urlImage: any;
}) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="row align-items-center justify-content-center g-3 text-center pb-4">
        <div className="col-12 col-lg-12">
          <Text
            type="H6"
            content="Actualizar imagen de producto"
            color="#5C6562"
          />
          <Hr />
        </div>
        <div className="col-12 col-lg-6">
          <LabelFile htmlFor="imageProduct" disabled={loading} className="mb-3">
            {!loading ? (
              <>
                <BsUpload className="me-2" /> Subir imágen de producto
              </>
            ) : (
              <LoadButton message="Cargando imágen..." />
            )}
            <InputFile
              type="file"
              name="imageProduct"
              id="imageProduct"
              onChange={onChangeImage}
            />
          </LabelFile>
        </div>
        <div className="col-12 col-lg-6">
          <Image
            src={urlImage ? urlImage : "/img/noproduct.png"}
            alt=""
            height={100}
            width={100}
            className="border p-1"
          />
          <p className="text-secondary mt-2">{urlImage ? 'Imagen cargada' : "Cargar imagen"}</p>
        </div>
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <Button
          type="button"
          primary
          className="me-2"
          onClick={updateImage}
          disabled={loading}
        >
          {loading ? "Procesando..." : "Guardar"}
        </Button>
        <Button type="button" light onClick={closeModal} disabled={loading}>
          Cerrar
        </Button>
      </div>
    </Modal>
  );
};

export default ModalUpdateImageProduct;
