/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";
import Select from "react-select";
import Modal from "react-modal";
import { BsUpload } from "react-icons/bs";
import { Button } from "../../../../components/elementsDS/buttons/Buttons";
import {
  Input,
  InputFile,
  LabelFile,
} from "../../../../components/elementsDS/Forms";
import { Hr, Text } from "../../../../components/elementsDS/Content";
import LoadButton from "../../../../components/elementsDS/Loaders/LoadButton";
import { IPmodalAddProduct } from "../../../../commons/interfaces/Props/products.interface";
const ModalAddProducts = ({
  modalIsOpen,
  closeModal,
  customStyles,
  optionsCategoria,
  optionsSubCategoria,
  optionsMarcas,
  loading,
  onChangeImage,
  imageUrl,
  onChange,
  onChangeSubCategory,
  onChangeCategory,
  onChangeMarca,
  saveProducts,
  productData,
  isEditing,
  updateProduct,
}: IPmodalAddProduct) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      // className="Modal"
      // overlayClassName="Overlay"
      // onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="row">
        <Text type="H6" content="Agregar nuevo producto" color="#5C6562" />
        <Hr />
        <div className="col-lg-6 justify-content-center">
          {!isEditing && (
            <LabelFile
              htmlFor="imageProduct"
              disabled={loading}
              className="mb-3"
            >
              {!loading ? (
                <>
                  <BsUpload className="me-2" /> Subir imágen de Producto asd
                </>
              ) : (
                <LoadButton message="Cargando imágen..." />
              )}
              <InputFile
                type="file"
                name="imageProduct"
                id="imageProduct"
                onChange={onChangeImage}
              />
            </LabelFile>
          )}

          <br />
          <div className="d-flex justify-content-center">
            <img
              src={productData.urlImage ? productData.urlImage : imageUrl}
              height={100}
              width={100}
              alt=""
            />
          </div>
        </div>
        <div className="mt-3 mb-3">
          <Input
            className="m-2"
            type="text"
            name="nombreProducto"
            placeholder="Nombre de producto"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-lg"
            onChange={onChange}
            value={productData.nombreProducto}
            disabled={loading}
          />
          {/* <Input
            type="text"
            placeholder="GNEXUS"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-lg"
            disabled
          />
          <Input
            type="text"
            placeholder="SAP"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-lg"
            disabled
          />
          <Input
            type="text"
            placeholder="codigo de barra"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-lg"
            disabled
          /> */}
          <Input
            className="m-2"
            name="presentacion"
            type="text"
            placeholder="presentacion"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-lg"
            onChange={onChange}
            disabled={loading}
            value={productData.presentacion}
          />
          {/* <Input
            type="text"
            placeholder="uxc"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-lg"
            disabled
          /> */}
          {isEditing && (
            <>
              <Input
                value={productData.categoria}
                type="text"
                placeholder="uxc"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-lg"
                disabled
              />
              <Input
                value={productData.subCategoria}
                type="text"
                placeholder="uxc"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-lg"
                disabled
              />
              <Input
                value={productData.marca}
                type="text"
                placeholder="uxc"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-lg"
                disabled
              />
            </>
          )}
          <Select 
            name="categoria"
            options={optionsCategoria}
            placeholder="Seleccionar categoría"
            className="m-3"
            onChange={onChangeCategory}
            defaultValue={productData.categoria}
          />
          <Select
            name="subCategoria"
            options={optionsSubCategoria}
            placeholder="Seleccionar sub categoría"
            className="mt-3"
            onChange={onChangeSubCategory}
            defaultValue={productData.subCategoria[0]}
          />
          <Select
            name="marca"
            options={optionsMarcas}
            placeholder="Seleccionar Marca"
            className="mt-3"
            onChange={onChangeMarca}
            defaultValue={productData.marca[0]}
          />
        </div>
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <Button
          type="button"
          primary
          className="me-2"
          onClick={isEditing ? updateProduct : saveProducts}
          disabled={loading}
        >
          {loading ? "Procesando..." : "Guardar"}
        </Button>
        <Button type="button" light onClick={closeModal} disabled={loading}>
          Cerrar
        </Button>
      </div>
    </Modal>
  );
};

export default ModalAddProducts;
