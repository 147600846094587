import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaSignOutAlt, FaBars } from "react-icons/fa";
import { ThemeProvider } from "styled-components";
import {
  Wrapper,
  HeaderNav,
  Content,
  Title,
  HeaderTitle,
} from "../components/elementsDS/Layout";
import SidebarWeb from "../components/sidebar/SidebarWeb";
import { Button } from "../components/elementsDS/buttons/Buttons";
import SidebarMovil from "../components/sidebar/SidebarMovil";
import Footer from "../components/footer/footer";
import { Hr, Image } from "../components/elementsDS/Content";
import { GlobalStyle } from "./globalStyle";
import { toast } from "react-toastify";

const theme = {
  space: [
    "4px",
    "8px",
    "12px",
    "16px",
    "24px",
    "32px",
    "48px",
    "64px",
    "96px",
    "128px",
    "192px",
    "256px",
    "384px",
  ],
};

interface Props {
  children: any;
  title?: string;
  icon?: any;
}

const LayoutAdmin: React.FC<Props> = ({ children, title, icon }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [visible, novisble] = useState(false);
  const showSidebar = () => novisble(!visible);

  const logout = () => {
    sessionStorage.removeItem("credential");
    navigate("/dashboard/login");
    toast.success("Su sesión ha sido cerrada.");
  };
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {pathname !== "/dashboard/login" ? (
        <Wrapper>
          <div className="d-none d-sm-block">
            <SidebarWeb />
          </div>
          <div className="d-block d-md-none">
            {visible ? <SidebarMovil /> : ""}
          </div>
          <Content>
            <HeaderNav>
              <Link className="text-decoration-none" to="/dashboard">
                <Image src="/img/coinsa.png" alt="logo" width={100} loading="lazy" />
              </Link>
              <div className="d-flex align-items-center">
                <div className="me-3 d-none d-sm-block d-md-block">
                  <span>
                    <b>Administrador</b>
                  </span>
                </div>
                <div className="flex-shrink-0">
                  <div className="d-block d-sm-none d-md-none">
                    <Button type="button" onClick={showSidebar}>
                      <FaBars />
                    </Button>
                  </div>
                  <div className="d-none d-sm-block d-md-block">
                    <Button type="button" className="me-2" onClick={logout}>
                      Cerrar sesión <FaSignOutAlt className="ms-1" />
                    </Button>
                  </div>
                </div>
              </div>
            </HeaderNav>
            <HeaderTitle>
              <Title>{title}</Title>
              {icon}
            </HeaderTitle>
            <Hr />
            {children}
          </Content>
        </Wrapper>
      ) : (
        <div className="container-fluider">
          {children}
          <Footer fixed />
        </div>
      )}
    </ThemeProvider>
  );
};

export default LayoutAdmin;
