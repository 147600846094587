/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";
import styled from "styled-components";
import { Text, Image } from "../../../components/elementsDS/Content";
import { Animate } from "../../../components/elementsDS/animations/animations";
import ItemBanner from "./ItemBanner";

const Background = styled.div`
  background-image: url("/img/background.webp");
  background-repeat: no-repeat;
  // background-color: #001689;
  background-position: inherit;
  background-size: cover;
  padding-top: 100px;
  padding-bottom: 100px;
  @media (max-width: 999px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  // flex: wrap;
  align-items: center;
  justify-content: center;
`;

const Item = styled.div`
  padding: 5px;
`;
interface I {
  title: string;
  subtitle: string;
  imageProduct: string;
  imageItem: string;
  items: string[];
}
const Promos = ({ title, subtitle, imageProduct, items, imageItem }: I) => {
  const leftItems = items.filter((el, i) => i % 2 === 0);
  const rightItems = items.filter((el, i) => i % 2 !== 0);
  return (
    <>
      <Animate.FadeIn>
        <Background>
          <Container>
            <Item>
              <Animate.ScaleIn>
                {leftItems.map((el, i) => (
                  <ItemBanner
                    key={`${i}-data-left`}
                    iconItem={imageItem}
                    textItem={el}
                  />
                ))}
              </Animate.ScaleIn>
            </Item>
            <Item className="text-center">
              <Animate.ScaleIn>
                {/* title */}
                <Text type="H6" content={title} color="white" />{" "}
                {/* subtitle */}
                <Text type="H3" content={subtitle} bold color="white" />{" "}
              </Animate.ScaleIn>
              <Animate.FadeUp>
                <Image
                  src={imageProduct}
                  alt="image product"
                  loading="lazy"
                  responsiveProduct
                />
              </Animate.FadeUp>
            </Item>
            <Item>
              <Animate.ScaleIn>
                {rightItems.map((el, i) => (
                  <ItemBanner
                    key={`${i}-data-right`}
                    firstTextDirection
                    iconItem={imageItem}
                    textItem={el}
                  />
                ))}
              </Animate.ScaleIn>
            </Item>
          </Container>
        </Background>
      </Animate.FadeIn>
    </>
  );
};

export default Promos;
