import React, {useId} from "react";
import { Link } from "react-router-dom";
import { DataSidebar } from "./DataSidebar";
import { SidebarMenuWeb, List, ListItem } from '../elementsDS/Sidebar';


function SidebarWeb() {
  return (
    <SidebarMenuWeb>
      <List>
        {DataSidebar.map(({ title, icon, path, index }) => (
          <Link to={path} className="text-decoration-none" key={useId(index)}>
            <ListItem>
              {icon}
              {title}
            </ListItem>
          </Link>
        ))}
      </List>
    </SidebarMenuWeb>
  );
}

export default SidebarWeb;
