/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from "react";
import { FcNews } from "react-icons/fc";
import LayoutAdmin from "../../../layouts/layoutAdmin";
import LoadButton from "../../../components/elementsDS/Loaders/LoadButton";
import {
  Input,
  InputFile,
  Label,
  LabelFile,
  Textarea,
} from "../../../components/elementsDS/Forms";
import { Button } from "../../../components/elementsDS/buttons/Buttons";
import { Card } from "../../../components/card/card";
import { BsPencil, BsPlus, BsPlusLg, BsTrash, BsUpload } from "react-icons/bs";
import { Hr, Image, Text } from "../../../components/elementsDS/Content";
import { IarticlesPresentation } from "../../../commons/interfaces/article.interface";
import { toast } from "react-toastify";
import { fetchMethod } from "../../../utils/fetch/fecth";
import { useUser } from "../../../context/userContext";

const AddPresentation: React.FC = () => {
  const contextData = useUser();
  const [loading, setloading] = useState(false);
  const [form, setform] = useState<IarticlesPresentation>({
    descripcion: "",
    imagen: "",
    imagenItem: "",
    items: [""],
    titulo: "",
  });
  const [itemInput, setitemInput] = useState("");
  const [items, setItems] = useState<string[]>([]);
  const [image, setimage] = useState<any>();
  const [imageUrl, setImageUrl] = useState<any>();
  const [imageIcon, setimageIcon] = useState<any>("");
  const [imageIconUrl, setimageIconUrl] = useState<any>("");

  const saveData = async () => {
    setloading(true);
    try {
      let message = "";
      if (!form.titulo) message += "agregue un titulo. \n";
      if (!form.descripcion) message += "agregue una descripcion. \n";
      if (!image) message += "agregue una imagen. \n";
      if (!imageIcon) message += "agregue una imagen de icono. \n";
      if (message) {
        toast.info(message);
      }
      const formData = new FormData();
      formData.append("file", image);
      const answerImage = await fetchMethod(
        `${process.env.REACT_APP_BACKEND_API}/products/uploadImage`,
        {
          method: "POST",
          body: formData,
          mode: "cors",
        }
      );
      const formData2 = new FormData();
      formData2.append("file", imageIcon);
      const answerImage2 = await fetchMethod(
        `${process.env.REACT_APP_BACKEND_API}/products/uploadImage`,
        {
          method: "POST",
          body: formData2,
          mode: "cors",
        }
      );
      if (!answerImage.success)
        return toast.error("no se pudo subir la imagen");
      if (!answerImage2.success)
        return toast.error("no se pudo subir la imagen");
      const answer = await fetchMethod(
        `${process.env.REACT_APP_BACKEND_API}/articles/articleBanner`,
        {
          method: "POST",
          headers: new Headers({
            Accept: "application/json",
            Authorization: `Bearer ${contextData.user?.access_token}`,
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({
            titulo: form.titulo,
            descripcion: form.descripcion,
            imagen: answerImage.data.imageUri,
            imagenItem: answerImage.data.imageUri,
            items: items,
          }),
        }
      );
      toast[answer.success ? "success" : "error"](answer.message);
      setform({
        descripcion: "",
        imagen: "",
        titulo: "",
        items: [""],
        imagenItem: "",
      });
      setItems([]);
    } catch (error: any) {
      toast.error(error.message);
    }
    setloading(false);
  };
  const onchange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setform((value) => ({
      ...value,
      [target.name]: target.value,
    }));
  };
  const onChangeImage = ({ target, currentTarget }: React.ChangeEvent<any>) => {
    const file = currentTarget.files[0];
    setimage(file);
    const url = URL.createObjectURL(file);
    setImageUrl(url);
  };
  const onChangeImage2 = ({
    target,
    currentTarget,
  }: React.ChangeEvent<any>) => {
    const file = currentTarget.files[0];
    setimageIcon(file);
    const url = URL.createObjectURL(file);
    setimageIconUrl(url);
  };
  useEffect(() => {
    return;
  }, []);

  return (
    <LayoutAdmin
      title="Agregar nueva presentación de producto"
      icon={<FcNews size={34} />}
    >
      <div className="container-fluider">
        <div className="row justify-content-center mt-5 g-3">
          <div className="col-12 col-lg-6">
            <Card>
              <Text type="H6" content="Info de producto" color="#000" />
              <Hr />
              <Label>Título</Label>
              <div className="input-group flex-nowrap">
                <Input
                  type="text"
                  placeholder=""
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-lg"
                  id="titulo"
                  name="titulo"
                  value={form.titulo}
                  onChange={onchange}
                />
              </div>
              <Label>Sub Título</Label>
              <div className="input-group flex-nowrap">
                <Input
                  type="text"
                  placeholder=""
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-lg"
                  name="descripcion"
                  value={form.descripcion}
                  onChange={onchange}
                />
              </div>

              <div>
                <Label className="me-3 mt-4">Cargar imagen de producto</Label>
                <LabelFile htmlFor="imageProduct" disabled={loading}>
                  {!loading ? (
                    <>
                      <BsUpload className="me-2" /> Subir
                    </>
                  ) : (
                    <LoadButton message="cargando imágen..." />
                  )}
                  <InputFile
                    type="file"
                    name="imageProduct"
                    id="imageProduct"
                    onChange={onChangeImage}
                  />
                </LabelFile>
                <img
                  src={imageUrl}
                  alt=""
                  width={50}
                  height={50}
                  className="m-1"
                />
                <Label className="me-3">Cargar icono de objetivo</Label>
                <LabelFile htmlFor="imageIcon" disabled={loading}>
                  {!loading ? (
                    <>
                      <BsUpload className="me-2" /> Subir
                    </>
                  ) : (
                    <LoadButton message="cargando icono..." />
                  )}
                  <InputFile
                    type="file"
                    name="imageIcon"
                    id="imageIcon"
                    onChange={onChangeImage2}
                  />
                </LabelFile>
                <img
                  src={imageIconUrl}
                  alt=""
                  width={50}
                  height={50}
                  className="m-1"
                />
              </div>
              <Hr />
              <Button
                primary={true}
                type="button"
                onClick={saveData}
                disabled={loading}
              >
                {loading ? <LoadButton /> : "Guardar"}
              </Button>
            </Card>
          </div>
          <div className="col-12 col-lg-6">
            <Card>
              <Text type="H6" content="Objetivos" color="#000" />
              <Hr />
              <Label>Descripción de objetivo</Label>
              <div className="input-group flex-nowrap">
                <Input
                  type="text"
                  placeholder="texto objetivo"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-lg"
                  value={itemInput}
                  onChange={({ target }) => setitemInput(target.value)}
                />
              </div>
              <div className="d-flex mt-3 justify-content-between align-items-center flex-wrap">
                <Button
                  type="button"
                  light
                  onClick={() => {
                    setitemInput("");
                    setItems([...items, itemInput]);
                  }}
                >
                  Agregar objetivo
                </Button>
              </div>
              <Hr />
              <div className="d-flex mt-4 justify-content-between align-items-center flex-wrap">
                <div>
                  <Label>Objetivos agregados</Label>
                  <ul>
                    {items.map((el, i) => (
                      <li
                        className="d-flex align-items-center justify-content-between"
                        key={`${el}-${i}`}
                      >
                        <div className="d-flex align-items-center">
                        <Image
                          src={imageIconUrl}
                          alt="image banner coinsa"
                          loading="lazy"
                          className="me-1"
                          width={60}
                          height={60}
                        />
                        <Text type="Paragraph" content={el} color="#000" />
                        </div>
                        <Button
                          type="button"
                          danger
                          className="me-2 m-2"
                          onClick={() => {
                            setItems(items.filter((key, it) => it !== i));
                          }}
                        >
                          <BsTrash />
                        </Button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <Hr />
            </Card>
          </div>
        </div>
      </div>
    </LayoutAdmin>
  );
};

export default AddPresentation;
