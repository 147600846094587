import React, { useState, useId } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { BRANDS } from "../../commons/const/brands";

// const NavWrapper = styled.nav`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   height: 60px;
//   background-color: #222;
//   color: #fff;
// `;

const NavItem = styled.div`
  position: relative;
  padding: 0 20px;
  cursor: pointer;
  &:hover {
    color: #3e8d90;
  }
`;

const DropdownList = styled.ul`
  position: absolute;
  top: 60px;
  left: 0;
  background-color: #fff;
  padding: 48px 0 48px 20px;
  min-width: 270px;
  max-height: 80vh;
  -ms-box-shadow: 0 15px 15px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 15px 15px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 15px #0000001a;
  border: 1px solid #ddd;
  border-radius: 0;
  list-style: none;
  overflow-y: scroll;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
`;

const DropdownItem = styled.li`
  padding: 8px;
  cursor: pointer;
  text-align: left;
  color: #515050;
  font-weight: 300;
  &:hover {
    color: #639fa1;
  }
`;
function MaysPrimera(string){
  return `${string.charAt(0)}${string.slice(1).toLowerCase()}`;
}
const NavDropdownBrands = () => {
  const [isOpen, setIsOpen] = useState(false);
  const id = useId();
  return (
    <NavItem
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      Marcas
      <DropdownList isOpen={isOpen}>
        {BRANDS.map((el,i)=>(
          <Link key={`${id}-${i}`} className="text-decoration-none" to={`/productos/marca/${el}`}>
          <DropdownItem>{ MaysPrimera(el)}</DropdownItem>
        </Link>
        ))}
        
        {/* <Link key={id} className="text-decoration-none" to="/productos/marca/CHARISMA">
          <DropdownItem>Charisma</DropdownItem>
        </Link>
        <Link key={id} className="text-decoration-none" to="/productos/marca/DERMA NATURAL">
          <DropdownItem>Dermanatural</DropdownItem>
        </Link>
        <Link key={id} className="text-decoration-none" to="/productos/marca/HAND CLEANER">
          <DropdownItem>Hand Cleaner</DropdownItem>
        </Link>
        <Link key={id} className="text-decoration-none" to="/productos/marca/HYDE PARK">
          <DropdownItem>Hyde Park</DropdownItem>
        </Link>
        <Link key={id} className="text-decoration-none" to="/productos/marca/ROY VIGAN">
          <DropdownItem>Roy Vigan</DropdownItem>
        </Link> */}
      </DropdownList>
    </NavItem>
  );
};

export default NavDropdownBrands;
