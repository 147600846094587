import React from 'react';
import styled from 'styled-components'

const Loading = styled.section`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;


function LoadPage() {
  return (
    <Loading>
        <img src="/img/loadinfo.gif" alt="load" className="img-fluid" />
    </Loading>
  );
}

export default LoadPage;
