/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LayoutAdmin from "../../../layouts/layoutAdmin";
import { Card } from "../../../components/card/card";
import { Text } from "../../../components/elementsDS/Content";
import { Button } from "../../../components/elementsDS/buttons/Buttons";
import { Input } from "../../../components/elementsDS/Forms";
import { useUser } from "../../../context/userContext";
import { fetchMethodPost } from "../../../utils/fetch/fecth";
import { toast } from "react-toastify";
import { IapiAnswerGeneral } from "../../../commons/interfaces/category.interface";
import { IapiAnswerUserLog } from "../../../commons/interfaces/user.interface";

const Login: React.FC = () => {
  const contextData = useUser();
  const navigate = useNavigate();
  const [userName, setuserName] = useState("");
  const [password, setpassword] = useState("");
  const login = async () => {
    try {
      const answer: IapiAnswerUserLog = await fetchMethodPost(
        `${process.env.REACT_APP_BACKEND_API}/user/login`,
        {
          user: userName,
          pass: password,
        }
      );
      if (answer.success) {
        if (answer.data) {
          answer.data.user.password = "";
          contextData.setuser(answer.data);
          sessionStorage.setItem("credential", JSON.stringify(answer.data));
          navigate("/dashboard/");
        }
      } else {
        toast.error(answer.message);
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  };
  return (
    <LayoutAdmin>
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img
            className="img-fluid m-auto d-block mb-4 mt-5"
            src="/img/coinsa.png"
            alt="logo"
          />
        </Link>
        <Card loginCard>
          <Text
            type="H6"
            content="Iniciar Sesión"
            bold
            color="#002C1C"
            align="left"
          />
          <div className="row mt-2">
            <div className="col-lg-12">
              <div className="input-group flex-nowrap mt-4">
                <Input
                  type="text"
                  placeholder="usuario"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-lg"
                  value={userName}
                  onChange={({ target }) => setuserName(target.value)}
                />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="input-group flex-nowrap mt-4">
                <Input
                  type="text"
                  placeholder="*contraseña"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-lg"
                  value={password}
                  onChange={({ target }) => setpassword(target.value)}
                />
              </div>
            </div>
            <div className="col-lg-12">
              <Button type="button" primary className="mt-4" onClick={login}>
                Ingresar al administrador
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </LayoutAdmin>
  );
};

export default Login;
