import React, { useCallback } from "react";
import styled, { css } from "styled-components";
import { space, color, typography } from "styled-system";
import { colors } from "./utilsStyle/PaletteColor";

export interface PropsContentStyle {
  left?: boolean;
  right?: boolean;
  alignTextRight?: boolean;
  blockSmall?: boolean;
  primary?: boolean;
  responsiveIcon?: boolean;
  responsiveProduct?: boolean;
}

const Tag = styled.div`
  font-weight: 400;
  line-height: 1;
  text-align: center;
  white-space: wrap;
  vertical-align: baseline;
  display: flex;
  flex-wrap: wrap;
  border-radius: 0;
  padding: 0.25em 0.55em;
  font-size: 11px;
  font-family: Avenir, sans-serif;
  text-align: center;
  ${({primary}: PropsContentStyle) =>
    primary &&
    css`
      background: ${colors.gray2};
      color: ${colors.dark};
    `}
  ${(props) =>
    props.success &&
    css`
      background: ${colors.green};
      color: ${colors.white};
    `}
  ${(props) =>
    props.danger &&
    css`
      background: ${colors.red};
      color: ${colors.white};
    `}
  ${(props) =>
    props.warning &&
    css`
      background: ${colors.orange};
      color: ${colors.white};
    `}
  ${(props) =>
    props.light &&
    css`
      background: ${colors.gray};
      color: ${colors.white};
    `}
    ${(props) =>
    props.info &&
    css`
      background: ${colors.blueBright};
      color: ${colors.white};
    `}
`;

const Hr = styled.hr`
  border: 0.5px solid #086141;
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
  ${({ responsiveIcon }: PropsContentStyle) =>
  responsiveIcon &&
  css`
    width: 60px;
    @media (max-width: 999px) {
      width: 25px;
    }
  `}
  ${({ responsiveProduct }: PropsContentStyle) =>
  responsiveProduct &&
  css`
    width: 180px;
    margin-top: 3rem;
    @media (max-width: 999px) {
      width: 80px;
      margin-top: 1rem;
    }
  `}
`;

const Section = styled.section`
  padding-top: 125px;
  padding-bottom: 125px;
`;

const ScrollPanel = styled.div`
  padding: 0.55rem 0.85rem;
  background-color: white;
  overflow-y: scroll;
  border-radius: 13px;
  height: 600px;
`;

const BoxText = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  align-items: center;
  border-top: 1px solid #318b6b;
  border-bottom: 1px solid #318b6b;
  margin-bottom: 80px;
`;

const BlockText = styled.div`
  background: #fff;
  width: 35%;
  @media (max-width: 600px) {
    width: 100%;
    padding: 20px;
  }
  ${({ right, alignTextRight, blockSmall }: PropsContentStyle) =>
    right &&
    css`
      background: #83b0a0;
      width: ${blockSmall ? '100%' : '65%'};
      padding: ${blockSmall ? '50px' : '115px'};
      text-align: ${alignTextRight ? 'right' : 'left'};
      color: white;
      @media (max-width: 600px) {
        width: 100%;
        padding: 40px;
      }
    `}
`;

//Texto
const H1 = styled.h1(space, typography, color);
const H2 = styled.h2(space, typography, color);
const H3 = styled.h3(space, typography, color);
const H4 = styled.h4(space, typography, color);
const H5 = styled.h5(space, typography, color);
const H6 = styled.h6(space, typography, color);
const Paragraph = styled.p(space, typography, color);

// Helpers
// Para textos de presentación única

const Text = ({ type, bold, color, align, padding, content, caption, span }: any) => {
  const renderText = useCallback(() => {
    switch (type) {
      case "H1":
        return (
          <H1
            color={color}
            fontSize={80}
            textAlign={align}
            fontWeight={bold ? 900 : ""}
            p={padding}
          >
            {content}
          </H1>
        );

      case "H2":
        return (
          <H2
            color={color}
            fontSize={61}
            textAlign={align}
            fontWeight={bold ? "bold" : ""}
            p={padding}
          >
            {content}
          </H2>
        );

      case "H3":
        return (
          <H3
            color={color}
            fontSize={[ 12, 18, 38, 47 ]}
            textAlign={align}
            fontWeight={bold ? "bold" : ""}
            p={padding}
          >
            {content}
          </H3>
        );

      case "H4":
        return (
          <H4
            color={color}
            fontSize={36}
            textAlign={align}
            fontWeight={bold ? "bold" : ""}
            p={padding}
          >
            {content}
          </H4>
        );

      case "H5":
        return (
          <H5
            color={color}
            fontSize={[ 14, 18, 23, 29 ]}
            textAlign={align}
            fontWeight={bold ? "bold" : ""}
            p={padding}
          >
            {content}
          </H5>
        );

      case "H6":
        return (
          <H6
            color={color}
            fontSize={[ 20, 27 ]}
            textAlign={align}
            fontWeight={bold ? "bold" : ""}
            p={padding}
          >
            {content}
          </H6>
        );

      case "Paragraph":
        return (
          <Paragraph
            color={color}
            fontSize={caption ? 11 : span ? 13 : 19}
            textAlign={align}
            fontWeight={bold ? "bold" : ""}
            p={padding}
          >
            {content}
          </Paragraph>
        );

      default:
        return null;
    }
  }, [type]);

  return <>{renderText()}</>;
};

export {
  Tag,
  Image,
  Hr,
  Section,
  ScrollPanel,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Paragraph,
  Text,
  BoxText,
  BlockText,
};
